import { Typography } from "antd";
import { IMAGES } from "../../config";

import "../../styles/authorization-page.scss";
import Footer from "./Footer";

export default function AuthorizationPage({ title, children }) {
  return (
    <div className="authorization-page">
      <div className="left">
        <div className="logo-block">
          <img src={IMAGES.LOGO} alt="logo" className="logo-left" />
        </div>

        <Footer />
      </div>

      <div className="right">
        <div className="container">
          <img src={IMAGES.LOGO} alt="logo" className="logo-right" />
          <Typography.Title style={{ marginBottom: "35px" }}>
            {title}
          </Typography.Title>

          {children}
        </div>

        <div className="login-container-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
