import { ICONS } from "../../config";

export default function TvSettings({
  background,
  secondaryColor,
}) {
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: " no-repeat",
        position: "relative",
      }}
      className="settings-container"
    >
      <div className="settings-container-items">
    
        <div
          className="settings-container-items_item"
          style={{
            border: `2px solid transparent`,
            background: "#151718",
          }}
        >
          <span>{ICONS.TRANSLATE}</span>
          <span className="settings-item">Change language</span>
        </div>

        <div
          className="settings-container-items_item"
          style={{
            border: `2px solid transparent`,
            background: "#151718",
          }}
        >
          <span>{ICONS.PARENTAL_CODE}</span>
          <span className="settings-item">Change Parental code</span>
        </div>

        <div
          className="settings-container-items_item"
          style={{
            border: `2px solid ${secondaryColor}`,
            background: "#151718",
          }}
        >
          <span>{ICONS.VISIBILITY_OFF}</span>
          <span className="settings-item">Hidden content</span>
        </div>

        <div
          className="settings-container-items_item"
          style={{
            border: `2px solid transparent`,
            background: "#151718",
          }}
        >
            <span>
              {ICONS.LOG_OUT}
            </span>
            <span className="settings-item">Log out</span>
        </div>

        <div className="tv-footer-container" style={{marginTop: "100px"}}>
          <p>
            <span> Device Key: 3GK689</span>
          </p>
          <p>App version: 1.1.5</p>
        </div>

      </div>
    </div>
  );
}
