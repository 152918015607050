import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profile: null,
    accountsList: null,
    isBrandinLogoChange: false,
    isRefreshBillingHistory: false,
    selectedLanguage: localStorage.getItem("leng") ||"en",
  },
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setAccountsList: (state, action) => {
      state.accountsList = action.payload;
    },
    setBrandingLogo: (state, action) => {
      state.isBrandinLogoChange = action.payload;
    },
    setIsRefreshBillingHistory: (state, action) => {
      state.isRefreshBillingHistory = action.payload;
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
  },
});
export const {
  setProfile,
  setAccountsList,
  setBrandingLogo,
  setIsRefreshBillingHistory,
  setSelectedLanguage,
} = profileSlice.actions;

export default profileSlice.reducer;
