import BRANDING_INFO from "../../videos/branding-info.mov";

export const brandingInfoModal = () => {
  return (
    <video width="300" height="240" controls style={{ marginTop: "-40px" }}>
      <source src={BRANDING_INFO} type="video/quicktime" />
      <source src={BRANDING_INFO} type="video/mp4" />
    </video>
  );
};
