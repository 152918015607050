import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  setProfile,
  setIsRefreshBillingHistory,
} from "../store/features/profileSlice";

import { Layout, Alert, Space, Popover, Button } from "antd";

import REQUESTS from "../api/requests";
import useTranslate from "../hooks/translator";

import { ICONS } from "../config";
import { Languages } from "../components";

import Credits from "./Credits";
import ProviderPackage from "./ProviderPackage";
import ActivationsStatistics from "./ActivationsStatistics";
import "../styles/header.scss";

export default function Header({ isMobile, setOpenMenuDrawer }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const viewMoreRef = useRef(null);

  const { profile, isBrandinLogoChange, isRefreshBillingHistory } =
    useSelector((store) => store.profile);

  const [alerts, setAlerts] = useState([]);
  const [subDevicesCount, setSubDevicesCount] = useState(0);
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    getProfile();
  }, [isBrandinLogoChange, isRefreshBillingHistory]);

  useEffect(() => {
    REQUESTS.ALERTS_MESSAGES.GET()
      .then((data) => {
        if (data) {
          setAlerts(data);
        }
      })
      .catch((err) => {});
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (viewMoreRef.current && !viewMoreRef.current.contains(event.target)) {
        setViewMore(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [viewMore, viewMoreRef.current]);

  const handleViewMoreClick = (e) => {
    e.stopPropagation();
    setViewMore(!viewMore);
  };

  const getProfile = () => {
    REQUESTS.PROFILE()
      .then((data) => {
        if (data?.is_partner || data?.parent_id) {
          REQUESTS.SUB_DEVICES_COUNT()
            .then((res) => setSubDevicesCount(res))
            .catch((err) => {});
        }
        dispatch(setProfile(data));
        dispatch(setIsRefreshBillingHistory(false));
      })
      .catch((err) => {});
  };

  const onAfterClose = (item) => {
    REQUESTS.ALERTS_MESSAGES.PUT({ messages_id: item })
      .then((res) => {})
      .catch((err) => {});
  };

  const returnPackageBlock = () => {
    if (profile) {
      if (
        profile?.is_partner &&
        profile?.partner_package &&
        !profile?.parent_id
      ) {
        return <ProviderPackage />;
      }

      if (!profile?.is_partner && !profile?.parent_id) {
        return <Credits />;
      }

      return null;
    }

    return null;
  };

  return (
    <>
      <Layout.Header className="header">
        {profile && (
          <div className="header-body">
            {isMobile && (
              <Button
                type="link"
                icon={ICONS.MENU}
                onClick={() => setOpenMenuDrawer(true)}
              ></Button>
            )}

            <div className="activations-devices-profile">
              <div
                className={`${
                  isMobile && viewMore ? "mobile-device-profile" : ""
                }`}
                style={{ display: isMobile && !viewMore ? "none" : "flex" }}
                onClick={(e) => e.stopPropagation()}
              >
                {profile?.is_partner || profile?.parent_id ? (
                  <Popover
                    content={
                      <div className="tariff-content">
                        <p>
                          <span>{translate("Your devices")}: </span>{" "}
                          {profile?.device_count}
                        </p>
                        <p>
                          <span> {translate("Reseller devices")}:</span>{" "}
                          {subDevicesCount}
                        </p>
                      </div>
                    }
                  >
                    <div style={{ cursor: "pointer" }}>
                      <ActivationsStatistics
                        subDevicesCount={subDevicesCount}
                      />
                    </div>
                  </Popover>
                ) : (
                  <ActivationsStatistics subDevicesCount={subDevicesCount} />
                )}
              </div>
              {returnPackageBlock()}
            </div>
            <div
              className={`${isMobile && viewMore ? "mobile-languages" : ""}`}
              style={{ display: isMobile && !viewMore ? "none" : "flex" }}
              onClick={(e) => e.stopPropagation()}
            >
              <Languages />
            </div>

            {isMobile && (
              <div onClick={handleViewMoreClick} className="more-header-icon">
                {ICONS.MORE_HORIZONTAL}
              </div>
            )}

            {viewMore && (
              <div
                className="view-more-wrap"
                ref={viewMoreRef}
                onClick={(e) => e.stopPropagation()}
              ></div>
            )}
          </div>
        )}
      </Layout.Header>

      <Space
        direction="vertical"
        className="alerts-natification"
        style={{ marginTop: 20 }}
      >
        {alerts &&
          alerts?.map((el) => {
            if (el?.viewed == 0) {
              return (
                <Alert
                  description={el?.desc}
                  type="info"
                  closable
                  onClose={() => onAfterClose(el?.id)}
                  key={el?.id}
                />
              );
            }
          })}
      </Space>
    </>
  );
}
