import { APP_BRANDING_BACKGROUNDS } from "../../config/data";
import ICONS from "../../config/icons";

export default function AppBackgrounds({ selectedBg, setSelectedBg }) {
  const onSelected = (val) => {
    setSelectedBg(val);
  };

  return (
    <div className="app-backgrounds">
      {APP_BRANDING_BACKGROUNDS.map((el, index) => (
        <div
          key={el.id}
          onClick={() => onSelected(el.src)}
          className="bg-img"
          style={{ backgroundImage: `url(${el.src})` }}
        >
          {selectedBg == el.src && <span>{ICONS.TRUE}</span>}
        </div>
      ))}
    </div>
  );
}
