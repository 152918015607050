const translations = {
  //a
  "Add Image": {
    en: "Add Image",
    es: "Agregar imagen",
    fr: "Ajouter une image",
    de: "Bild hinzufügen",
    pt: "Adicionar imagem",
    it: "Aggiungi immagine",
    sq: "Shto imazh",
    tr: "Resim Ekle",
  },
  "Add Video": {
    en: "Add Video",
    es: "Agregar video",
    fr: "Ajouter une vidéo",
    de: "Video hinzufügen",
    pt: "Adicionar vídeo",
    it: "Aggiungi video",
    sq: "Shto video",
    tr: "Video Ekle",
  },
  Active: {
    en: "Active",
    pt: "Ativo",
    de: "Aktiv",
    es: "Activo",
    fr: "Actif",
    it: "Attivo",
    sq: "Aktiv",
    tr: "Aktif",
  },
  "Activate devices": {
    en: "Activate devices",
    pt: "Ativar dispositivos",
    de: "Geräte aktivieren",
    es: "Activar dispositivos",
    fr: "Activer les appareils",
    it: "Attivare dispositivi",
    sq: "Aktivizoni pajisjet",
    tr: "Cihazları etkinleştir",
  },
  "Add New Subreseller": {
    en: "Add New Subreseller",
    pt: "Adicionar Novo Subrevendedor",
    de: "Neuen Unterwiederverkäufer hinzufügen",
    es: "Agregar Nuevo Subrevendedor",
    fr: "Ajouter un Nouveau Sous-revendeur",
    it: "Aggiungi Nuovo Subrivenditore",
    sq: "Shtoni Sub-Rivenditorin e Ri",
    tr: "Yeni Alt Bayi Ekle",
  },
  "Allow resellers to make use of DNS": {
    en: "Allow resellers to make use of DNS",
    pt: "Permitir que os revendedores usem DNS",
    de: "Erlauben Sie Wiederverkäufern, DNS zu verwenden",
    es: "Permitir a los revendedores hacer uso de DNS",
    fr: "Autoriser les revendeurs à utiliser le DNS",
    it: "Consenti ai rivenditori di utilizzare il DNS",
    sq: "Lejo shitësit të përdorin DNS",
    tr: "Bayiilerin DNS kullanmasına izin ver",
  },
  "All Rights Reserved": {
    en: "All Rights Reserved",
    pt: "Todos os Direitos Reservados",
    de: "Alle Rechte vorbehalten",
    es: "Todos los derechos reservados",
    fr: "Tous droits réservés",
    it: "Tutti i diritti riservati",
    sq: "Të gjitha të drejtat e rezervuara",
    tr: "Tüm Hakları Saklıdır",
  },
  All: {
    en: "All",
    pt: "Todos",
    de: "Alle",
    es: "Todos",
    fr: "Tous",
    it: "Tutti",
    sq: "Të gjitha",
    tr: "Hepsi",
  },
  adults: {
    en: "adults",
    pt: "adultos",
    de: "Erwachsene",
    es: "adultos",
    fr: "adultes",
    it: "adulti",
    sq: "Të rriturit",
    tr: "yetişkinler",
  },
  Amount: {
    en: "Amount",
    pt: "Quantidade",
    de: "Betrag",
    es: "Cantidad",
    fr: "Montant",
    it: "Importo",
    sq: "Shuma",
    tr: "Miktar",
  },
  Actives: {
    en: "Actives",
    pt: "Ativos",
    de: "Aktiv",
    es: "Activos",
    fr: "Actifs",
    it: "Attivi",
    sq: "Aktivë",
    tr: "Aktifler",
  },
  "Activated by": {
    en: "Activated by",
    pt: "Ativado por",
    de: "Aktiviert durch",
    es: "Activado por",
    fr: "Activé par",
    it: "Attivato da",
    sq: "Aktivuar nga",
    tr: "Tarafından etkinleştirildi",
  },
  Action: {
    en: "Action",
    pt: "Ação",
    de: "Aktion",
    es: "Acción",
    fr: "Action",
    it: "Azione",
    sq: "Veprim",
    tr: "Eylem",
  },
  Activate: {
    en: "Activate",
    pt: "Ativar",
    de: "Aktivieren",
    es: "Activar",
    fr: "Activer",
    it: "Attivare",
    sq: "Aktivizo",
    tr: "Etkinleştir",
  },
  Activation: {
    en: "Activation",
    pt: "Ativação",
    de: "Aktivierung",
    es: "Activación",
    fr: "Activation",
    it: "Attivazione",
    sq: "Aktivizim",
    tr: "Aktivasyon",
  },
  "Activate now or add free trial to device": {
    en: "Activate now or add free trial to device",
    pt: "Ativar agora ou adicionar teste gratuito ao dispositivo",
    de: "Jetzt aktivieren oder kostenlose Testversion zum Gerät hinzufügen",
    es: "Activar ahora o agregar prueba gratuita al dispositivo",
    fr: "Activer maintenant ou ajouter un essai gratuit au périphérique",
    it: "Attiva ora o aggiungi una prova gratuita al dispositivo",
    sq: "Aktivo tani ose shtoni provë falas në pajisje",
    tr: "Şimdi etkinleştir veya cihaza ücretsiz deneme ekle",
  },
  Approve: {
    en: "Approve",
    pt: "Aprovar",
    de: "Genehmigen",
    es: "Aprobar",
    fr: "Approuver",
    it: "Approvare",
    sq: "Mirato",
    tr: "Onayla",
  },
  "App name": {
    en: "App name",
    pt: "Nome do aplicativo",
    de: "App-Name",
    es: "Nombre de la aplicación",
    fr: "Nom de l'application",
    it: "Nome dell'app",
    sq: "Emri i aplikacionit",
    tr: "Uygulama adı",
  },
  Version: {
    en: "Version",
    es: "Versión",
    fr: "Version",
    de: "Version",
    pt: "Versão",
    it: "Versione",
    sq: "Versioni",
    tr: "Sürüm",
  },
  "App version": {
    en: "App version",
    pt: "Versão do aplicativo",
    de: "App-Version",
    es: "Versión de la aplicación",
    fr: "Version de l'application",
    it: "Versione dell'app",
    sq: "Versioni i aplikacionit",
    tr: "Uygulama sürümü",
  },
  "API key": {
    en: "API key",
    pt: "Chave da API",
    de: "API-Schlüssel",
    es: "Clave de API",
    fr: "Clé API",
    it: "Chiave API",
    sq: "Çelësi API",
    tr: "API anahtarı",
  },
  "Access code": {
    en: "Access code",
    pt: "Código de acesso",
    de: "Zugangscode",
    es: "Código de acceso",
    fr: "Code d'accès",
    it: "Codice di accesso",
    sq: "Kodi i hyrjes",
    tr: "Erişim kodu",
  },
  "Auto renew": {
    en: "Auto renew",
    pt: "Renovação automática",
    de: "Automatische Verlängerung",
    es: "Renovación automática",
    fr: "Renouvellement automatique",
    it: "Rinnovo automatico",
    sq: "Rinovim automatik",
    tr: "Otomatik yenileme",
  },
  "Add Credits": {
    en: "Add Credits",
    pt: "Adicionar créditos",
    de: "Guthaben hinzufügen",
    es: "Agregar créditos",
    fr: "Ajouter des crédits",
    it: "Aggiungi crediti",
    sq: "Shtoni kredite",
    tr: "Kredi ekle",
  },
  "Auto approve": {
    en: "Auto approve",
    pt: "Aprovação automática",
    de: "Automatische Genehmigung",
    es: "Aprobación automática",
    fr: "Approbation automatique",
    it: "Approvazione automatica",
    sq: "Mirato automatikisht",
    tr: "Otomatik onayla",
  },
  "Activated from parent": {
    en: "Activated from parent",
    pt: "Ativado pelo pai",
    de: "Aktiviert vom Elternteil",
    es: "Activado desde el padre",
    fr: "Activé depuis le parent",
    it: "Attivato dal genitore",
    sq: "Aktivare nga prindërit",
    tr: "Ebeveyn tarafından etkinleştirildi",
  },
  "activation info": {
    en: "If you will not choose any types of activation the device will be automatically activated and the credit will be charged.",
    pt: "Se você não escolher nenhum tipo de ativação, o dispositivo será ativado automaticamente e o crédito será cobrado.",
    de: "Wenn Sie keine Aktivierungsart auswählen, wird das Gerät automatisch aktiviert und Ihr Guthaben wird belastet.",
    es: "Si no elige ningún tipo de activación, el dispositivo se activará automáticamente y se le cobrará el crédito.",
    fr: "Si vous ne choisissez pas de type d'activation, le dispositif sera activé automatiquement et le crédit sera débité.",
    it: "Se non scegli alcun tipo di attivazione, il dispositivo verrà attivato automaticamente e il credito verrà addebitato.",
    sq: "Nëse nuk zgjedhni asnjë lloj aktivizimi, pajisja do të aktivizohet automatikisht dhe do të ngarkohet krediti.",
    tr: "Herhangi bir etkinleştirme türü seçmezseniz, cihaz otomatik olarak etkinleştirilecek ve kredi hesabınızdan düşülecektir.",
  },
  "Activated from device": {
    en: "Activated from device",
    pt: "Ativado a partir do dispositivo",
    de: "Aktiviert vom Gerät",
    es: "Activado desde el dispositivo",
    fr: "Activé depuis l'appareil",
    it: "Attivato dal dispositivo",
    sq: "Aktivizuar nga pajisja",
    tr: "Cihazdan etkinleştirildi",
  },
  "Activated from panel": {
    en: "Activated from panel",
    pt: "Ativado a partir do painel",
    de: "Aktiviert vom Panel",
    es: "Activado desde el panel",
    fr: "Activé depuis le panneau",
    it: "Attivato dal pannello",
    sq: "Aktivizuar nga paneli",
    tr: "Panelden etkinleştirildi",
  },
  "Activate device": {
    en: "Activate device",
    pt: "Ativar dispositivo",
    de: "Gerät aktivieren",
    es: "Activar dispositivo",
    fr: "Activer l'appareil",
    it: "Attiva dispositivo",
    sq: "Aktivizo pajisjen",
    tr: "Cihazı etkinleştir",
  },

  "Account Status": {
    en: "Account Status",
    pt: "Status da conta",
    de: "Kontostatus",
    es: "Estado de la cuenta",
    fr: "État du compte",
    it: "Stato dell'account",
    sq: "Statusi i Llogarisë",
    tr: "Hesap Durumu",
  },
  "Add new device": {
    en: "Add New Device",
    pt: "Adicionar Novo Dispositivo",
    de: "Neues Gerät Hinzufügen",
    es: "Agregar Nuevo Dispositivo",
    fr: "Ajouter Un Nouveau Dispositif",
    it: "Aggiungi Nuovo Dispositivo",
    sq: "Shto Pajisje Të Reja",
    tr: "Yeni Cihaz Ekle",
  },
  "Add New DNS": {
    en: "Add New DNS",
    pt: "Adicionar Novo DNS",
    de: "Neue DNS Hinzufügen",
    es: "Agregar Nuevo DNS",
    fr: "Ajouter Un Nouveau DNS",
    it: "Aggiungi Nuovo DNS",
    sq: "Shto DNS Të Reja",
    tr: "Yeni DNS Ekle",
  },
  "All devices": {
    en: "All devices",
    pt: "Todos os dispositivos",
    de: "Alle Geräte",
    es: "Todos los dispositivos",
    fr: "Tous les appareils",
    it: "Tutti i dispositivi",
    sq: "Të gjitha pajisjet",
    tr: "Tüm cihazlar",
  },
  Approved: {
    en: "Approved",
    pt: "Aprovado",
    de: "Genehmigt",
    es: "Aprobado",
    fr: "Approuvé",
    it: "Approvato",
    sq: "Miratuar",
    tr: "Onaylandı",
  },
  "Activated devices": {
    en: "Activated devices",
    pt: "Dispositivos ativados",
    de: "Aktivierte Geräte",
    es: "Dispositivos activados",
    fr: "Appareils activés",
    it: "Dispositivi attivati",
    sq: "Pajisje të aktivizuara",
    tr: "Etkinleştirilen cihazlar",
  },
  "Allow app branding": {
    en: "Allow app branding",
    pt: "Permitir marca de aplicativo",
    de: "App-Branding zulassen",
    es: "Permitir marca de la aplicación",
    fr: "Autoriser la marque de l'application",
    it: "Consenti il branding dell'app",
    sq: "Lejo brandingun e aplikacionit",
    tr: "Uygulama markalamasına izin ver",
  },
  "Activation type": {
    en: "Activation type",
    pt: "Tipo de ativação",
    de: "Aktivierungstyp",
    es: "Tipo de activación",
    fr: "Type d'activation",
    it: "Tipo di attivazione",
    sq: "Lloji i aktivizimit",
    tr: "Aktivasyon türü",
  },
  "Add notification": {
    en: "Add notification",
    pt: "Adicionar notificação",
    de: "Benachrichtigung hinzufügen",
    es: "Agregar notificación",
    fr: "Ajouter une notification",
    it: "Aggiungi notifica",
    sq: "Shto njoftim",
    tr: "Bildirim ekle",
  },
  "Add New Sub-Reseller": {
    en: "Add New Sub-Reseller",
    pt: "Adicionar Novo Sub-Revendedor",
    de: "Neuen Unterwiederverkäufer hinzufügen",
    es: "Agregar Nuevo Subrevendedor",
    fr: "Ajouter un Nouveau Sous-revendeur",
    it: "Aggiungi Nuovo Sub-Rivenditore",
    sq: "Shtoni Sub-Rishitës të Ri",
    tr: "Yeni Alt Bayi Ekle",
  },
  "Admin Panel Login Page": {
    en: "Admin Panel Login Page",
    pt: "Página de Login do Painel de Administração",
    de: "Anmeldeseite des Admin-Panels",
    es: "Página de Inicio de Sesión del Panel de Administración",
    fr: "Page de Connexion du Panneau d'Administration",
    it: "Pagina di Accesso al Pannello di Amministrazione",
    sq: "Faqe Hyrjeje në Panelin e Adminitratitës",
    tr: "Yönetici Paneli Giriş Sayfası",
  },
  "Add new DNS configurations": {
    en: "Add new DNS configurations",
    pt: "Adicionar novas configurações de DNS",
    de: "Neue DNS-Konfigurationen hinzufügen",
    es: "Agregar nuevas configuraciones de DNS",
    fr: "Ajouter de nouvelles configurations DNS",
    it: "Aggiungi nuove configurazioni DNS",
    sq: "Shto konfigurime të reja të DNS",
    tr: "Yeni DNS yapılandırmaları ekle",
  },
  "Activate without free trial": {
    en: "Activate without free trial",
    pt: "Ativar sem teste gratuito",
    de: "Aktivieren ohne kostenlose Testversion",
    es: "Activar sin prueba gratuita",
    fr: "Activer sans essai gratuit",
    it: "Attiva senza prova gratuita",
    sq: "Aktivo pa provë falasë",
    tr: "Ücretsiz deneme olmadan etkinleştir",
  },
  "Auto renew when device expired": {
    en: "Auto renew when device expired",
    pt: "Renovação automática quando o dispositivo expirar",
    de: "Automatische Verlängerung, wenn das Gerät abgelaufen ist",
    es: "Renovación automática cuando el dispositivo haya caducado",
    fr: "Renouvellement automatique lorsque l'appareil a expiré",
    it: "Rinnova automaticamente quando il dispositivo è scaduto",
    sq: "Rinovohet automatikisht kur pajisja skadon",
    tr: "Cihazın süresi dolduğunda otomatik yenile",
  },
  "Are you sure you want to": {
    en: "Are you sure you want to",
    pt: "Tem certeza de que deseja",
    de: "Sind Sie sicher, dass Sie möchten",
    es: "¿Estás seguro de que quieres",
    fr: "Êtes-vous sûr de vouloir",
    it: "Sei sicuro di voler",
    sq: "A jeni të sigurtë që doni të",
    tr: "Bunu yapmak istediğinize emin misiniz",
  },
  "Are you sure you want to delete": {
    en: "Are you sure you want to delete?",
    pt: "Tem certeza de que deseja excluir?",
    de: "Sind Sie sicher, dass Sie löschen möchten?",
    es: "¿Estás seguro de que quieres borrar?",
    fr: "Êtes-vous sûr de vouloir supprimer ?",
    it: "Sei sicuro di voler eliminare?",
    sq: "A jeni të sigurtë që doni ta fshijeni?",
    tr: "Silmek istediğinize emin misiniz?",
  },

  "Allow users to login from application": {
    en: "Allow users to login from application",
    pt: "Permitir que os usuários façam login pelo aplicativo",
    de: "Erlauben Sie Benutzern, sich von der Anwendung aus anzumelden",
    es: "Permitir que los usuarios inicien sesión desde la aplicación",
    fr: "Autoriser les utilisateurs à se connecter depuis l'application",
    it: "Consenti agli utenti di effettuare l'accesso dall'applicazione",
    sq: "Lejo përdoruesve të hyjnë në llogari nga aplikacioni",
    tr: "Kullanıcıların uygulamadan giriş yapmasına izin ver",
  },

  "An unknown error occurred": {
    en: "An unknown error occurred",
    pt: "Ocorreu um erro desconhecido",
    de: "Ein unbekannter Fehler ist aufgetreten",
    es: "Ocurrió un error desconocido",
    fr: "Une erreur inconnue s'est produite",
    it: "Si è verificato un errore sconosciuto",
    sq: "Ndodhi një gabim i panjohur",
    tr: "Bilinmeyen bir hata oluştu",
  },

  "Are you sure you want to remove this banner ad": {
    en: "Are you sure you want to remove this banner ad?",
    es: "¿Estás seguro de que deseas eliminar este banner?",
    fr: "Êtes-vous sûr de vouloir supprimer cette bannière publicitaire ?",
    de: "Sind Sie sicher, dass Sie dieses Banner-Werbung entfernen möchten?",
    pt: "Tem certeza de que deseja remover este banner?",
    it: "Sei sicuro di voler rimuovere questo banner?",
    sq: "Jeni të sigurt që dëshironi të hiqni këtë reklamë banner?",
    tr: "Bu banner reklamayı kaldırmak istediğinize emin misiniz?",
  },

  "Are you sure you want to approve this sub-reseller": {
    en: "Are you sure you want to approve this sub-reseller?",
    pt: "Tem certeza de que deseja aprovar este sub-revendedor?",
    de: "Sind Sie sicher, dass Sie diesen Unterwiederverkäufer genehmigen möchten?",
    es: "¿Estás seguro de que quieres aprobar a este subrevendedor?",
    fr: "Êtes-vous sûr de vouloir approuver ce sous-revendeur ?",
    it: "Sei sicuro di voler approvare questo sub-rivenditore?",
    sq: "A jeni të sigurtë që doni ta miratoni këtë nën-rishitësen?",
    tr: "Bu alt bayi onaylamak istediğinize emin misiniz?",
  },

  "Are you sure you want to cancel this sub-reseller": {
    en: "Are you sure you want to cancel this sub-reseller?",
    pt: "Tem certeza de que deseja cancelar este sub-revendedor?",
    de: "Sind Sie sicher, dass Sie diesen Unterwiederverkäufer stornieren möchten?",
    es: "¿Estás seguro de que quieres cancelar a este subrevendedor?",
    fr: "Êtes-vous sûr de vouloir annuler ce sous-revendeur ?",
    it: "Sei sicuro di voler annullare questo sub-rivenditore?",
    sq: "A jeni të sigurtë që doni ta anuloni këtë nën-rishitësen?",
    tr: "Bu alt bayi iptal etmek istediğinize emin misiniz?",
  },

  "Attach account": {
    en: "Attach account",
    pt: "Anexar conta",
    de: "Konto anhängen",
    es: "Adjuntar cuenta",
    fr: "Attacher un compte",
    it: "Allega account",
    sq: "Bashkëngjit llogarinë",
    tr: "Hesabı ekle",
  },

  "Are you sure to delete this account": {
    en: "Are you sure to delete this account?",
    pt: "Tem certeza de que deseja excluir esta conta?",
    de: "Sind Sie sicher, dieses Konto zu löschen?",
    es: "¿Está seguro de que desea eliminar esta cuenta?",
    fr: "Êtes-vous sûr de vouloir supprimer ce compte?",
    it: "Sei sicuro di voler eliminare questo account?",
    sq: "A jeni të sigurtë që doni ta fshijeni këtë llogari?",
    tr: "Bu hesabı silmek istediğinize emin misiniz?",
  },

  "App reset": {
    en: "App reset",
    pt: "Reiniciar aplicativo",
    de: "App zurücksetzen",
    es: "Restablecer aplicación",
    fr: "Réinitialiser l'application",
    it: "Ripristina app",
    sq: "Riniapplikoni",
    tr: "Uygulamayı sıfırla",
  },
  "Allow users to reset the application": {
    en: "Allow users to reset the application",
    pt: "Permitir que os usuários resetem o aplicativo",
    de: "Benutzern ermöglichen, die App zurückzusetzen",
    es: "Permitir a los usuarios restablecer la aplicación",
    fr: "Autoriser les utilisateurs à réinitialiser l'application",
    it: "Consentire agli utenti di ripristinare l'applicazione",
    sq: "Lejoju përdoruesit të rivendosin aplikacionin",
    tr: "Kullanıcıların uygulamayı sıfırlamasına izin ver",
  },
  "Available Credits": {
    en: "Available Credits",
    pt: "Créditos Disponíveis",
    de: "Verfügbare Credits",
    es: "Créditos Disponibles",
    fr: "Crédits Disponibles",
    it: "Crediti Disponibili",
    sq: "Kreditet e disponueshme",
    tr: "Mevcut Krediler",
  },
  Available: {
    en: "Available",
    pt: "Disponível",
    de: "Verfügbar",
    es: "Disponible",
    fr: "Disponible",
    it: "Disponibile",
    sq: "E disponueshme",
    tr: "Mevcut",
  },
  "Available credits": {
    en: "Available credits",
    pt: "Créditos disponíveis",
    de: "Verfügbare Guthaben",
    es: "Créditos disponibles",
    fr: "Crédits disponibles",
    it: "Crediti disponibili",
    sq: "Kreditet e disponueshme",
    tr: "Mevcut krediler",
  },
  "Are you sure to export data": {
    en: "Are you sure to export data?",
    pt: "Tem certeza de que deseja exportar os dados?",
    de: "Möchten Sie die Daten wirklich exportieren?",
    es: "¿Estás seguro de exportar los datos?",
    fr: "Êtes-vous sûr de vouloir exporter les données?",
    it: "Sei sicuro di voler esportare i dati?",
    sq: "Jeni të sigurt që doni të eksportoni të dhënat?",
    tr: "Verileri dışa aktarmak istediğinizden emin misiniz?",
  },
  "Add New Banner": {
    en: "Add New Banner",
    es: "Agregar nuevo banner",
    fr: "Ajouter une nouvelle bannière",
    de: "Neues Banner hinzufügen",
    pt: "Adicionar novo banner",
    it: "Aggiungi nuovo banner",
    sq: "Shto një banner të ri",
    tr: "Yeni Afiş Ekle",
  },
  //b
  "background image size range": {
    en: "The minimal and maximal background image sizes in pixels must be 1280x720 to 3840x2160",
    es: "Los tamaños mínimos y máximos de la imagen de fondo en píxeles deben ser de 1280x720 a 3840x2160",
    fr: "Les tailles minimales et maximales de l'image d'arrière-plan en pixels doivent être de 1280x720 à 3840x2160",
    de: "Die minimalen und maximalen Hintergrundbildgrößen in Pixeln müssen zwischen 1280x720 und 3840x2160 liegen",
    pt: "Os tamanhos mínimos e máximos da imagem de fundo em pixels devem ser de 1280x720 a 3840x2160",
    it: "Le dimensioni minime e massime dell'immagine di sfondo in pixel devono essere da 1280x720 a 3840x2160",
    sq: "Përmasat minimale dhe maksimale të imazhit të sfondit në piksel duhet të jenë nga 1280x720 deri në 3840x2160",
    tr: "Arka plan resim boyutlarının minimum ve maksimum boyutları piksel cinsinden 1280x720 ile 3840x2160 olmalıdır",
  },
  "Banner AD": {
    en: "Banner AD",
    es: "Anuncio de banner",
    fr: "Annonce bannière",
    de: "Bannerwerbung",
    pt: "Anúncio de banner",
    it: "Annuncio banner",
    sq: "Reklamë banner",
    tr: "Afiş Reklamı",
  },
  Block: {
    en: "Block",
    pt: "Bloquear",
    de: "Blockieren",
    es: "Bloquear",
    fr: "Bloquer",
    it: "Blocca",
    sq: "Blloko",
    tr: "Engelle",
  },
  Blocked: {
    en: "Blocked",
    pt: "Bloqueado",
    de: "Blockiert",
    es: "Bloqueado",
    fr: "Bloqué",
    it: "Bloccato",
    sq: "Blokuar",
    tr: "Engellendi",
  },
  "Blocked devices": {
    en: "Blocked devices",
    pt: "Dispositivos bloqueados",
    de: "Blockierte Geräte",
    es: "Dispositivos bloqueados",
    fr: "Appareils bloqués",
    it: "Dispositivi bloccati",
    sq: "Pajisje të bllokuara",
    tr: "Engellendi cihazlar",
  },
  "Blocked date": {
    en: "Blocked date",
    pt: "Data bloqueada",
    de: "Blockiertes Datum",
    es: "Fecha bloqueada",
    fr: "Date bloquée",
    it: "Data bloccata",
    sq: "Data e bllokuar",
    tr: "Engellendi tarih",
  },
  Branding: {
    en: "Branding",
    pt: "Marca",
    de: "Branding",
    es: "Marca",
    fr: "Marque",
    it: "Marchio",
    sq: "Brendimi",
    tr: "Markalama",
  },
  "Back Home": {
    en: "Back Home",
    pt: "Voltar para Casa",
    de: "Zurück zur Startseite",
    es: "Volver a Inicio",
    fr: "Retour à l'accueil",
    it: "Torna a Casa",
    sq: "Kthehu në Shtëpi",
    tr: "Ana Sayfaya Dön",
  },
  "Become a seller": {
    en: "Become a seller",
    pt: "Tornar-se um vendedor",
    de: "Verkäufer werden",
    es: "Convertirse en vendedor",
    fr: "Devenir vendeur",
    it: "Diventa un venditore",
    sq: "Bëhu Shitës",
    tr: "Satıcı Ol",
  },
  Buy: {
    en: "Buy",
    pt: "Comprar",
    de: "Kaufen",
    es: "Comprar",
    fr: "Acheter",
    it: "Acquistare",
    sq: "Blej",
    tr: "Satın Al",
  },
  "Buy Credit": {
    en: "Buy Credit",
    pt: "Comprar créditos",
    de: "Kredit kaufen",
    es: "Comprar crédito",
    fr: "Acheter des crédits",
    it: "Acquista credito",
    sq: "Blej kredi",
    tr: "Kredi satın al",
  },
  "Brand name": {
    en: "Brand name",
    pt: "Nome da marca",
    de: "Markenname",
    es: "Nombre de la marca",
    fr: "Nom de la marque",
    it: "Nome del marchio",
    sq: "Emri i markës",
    tr: "Marka adı",
  },
  "Back To Login": {
    en: "Back To Login",
    pt: "Voltar para o Login",
    de: "Zurück zur Anmeldung",
    es: "Volver a Iniciar Sesión",
    fr: "Retour à la Connexion",
    it: "Torna al Login",
    sq: "Kthehu në Hyrje",
    tr: "Geri Dön Giriş",
  },
  "Billing history": {
    en: "Billing history",
    pt: "Histórico de faturamento",
    de: "Abrechnungshistorie",
    es: "Historial de facturación",
    fr: "Historique de facturation",
    it: "Cronologia delle fatture",
    sq: "Historia e faturimit",
    tr: "Fatura geçmişi",
  },
  "Become A Partner": {
    en: "Become A Partner",
    pt: "Torne-se um Parceiro",
    de: "Werde Partner",
    es: "Conviértete en Socio",
    fr: "Devenir Partenaire",
    it: "Diventa un Partner",
    sq: "Bëhu Partner",
    tr: "Ortak Ol",
  },

  become_seller_option: {
    en: "Become seller option is giving you opportunity to sell add credits to sub reseller from your activation count balance. You can decide what sub reseller can use your activations and what sub reseller need to buy from you to continue using your service. You will be also able to take back credits from sub reseller.",
    pt: "A opção de se tornar vendedor oferece a você a oportunidade de vender créditos adicionais para sub-revendedores a partir do saldo do seu contador de ativações. Você pode decidir o que o sub-revendedor pode usar de suas ativações e o que o sub-revendedor precisa comprar de você para continuar usando seu serviço. Você também poderá recuperar créditos do sub-revendedor.",
    de: "Die Option, Verkäufer zu werden, bietet Ihnen die Möglichkeit, Guthaben aus Ihrem Aktivierungszähler an Unterverkäufer zu verkaufen. Sie können entscheiden, welche Aktivierungen der Unterverkäufer nutzen kann und was der Unterverkäufer von Ihnen kaufen muss, um Ihren Service weiterhin nutzen zu können. Sie werden auch in der Lage sein, Guthaben vom Unterverkäufer zurückzufordern.",
    es: "La opción de convertirse en vendedor le brinda la oportunidad de vender créditos adicionales a sub revendedores desde el saldo de su contador de activaciones. Usted puede decidir qué puede utilizar el sub revendedor de sus activaciones y qué debe comprar el sub revendedor de usted para seguir utilizando su servicio. También podrá recuperar créditos del sub revendedor.",
    fr: "L'option de devenir vendeur vous offre la possibilité de vendre des crédits supplémentaires aux sous-revendeurs à partir de votre solde de compteur d'activation. Vous pouvez décider ce que le sous-revendeur peut utiliser de vos activations et ce que le sous-revendeur doit acheter auprès de vous pour continuer à utiliser votre service. Vous pourrez également récupérer des crédits auprès du sous-revendeur.",
    it: "L'opzione di diventare venditore ti offre l'opportunità di vendere crediti aggiuntivi a sub rivenditori dal saldo del tuo contatore di attivazioni. Puoi decidere cosa il sub rivenditore può utilizzare delle tue attivazioni e cosa il sub rivenditore deve acquistare da te per continuare a utilizzare il tuo servizio. Sarai inoltre in grado di recuperare i crediti dal sub rivenditore.",
    sq: "Opsioni për të bërë shitës ju jep mundësinë të shisni kredite shtesë në nën-shitës nga balanca e llogaritësit tuaj të aktivizimit. Ju mund të vendosni se çfarë mund të përdorë nën-shitësi nga aktivizimet tuaja dhe se çfarë duhet të blejë nën-shitësi nga ju për të vazhduar përdorimin e shërbimit tuaj. Ju do të mundeni gjithashtu të merrni prapa kreditet nga nën-shitësi.",
    tr: "Satıcı olma seçeneği, aktivasyon sayısı bakiyenizden alt satıcılara ek kredi satma fırsatı sunmaktadır. Hangi alt satıcının aktivasyonlarınızı kullanabileceğine ve hangi alt satıcının hizmetinizi kullanmaya devam etmek için sizden ne satın alması gerektiğine karar verebilirsiniz. Ayrıca alt satıcılardan kredileri geri alma imkanına da sahip olacaksınız.",
  },

  "By admin": {
    en: "By admin",
    pt: "Pelo administrador",
    de: "Vom Administrator",
    es: "Por el administrado",
    fr: "Par l'administrateur",
    it: "Da amministratore",
    sq: "Nga administratori",
    tr: "Yönetici tarafından",
  },

  "By platforms": {
    en: "By platforms",
    pt: "Por plataformas",
    de: "Nach Plattformen",
    es: "Por plataformas",
    fr: "Par plateformes",
    it: "Per piattaforme",
    sq: "Nga platforma",
    tr: "Platformlara göre",
  },

  "By device key": {
    en: "By device key",
    pt: "Por chave do dispositivo",
    de: "Nach Geräteschlüssel",
    es: "Por clave del dispositivo",
    fr: "Par clé de l'appareil",
    it: "Per chiave del dispositivo",
    sq: "Nga çelësi i pajisjes",
    tr: "Cihaz anahtarı ile",
  },

  "By registration date": {
    en: "By registration date",
    pt: "Por data de registro",
    de: "Nach Anmeldedatum",
    es: "Por fecha de registro",
    fr: "Par date d'inscription",
    it: "Per data di registrazione",
    sq: "Nga data e regjistrimit",
    tr: "Kayıt tarihine göre",
  },

  "Brand name must include only letters or numbers.": {
    en: "Brand name must include only letters or numbers.",
    pt: "O nome da marca deve conter apenas letras ou números.",
    de: "Der Markenname darf nur Buchstaben oder Zahlen enthalten.",
    es: "El nombre de la marca debe contener solo letras o números.",
    fr: "Le nom de la marque ne doit contenir que des lettres ou des chiffres.",
    it: "Il nome del marchio deve contenere solo lettere o numeri.",
    sq: "Emri i markës duhet të përfshijë vetëm shkronja ose numra.",
    tr: "Marka adı yalnızca harfler veya sayılar içermelidir.",
  },

  "By clicking on menu you can": {
    en: "By clicking on the 'Action' menu, you can 'Edit' some credentials of the chosen device or 'Deactivate/Activate' the device active/inactive period.",
    pt: "Ao clicar no menu 'Ação', você pode 'Editar' algumas credenciais do dispositivo escolhido ou 'Desativar/Ativar' o período ativo/inativo do dispositivo.",
    de: "Durch Klicken auf das 'Aktion'-Menü können Sie einige Anmeldeinformationen des ausgewählten Geräts 'Bearbeiten' oder das aktive/inaktive Zeitintervall des Geräts 'Deaktivieren/Aktivieren'.",
    es: "Al hacer clic en el menú 'Acción', puedes 'Editar' algunas credenciales del dispositivo seleccionado o 'Desactivar/Activar' el período activo/inactivo del dispositivo.",
    fr: "En cliquant sur le menu 'Action', vous pouvez 'Modifier' certaines informations d'identification du dispositif sélectionné ou 'Désactiver/Activer' la période active/inactive du dispositif.",
    it: "Cliccando sul menu 'Azione', puoi 'Modificare' alcune credenziali del dispositivo scelto o 'Disattivare/Attivare' il periodo attivo/inattivo del dispositivo.",
    sq: "Duke klikuar në meny mundeni",
    tr: "'Eylem' menüsüne tıklayarak, seçilen cihazın bazı kimlik bilgilerini 'Düzenleyebilir' veya cihazın aktif/pasif dönemini 'Devre Dışı Bırakabilir/Aktif Edilebilir' olursunuz.",
  },

  "Browser version": {
    en: "Browser version",
    pt: "Versão do navegador",
    de: "Browser-Version",
    es: "Versión del navegador",
    fr: "Version du navigateur",
    it: "Versione del browser",
    sq: "Versioni i shfletuesit",
    tr: "Tarayıcı sürümü",
  },

  "Edit New Banner": {
    en: "Edit New Banner",
    es: "Editar nuevo banner",
    fr: "Modifier la nouvelle bannière",
    de: "Neues Banner bearbeiten",
    pt: "Editar novo banner",
    it: "Modifica nuovo banner",
    sq: "Redakto banerin e ri",
    tr: "Yeni Afişi Düzenle",
  },

  "End date": {
    en: "End date",
    es: "Fecha de finalización",
    fr: "Date de fin",
    de: "Enddatum",
    pt: "Data de término",
    it: "Data di fine",
    sq: "Data e përfundimit",
    tr: "Bitiş tarihi",
  },

  "Export CSV": {
    en: "Export CSV",
    pt: "Exportar CSV",
    de: "CSV exportieren",
    es: "Exportar CSV",
    fr: "Exporter CSV",
    it: "Esporta CSV",
    sq: "Eksporto CSV",
    tr: "CSV'yi Dışa Aktar",
  },

  "Earn money": {
    en: "Earn money",
    pt: "Ganhar dinheiro",
    de: "Geld verdienen",
    es: "Ganar dinero",
    fr: "Gagner de l'argent",
    it: "Guadagnare denaro",
    sq: "Fitoni para",
    tr: "Para kazan",
  },
  Episode: {
    en: "Episode",
    pt: "Episódio",
    de: "Folge",
    es: "Episodio",
    fr: "Épisode",
    it: "Episodio",
    sq: "Episod",
    tr: "Bölüm",
  },
  Edit: {
    en: "Edit",
    pt: "Editar",
    de: "Bearbeiten",
    es: "Editar",
    fr: "Modifier",
    it: "Modifica",
    sq: "Ndrysho",
    tr: "Düzenle",
  },
  "Edit profile": {
    en: "Edit profile",
    pt: "Editar perfil",
    de: "Profil bearbeiten",
    es: "Editar perfil",
    fr: "Modifier le profil",
    it: "Modifica profilo",
    sq: "Ndrysho profilin",
    tr: "Profili düzenle",
  },
  Email: {
    en: "Email",
    pt: "Email",
    de: "E-Mail",
    es: "Correo electrónico",
    fr: "E-mail",
    it: "Email",
    sq: "Email",
    tr: "E-posta",
  },
  "Enter your email": {
    en: "Enter your email",
    pt: "Digite seu e-mail",
    de: "Geben Sie Ihre E-Mail ein",
    es: "Ingrese su correo electrónico",
    fr: "Saisissez votre adresse e-mail",
    it: "Inserisci la tua email",
    sq: "Shkruaj email-in tënd (të gjitha shkronjat e vogla)",
    tr: "E-posta adresinizi girin",
  },
  "Enter 2FA verification code": {
    en: "Enter 2FA verification code",
    pt: "Digite o código de verificação 2FA",
    de: "Geben Sie den 2FA-Verifizierungscode ein",
    es: "Ingrese el código de verificación 2FA",
    fr: "Saisissez le code de vérification 2FA",
    it: "Inserisci il codice di verifica 2FA",
    sq: "Fut kodin e verifikimit 2FA",
    tr: "2FA doğrulama kodunu girin",
  },
  "Enable 2FA": {
    en: "Enable 2FA",
    pt: "Ativar 2FA",
    de: "2FA aktivieren",
    es: "Activar 2FA",
    fr: "Activer 2FA",
    it: "Abilita 2FA",
    sq: "Aktivizo 2FA",
    tr: "2FA'yı etkinleştir",
  },
  "Enter code": {
    en: "Enter code",
    pt: "Digite o código",
    de: "Code eingeben",
    es: "Ingresar código",
    fr: "Entrer le code",
    it: "Inserisci il codice",
    sq: "Fut kodin",
    tr: "Kodu girin",
  },
  Error: {
    en: "Error",
    pt: "Erro",
    de: "Fehler",
    es: "Error",
    fr: "Erreur",
    it: "Errore",
    sq: "Gabim",
    tr: "Hata",
  },
  "Error logs": {
    en: "Error logs",
    pt: "Registros de erro",
    de: "Fehlerprotokolle",
    es: "Registros de errores",
    fr: "Journaux d'erreurs",
    it: "Log degli errori",
    sq: "Regjistrat e gabimit",
    tr: "Hata günlükleri",
  },
  "Error count": {
    en: "Error count",
    pt: "Contagem de erros",
    de: "Fehleranzahl",
    es: "Contador de errores",
    fr: "Nombre d'erreurs",
    it: "Conteggio errori",
    sq: "Numri i gabimit",
    tr: "Hata sayısı",
  },
  "Edit DNS": {
    en: "Edit DNS",
    pt: "Editar DNS",
    de: "DNS bearbeiten",
    es: "Editar DNS",
    fr: "Modifier le DNS",
    it: "Modifica DNS",
    sq: "Modifiko DNS",
    tr: "DNS'yi düzenle",
  },
  "Edit playlist": {
    en: "Edit playlist",
    pt: "Editar lista de reprodução",
    de: "Playlist bearbeiten",
    es: "Editar lista de reproducción",
    fr: "Modifier la playlist",
    it: "Modifica playlist",
    sq: "Modifiko playlistën",
    tr: "Çalma listesini düzenle",
  },
  "Expired date": {
    en: "Expired date",
    pt: "Data de expiração",
    de: "Ablaufdatum",
    es: "Fecha de vencimiento",
    fr: "Date d'expiration",
    it: "Data di scadenza",
    sq: "Data e skaducimit",
    tr: "Sonuç tarihi",
  },
  Expired: {
    en: "Expired",
    pt: "Expirado",
    de: "Abgelaufen",
    es: "Caducado",
    fr: "Expiré",
    it: "Scaduto",
    sq: "Skadet",
    tr: "Süresi dolmuş",
  },
  "Email address": {
    en: "Email address",
    pt: "Endereço de e-mail",
    de: "E-Mail-Adresse",
    es: "Dirección de correo electrónico",
    fr: "Adresse e-mail",
    it: "Indirizzo email",
    sq: "Adresa e emailit",
    tr: "E-posta adresi",
  },
  "Export excel": {
    en: "Export excel",
    pt: "Exportar para o Excel",
    de: "Excel exportieren",
    es: "Exportar a Excel",
    fr: "Exporter vers Excel",
    it: "Esporta in Excel",
    sq: "Eksporto Excel",
    tr: "Excel'e aktar",
  },
  "Edit Sub-Reseller": {
    en: "Edit Sub-Reseller",
    pt: "Editar Sub-Revendedor",
    de: "Unterwiederverkäufer bearbeiten",
    es: "Editar subrevendedor",
    fr: "Modifier le sous-revendeur",
    it: "Modifica sub-rivenditore",
    sq: "Ndrysho Nën-Shitjen",
    tr: "Alt-Bayi düzenle",
  },
  "Enter server host": {
    en: "Enter server host",
    pt: "Digite o host do servidor",
    de: "Server-Host eingeben",
    es: "Introduce el host del servidor",
    fr: "Entrez l'hôte du serveur",
    it: "Inserisci l'host del server",
    sq: "Shkruaj hostin e serverit",
    tr: "Sunucu ana bilgisayarını girin",
  },
  "Enter playlist URL": {
    en: "Enter playlist URL",
    pt: "Digite a URL da lista de reprodução",
    de: "Playlist-URL eingeben",
    es: "Introduce la URL de la lista de reproducción",
    fr: "Entrez l'URL de la playlist",
    it: "Inserisci l'URL della playlist",
    sq: "Shkruaj URL-në e Playlistës",
    tr: "Çalma listesi URL'sini girin",
  },
  "Enter device key": {
    en: "Enter device key",
    pt: "Digite a chave do dispositivo",
    de: "Geräteschlüssel eingeben",
    es: "Introduce la clave del dispositivo",
    fr: "Entrez la clé de l'appareil",
    it: "Inserisci la chiave del dispositivo",
    sq: "Shkruaj çelësin e pajisjes",
    tr: "Cihaz anahtarını girin",
  },
  "Enabling 2FA": {
    en: "Enabling 2FA",
    pt: "Ativando a autenticação de dois fatores",
    de: "2-Faktor-Authentifizierung aktivieren",
    es: "Activando la autenticación de dos factores",
    fr: "Activation de l'authentification à deux facteurs",
    it: "Abilitazione della doppia autenticazione",
    sq: "Aktivizimi i 2FA",
    tr: "İki faktörlü kimlik doğrulama etkinleştiriliyor",
  },
  "Enter 2FA email address": {
    en: "Enter 2FA email address",
    pt: "Digite o endereço de e-mail para a autenticação de dois fatores",
    de: "Geben Sie die E-Mail-Adresse für die Zwei-Faktor-Authentifizierung ein",
    es: "Ingrese la dirección de correo electrónico para la autenticación de dos factores",
    fr: "Entrez l'adresse e-mail pour l'authentification à deux facteurs",
    it: "Inserisci l'indirizzo email per l'autenticazione a due fattori",
    sq: "Shkruaj adresën email për 2FA",
    tr: "İki faktörlü kimlik doğrulama için e-posta adresini girin",
  },
  "E-mail isn't valid": {
    en: "E-mail isn't valid!",
    pt: "E-mail não é válido!",
    de: "E-Mail ist ungültig!",
    es: "¡El correo electrónico no es válido!",
    fr: "L'adresse e-mail n'est pas valide !",
    it: "L'indirizzo email non è valido!",
    sq: "Email-i nuk është valid!",
    tr: "E-posta geçerli değil!",
  },
  "Expired remaining days": {
    en: "Expired remaining days",
    pt: "Dias restantes de expiração",
    de: "Verbleibende Tage bis zum Ablauf",
    es: "Días restantes de vencimiento",
    fr: "Jours restants avant expiration",
    it: "Giorni rimasti alla scadenza",
    sq: "Ditet e mbetura të skaduara",
    tr: "Son kullanıma kalan günler",
  },
  "Enable Two-Factor Authentication": {
    en: "Enable Two-Factor Authentication",
    pt: "Ativar Autenticação de Dois Fatores",
    de: "Zwei-Faktor-Authentifizierung aktivieren",
    es: "Habilitar Autenticación de Dos Factores",
    fr: "Activer l'authentification à deux facteurs",
    it: "Abilita l'Autenticazione a Due Fattori",
    sq: "Aktivizo Autentikimin Dy-Faktorial",
    tr: "İki Faktörlü Kimlik Doğrulamayı Etkinleştir",
  },
  //c
  "Customize Layout": {
    en: "Customize Layout",
    es: "Personalizar diseño",
    fr: "Personnaliser la mise en page",
    de: "Layout anpassen",
    pt: "Personalizar layout",
    it: "Personalizza layout",
    sq: "Përshtat paraqitjen",
    tr: "Düzeni Özelleştir",
  },
  company: {
    en: "company",
    pt: "empresa",
    de: "Unternehmen",
    es: "empresa",
    fr: "entreprise",
    it: "azienda",
    sq: "kompani",
    tr: "şirket",
  },
  Continue: {
    en: "Continue",
    pt: "Continuar",
    de: "Weiter",
    es: "Continuar",
    fr: "Continuer",
    it: "Continua",
    sq: "Vazhdo",
    tr: "Devam et",
  },
  Card: {
    en: "Card",
    pt: "Cartão",
    de: "Karte",
    es: "Tarjeta",
    fr: "Carte",
    it: "Carta",
    sq: "Kartë",
    tr: "Kart",
  },
  Confirm: {
    en: "Confirm",
    pt: "Confirmar",
    de: "Bestätigen",
    es: "Confirmar",
    fr: "Confirmer",
    it: "Confermare",
    sq: "Konfirmo",
    tr: "Onayla",
  },
  Contacts: {
    en: "Contacts",
    pt: "Contatos",
    de: "Kontakte",
    es: "Contactos",
    fr: "Contacts",
    it: "Contatti",
    sq: "Kontakte",
    tr: "İletişimler",
  },
  "Contact us on": {
    en: "Contact us on",
    pt: "Contate-nos em",
    de: "Kontaktieren Sie uns unter",
    es: "Contáctenos en",
    fr: "Contactez-nous au",
    it: "Contattaci su",
    sq: "Na kontaktoni në",
    tr: "Bize şu adresten ulaşın",
  },
  close: {
    en: "close",
    pt: "fechar",
    de: "schließen",
    es: "cerrar",
    fr: "fermer",
    it: "chiudi",
    sq: "Mbyll",
    tr: "kapat",
  },
  Count: {
    en: "Count",
    pt: "Contagem",
    de: "Zählen",
    es: "Contar",
    fr: "Compter",
    it: "Conteggio",
    sq: "Llogaritje",
    tr: "Say",
  },
  custom: {
    en: "custom",
    pt: "personalizado",
    de: "individuell",
    es: "personalizado",
    fr: "personnalisé",
    it: "personalizzato",
    sq: "Personalizuar",
    tr: "özel",
  },
  Credit: {
    en: "Credit",
    pt: "Crédito",
    de: "Guthaben",
    es: "Crédito",
    fr: "Crédit",
    it: "Credito",
    sq: "Kredit",
    tr: "Kredi",
  },
  Credits: {
    en: "Credits",
    pt: "Créditos",
    de: "Guthaben",
    es: "Créditos",
    fr: "Crédits",
    it: "Crediti",
    sq: "Kreditet",
    tr: "Krediler",
  },
  cancel: {
    en: "cancel",
    pt: "cancelar",
    de: "abbrechen",
    es: "cancelar",
    fr: "annuler",
    it: "annulla",
    sq: "anuloj",
    tr: "iptal",
  },
  Cancel: {
    en: "Cancel",
    pt: "Cancelar",
    de: "Abbrechen",
    es: "Cancelar",
    fr: "Annuler",
    it: "Annulla",
    sq: "Anuloj",
    tr: "İptal",
  },
  Check: {
    en: "Check",
    pt: "Verificar",
    de: "Überprüfen",
    es: "Comprobar",
    fr: "Vérifier",
    it: "Controlla",
    sq: "Kontrollo",
    tr: "Kontrol Et",
  },
  Country: {
    en: "Country",
    pt: "País",
    de: "Land",
    es: "País",
    fr: "Pays",
    it: "Paese",
    sq: "Shteti",
    tr: "Ülke",
  },
  "Coming soon": {
    en: "Coming soon",
    pt: "Em breve",
    de: "Demnächst",
    es: "Próximamente",
    fr: "Bientôt disponible",
    it: "Prossimamente",
    sq: "Së shpejti",
    tr: "Yakında",
  },
  "custom DNS": {
    en: "custom DNS",
    pt: "DNS personalizado",
    de: "Individuelle DNS",
    es: "DNS personalizado",
    fr: "DNS personnalisé",
    it: "DNS personalizzato",
    sq: "DNS i personalizuar",
    tr: "özel DNS",
  },
  "Custom Playlist": {
    en: "Custom Playlist",
    pt: "Lista de Reprodução Personalizada",
    de: "Benutzerdefinierte Wiedergabeliste",
    es: "Lista de Reproducción Personalizada",
    fr: "Playlist Personnalisée",
    it: "Playlist Personalizzata",
    sq: "Lista e Përmbajtjes së Personalizuar",
    tr: "Özel Oynatma Listesi",
  },
  "Created date": {
    en: "Created date",
    pt: "Data de criação",
    de: "Erstellungsdatum",
    es: "Fecha de creación",
    fr: "Date de création",
    it: "Data di creazione",
    sq: "Data e krijimit",
    tr: "Oluşturulma tarihi",
  },

  "Confirmation code": {
    en: "Confirmation code",
    pt: "Código de confirmação",
    de: "Bestätigungscode",
    es: "Código de confirmación",
    fr: "Code de confirmation",
    it: "Codice di conferma",
    sq: "Kodi i konfirmimit",
    tr: "Kod onayı",
  },
  "Change language": {
    en: "Change language",
    pt: "Alterar idioma",
    de: "Sprache ändern",
    es: "Cambiar idioma",
    fr: "Changer de langue",
    it: "Cambia lingua",
    sq: "Ndrysho gjuhën",
    tr: "Dili değiştir",
  },
  "Card number": {
    en: "Card number",
    pt: "Número do cartão",
    de: "Kartennummer",
    es: "Número de tarjeta",
    fr: "Numéro de carte",
    it: "Numero di carta",
    sq: "Numri i kartës",
    tr: "Kart numarası",
  },
  "Change Parental code": {
    en: "Change Parental code",
    pt: "Alterar código de controle parental",
    de: "Elterncode ändern",
    es: "Cambiar código de control parental",
    fr: "Changer le code parental",
    it: "Cambia codice genitore",
    sq: "Ndrysho kodin prindëror",
    tr: "Ebeveyn kodunu değiştir",
  },
  "Credits count must be greater than": {
    en: "Credits count must be greater than",
    pt: "A contagem de créditos deve ser maior que",
    de: "Die Anzahl der Guthaben muss größer sein als",
    es: "El recuento de créditos debe ser mayor que",
    fr: "Le nombre de crédits doit être supérieur à",
    it: "Il conteggio dei crediti deve essere superiore a",
    sq: "Numri i kredive duhet të jetë më i madh se",
    tr: "Kredi sayısı daha büyük olmalıdır",
  },
  "Choose the server you want to transfer from": {
    en: "Choose the server you want to transfer from",
    pt: "Escolha o servidor de onde deseja transferir",
    de: "Wählen Sie den Server aus, von dem Sie übertragen möchten",
    es: "Elija el servidor del que desea transferir",
    fr: "Choisissez le serveur que vous souhaitez transférer depuis",
    it: "Scegli il server da cui desideri trasferire",
    sq: "Zgjidhni serverin nga i cili dëshironi të transferoni",
    tr: "Transfer etmek istediğiniz sunucuyu seçin",
  },
  "Credit will be charged for each activation": {
    en: "1 Credit will be charged for each activation.",
    pt: "1 crédito será cobrado para cada ativação.",
    de: "Für jede Aktivierung wird 1 Guthaben berechnet.",
    es: "Se cobrará 1 crédito por cada activación.",
    fr: "1 crédit sera facturé pour chaque activation.",
    it: "1 credito sarà addebitato per ogni attivazione.",
    sq: "1 Kredi do të tarifohet për çdo aktivizim.",
    tr: "Her aktivasyon için 1 kredi tahsil edilecektir.",
  },
  "Click here to complete your payment": {
    en: "Click here to complete your payment",
    pt: "Clique aqui para concluir o pagamento",
    de: "Klicken Sie hier, um die Zahlung abzuschließen",
    es: "Haga clic aquí para completar su pago",
    fr: "Cliquez ici pour finaliser votre paiement",
    it: "Clicca qui per completare il pagamento",
    sq: "Kliko këtu për të përfunduar pagesën tuaj",
    tr: "Ödemenizi tamamlamak için buraya tıklayın.",
  },

  //j
  "Join WhatsApp group": {
    en: "Join WhatsApp group",
    pt: "Entrar no grupo do WhatsApp",
    de: "WhatsApp-Gruppe beitreten",
    es: "Unirse al grupo de WhatsApp",
    fr: "Rejoindre le groupe WhatsApp",
    it: "Unisciti al gruppo WhatsApp",
    sq: "Bashkohu në grupin WhatsApp",
    tr: "WhatsApp grubuna katıl",
  },
  "Join Telegram group": {
    en: "Join Telegram group",
    pt: "Participar do grupo no Telegram",
    de: "Dem Telegram-Gruppe beitreten",
    es: "Unirse al grupo de Telegram",
    fr: "Rejoindre le groupe Telegram",
    it: "Unisciti al gruppo Telegram",
    sq: "Bashkohu në grupin Telegram",
    tr: "Telegram grubuna katıl",
  },
  "Join community": {
    en: "Join community",
    pt: "Participe da comunidade",
    de: "Der Community beitreten",
    es: "Únete a la comunidad",
    fr: "Rejoindre la communauté",
    it: "Unisciti alla comunità",
    sq: "Bashkohu në komunitet",
    tr: "Topluluğa katıl",
  },

  "Join Our Telegram Community": {
    en: "Join Our Telegram Community",
    pt: "Junte-se à Nossa Comunidade no Telegram",
    de: "Treten Sie unserer Telegram-Community bei",
    es: "Únete a nuestra comunidad de Telegram",
    fr: "Rejoignez notre communauté Telegram",
    it: "Unisciti alla nostra comunità Telegram",
    sq: "Bashkohuni me Komunitetin Tonë në Telegram",
    tr: "Telegram Topluluğumuza Katıl",
  },

  //i
  "Incorrect email or password": {
    en: "Incorrect email or password",
    pt: "E-mail ou senha incorretos",
    de: "Falsche E-Mail oder falsches Passwort",
    es: "Correo electrónico o contraseña incorrectos",
    fr: "E-mail ou mot de passe incorrect",
    it: "E-mail o password errati",
    sq: "Email ose fjalëkalim i pasaktë",
    tr: "E-posta veya şifre yanlış",
  },
  "invalid image file": {
    en: "Invalid image file",
    es: "Archivo de imagen no válido",
    fr: "Fichier image invalide",
    de: "Ungültige Bilddatei",
    pt: "Arquivo de imagem inválido",
    it: "File immagine non valido",
    sq: "Skedar imazhi i pavlefshëm",
    tr: "Geçersiz resim dosyası",
  },
  "Images must be less than": {
    en: "Images must be less than",
    es: "Las imágenes deben ser menos de",
    fr: "Les images doivent être inférieures à",
    de: "Bilder müssen weniger als",
    pt: "As imagens devem ser menos de",
    it: "Le immagini devono essere meno di",
    sq: "Imazhet duhet të jenë më pak se",
    tr: "Resimler daha az olmalıdır",
  },
  "in size": {
    en: "in size",
    es: "en tamaño",
    fr: "en taille",
    de: "in der Größe",
    pt: "em tamanho",
    it: "in dimensione",
    sq: "në madhësi",
    tr: "boyutunda",
  },
  image: {
    en: "Image",
    es: "Imagen",
    fr: "Image",
    de: "Bild",
    pt: "Imagem",
    it: "Immagine",
    sq: "Imazh",
    tr: "Görüntü",
  },
  "If this option is enabled, resellers will have the option to automatically register with you as sub resellers. If not, you have to manually accept their requests.":
    {
      en: "If this option is enabled, resellers will have the option to automatically register with you as sub resellers. If not, you have to manually accept their requests.",
      pt: "Se esta opção estiver ativada, os revendedores terão a opção de se registrar automaticamente com você como sub-revendedores. Se não, você terá que aceitar manualmente os pedidos deles.",
      de: "Wenn diese Option aktiviert ist, können Wiederverkäufer sich automatisch bei Ihnen als Unterverkäufer registrieren. Andernfalls müssen Sie ihre Anfragen manuell akzeptieren.",
      es: "Si esta opción está habilitada, los revendedores tendrán la opción de registrarse automáticamente contigo como subrevendedores. Si no, tendrás que aceptar manualmente sus solicitudes.",
      fr: "Si cette option est activée, les revendeurs auront la possibilité de s'inscrire automatiquement chez vous en tant que sous-revendeurs. Sinon, vous devrez accepter manuellement leurs demandes.",
      it: "Se questa opzione è abilitata, i rivenditori avranno la possibilità di registrarsi automaticamente con te come sub-rivenditori. In caso contrario, dovrai accettare manualmente le loro richieste.",
      sq: "Nëse kjo opsion është i aktivizuar, rivendorët do të kenë mundësinë të regjistrohen automatikisht me ju si nën-rivendorë. Nëse jo, duhet të pranoni manualisht kërkesat e tyre.",
      tr: "Bu seçenek etkinleştirildiğinde, bayiilerin sizinle otomatik olarak alt bayi olarak kaydolma seçeneği olacak. Aksi takdirde, taleplerini manuel olarak kabul etmeniz gerekecek.",
    },
  Info: {
    en: "Info",
    pt: "Informação",
    de: "Info",
    es: "Información",
    fr: "Info",
    it: "Info",
    sq: "Informacion",
    tr: "Bilgi",
  },

  //d
  Date: {
    en: "Date",
    es: "Fecha",
    fr: "Date",
    de: "Datum",
    pt: "Data",
    it: "Data",
    sq: "Data",
    tr: "Tarih",
  },
  Device: {
    en: "Device",
    pt: "Dispositivo",
    de: "Gerät",
    es: "Dispositivo",
    fr: "Appareil",
    it: "Dispositivo",
    sq: "Pajisje",
    tr: "Cihaz",
  },
  Devices: {
    en: "Devices",
    pt: "Dispositivos",
    de: "Geräte",
    es: "Dispositivos",
    fr: "Appareils",
    it: "Dispositivi",
    sq: "Pajisje",
    tr: "Cihazlar",
  },
  Deactive: {
    en: "Deactive",
    pt: "Inativo",
    de: "Inaktiv",
    es: "Inactivo",
    fr: "Inactif",
    it: "Disattivo",
    sq: "Inaktiv",
    tr: "Pasif",
  },

  "Do you want to reset": {
    en: "Do you want to reset?",
    pt: "Deseja redefinir?",
    de: "Möchten Sie zurücksetzen?",
    es: "¿Quieres restablecer?",
    fr: "Voulez-vous réinitialiser?",
    it: "Vuoi ripristinare?",
    sq: "Dëshironi të rivendosni?",
    tr: "Sıfırlamak istiyor musunuz?",
  },

  "Device not found": {
    en: "Device not found",
    pt: "Dispositivo não encontrado",
    de: "Gerät nicht gefunden",
    es: "Dispositivo no encontrado",
    fr: "Appareil non trouvé",
    it: "Dispositivo non trovato",
    sq: "Pajisja nuk u gjet",
    tr: "Cihaz bulunamadı",
  },
  Disabled: {
    en: "Disabled",
    pt: "Desativado",
    de: "Deaktiviert",
    es: "Desactivado",
    fr: "Désactivé",
    it: "Disabilitato",
    sq: "E padisponueshëm",
    tr: "Devre dışı",
  },
  "Deactivate device": {
    en: "Deactivate device",
    pt: "Desativar dispositivo",
    de: "Gerät deaktivieren",
    es: "Desactivar dispositivo",
    fr: "Désactiver l'appareil",
    it: "Disattivare dispositivo",
    sq: "Çaktivizo pajisjen",
    tr: "Cihazı devre dışı bırak",
  },
  Deactivate: {
    en: "Deactivate",
    pt: "Desativar",
    de: "Deaktivieren",
    es: "Desactivar",
    fr: "Désactiver",
    it: "Disattiva",
    sq: "Çaktivizo",
    tr: "Devre dışı bırak",
  },
  Dashboard: {
    en: "Dashboard",
    pt: "Painel",
    de: "Dashboard",
    es: "Tablero",
    fr: "Tableau de bord",
    it: "Cruscotto",
    sq: "Paneli",
    tr: "Gösterge paneli",
  },
  "Device key": {
    en: "Device key",
    pt: "Chave do dispositivo",
    de: "Geräteschlüssel",
    es: "Clave del dispositivo",
    fr: "Clé de l'appareil",
    it: "Chiave del dispositivo",
    sq: "Çelësi i pajisjes",
    tr: "Cihaz anahtarı",
  },
  "Devices count": {
    en: "Devices count",
    pt: "Contagem de dispositivos",
    de: "Geräteanzahl",
    es: "Conteo de dispositivos",
    fr: "Nombre d'appareils",
    it: "Conteggio dispositivi",
    sq: "Numri i pajisjeve",
    tr: "Cihaz sayısı",
  },
  Deactivated: {
    en: "Deactivated",
    pt: "Desativado",
    de: "Deaktiviert",
    es: "Desactivado",
    fr: "Désactivé",
    it: "Disattivato",
    sq: "Deaktivizuar",
    tr: "Devre dışı bırakıldı",
  },
  "Deactivated by": {
    en: "Deactivated by",
    pt: "Desativado por",
    de: "Deaktiviert von",
    es: "Desactivado por",
    fr: "Désactivé par",
    it: "Disattivato da",
    sq: "Deaktivizuar nga",
    tr: "Devre dışı bırakan",
  },
  Date: {
    en: "Date",
    es: "Fecha",
    fr: "Date",
    de: "Datum",
    pt: "Data",
    it: "Data",
    sq: "Data",
    tr: "Tarih",
  },
  "Deactivated date": {
    en: "Deactivated date",
    pt: "Data de desativação",
    de: "Deaktivierungsdatum",
    es: "Fecha de desactivación",
    fr: "Date de désactivation",
    it: "Data di disattivazione",
    sq: "Data e deaktivizimit",
    tr: "Devre dışı bırakılma tarihi",
  },
  "Deactivated Devices History": {
    en: "Deactivated Devices History",
    pt: "Histórico de Dispositivos Desativados",
    de: "Deaktivierte Gerätehistorie",
    es: "Historial de Dispositivos Desactivados",
    fr: "Historique des Appareils Désactivés",
    it: "Cronologia Dispositivi Disattivati",
    sq: "Historia e Dispositivëve të Deaktivizuar",
    tr: "Devre Dışı Bırakılan Cihazların Geçmişi",
  },
  "Deactivated history": {
    en: "Deactivated history",
    pt: "Histórico desativado",
    de: "Verlauf deaktiviert",
    es: "Historial desactivado",
    fr: "Historique désactivé",
    it: "Cronologia disattivata",
    sq: "Historia e deaktivizuar",
    tr: "Devre Dışı Bırakılan Geçmiş",
  },
  Description: {
    en: "Description",
    pt: "Descrição",
    de: "Beschreibung",
    es: "Descripción",
    fr: "Description",
    it: "Descrizione",
    sq: "Përshkrim",
    tr: "Açıklama",
  },
  "Device count": {
    en: "Device count",
    pt: "Contagem de dispositivos",
    de: "Geräteanzahl",
    es: "Cantidad de dispositivos",
    fr: "Nombre d'appareils",
    it: "Conteggio dei dispositivi",
    sq: "Numri i pajisjeve",
    tr: "Cihaz sayısı",
  },
  "Device UUID": {
    en: "Device UUID",
    pt: "UUID do dispositivo",
    de: "Geräte UUID",
    es: "UUID del dispositivo",
    fr: "UUID de l'appareil",
    it: "UUID del dispositivo",
    sq: "UUID e pajisjes",
    tr: "Cihaz UUID",
  },
  "Device Config": {
    en: "Device Config",
    pt: "Configuração do dispositivo",
    de: "Gerätekonfiguration",
    es: "Configuración del dispositivo",
    fr: "Configuration de l'appareil",
    it: "Configurazione del dispositivo",
    sq: "Konfigurimi i pajisjes",
    tr: "Cihaz Yapılandırması",
  },
  "Don't have account": {
    en: "Don't have account?",
    pt: "Não tem uma conta?",
    de: "Haben Sie kein Konto?",
    es: "¿No tienes una cuenta?",
    fr: "Vous n'avez pas de compte ?",
    it: "Non hai un account?",
    sq: "Nuk keni llogari?",
    tr: "Hesabınız yok mu?",
  },
  "Deactivated devices": {
    en: "Deactivated devices",
    pt: "Dispositivos desativados",
    de: "Deaktivierte Geräte",
    es: "Dispositivos desactivados",
    fr: "Appareils désactivés",
    it: "Dispositivi disattivati",
    sq: "Pajisjet e çaktivizuara",
    tr: "Devre dışı bırakılan cihazlar",
  },

  "DE:Sky Thriller HD+": {
    en: "DE:Sky Thriller HD+",
    pt: "DE: Sky Thriller HD+",
    de: "DE: Sky Thriller HD+",
    es: "DE: Sky Thriller HD+",
    fr: "DE: Sky Thriller HD+",
    it: "DE: Sky Thriller HD+",
    sq: "DE: Sky Thriller HD+",
    tr: "DE: Sky Thriller HD+",
  },
  "Date (MM/YY)": {
    en: "Date (MM/YY)",
    pt: "Data (MM/AA)",
    de: "Datum (MM/YY)",
    es: "Fecha (MM/AA)",
    fr: "Date (MM/AA)",
    it: "Data (MM/AA)",
    sq: "Data (MM/VV)",
    tr: "Tarih (AA/YY)",
  },

  "Do you want to delete this": {
    en: "Do you want to delete this",
    pt: "Deseja excluir isso",
    de: "Möchten Sie dies löschen",
    es: "¿Quieres eliminar esto?",
    fr: "Voulez-vous supprimer ceci?",
    it: "Vuoi eliminare questo?",
    sq: "Dëshironi të fshini këtë?",
    tr: "Bunu silmek istiyor musunuz?",
  },
  "DNS deleted successfully": {
    en: "DNS deleted successfully",
    pt: "DNS excluído com sucesso",
    de: "DNS erfolgreich gelöscht",
    es: "DNS eliminado con éxito",
    fr: "DNS supprimé avec succès",
    it: "DNS eliminato con successo",
    sq: "DNS u fshi me sukses",
    tr: "DNS başarıyla silindi.",
  },
  "Do you want to logout": {
    en: "Do you want to logout",
    pt: "Deseja sair",
    de: "Möchten Sie sich abmelden",
    es: "¿Quieres cerrar sesión",
    fr: "Voulez-vous vous déconnecter",
    it: "Vuoi uscire",
    sq: "Dëshironi të dilni",
    tr: "Çıkış yapmak istiyor musunuz",
  },

  "Do you want to reset?": {
    en: "Do you want to reset?",
    pt: "Deseja redefinir?",
    de: "Möchten Sie zurücksetzen?",
    es: "¿Quieres restablecer?",
    fr: "Voulez-vous réinitialiser?",
    it: "Vuoi ripristinare?",
    sq: "Dëshironi të rivendosni?",
    tr: "Sıfırlamak istiyor musunuz?",
  },
  "Device key must be 6 characters": {
    en: "Device key must be 6 characters",
    pt: "A chave do dispositivo deve ter 6 caracteres",
    de: "Geräteschlüssel muss 6 Zeichen lang sein",
    es: "La clave del dispositivo debe tener 6 caracteres",
    fr: "La clé de l'appareil doit contenir 6 caractères",
    it: "La chiave del dispositivo deve essere composta da 6 caratteri",
    sq: "Çelësi i pajisjes duhet të jetë 6 karaktere",
    tr: "Cihaz anahtarı 6 karakter olmalıdır.",
  },
  "Do you want to deactivate this device": {
    en: "Do you want to deactivate this device?",
    pt: "Deseja desativar este dispositivo?",
    de: "Möchten Sie dieses Gerät deaktivieren?",
    es: "¿Desea desactivar este dispositivo?",
    fr: "Voulez-vous désactiver cet appareil ?",
    it: "Vuoi disattivare questo dispositivo?",
    sq: "A dëshironi të çaktivizoni këtë pajisje?",
    tr: "Bu cihazı devre dışı bırakmak istiyor musunuz?",
  },

  "Do you want to block this device": {
    en: "Do you want to block this device?",
    pt: "Você quer bloquear este dispositivo?",
    de: "Möchten Sie dieses Gerät blockieren?",
    es: "¿Quieres bloquear este dispositivo?",
    fr: "Voulez-vous bloquer ce dispositif?",
    it: "Vuoi bloccare questo dispositivo?",
    sq: "A dëshironi të bllokoni këtë pajisje?",
    tr: "Bu cihazı engellemek istiyor musunuz?",
  },
  "Do you want to unblock this device": {
    en: "Do you want to unblock this device?",
    pt: "Deseja desbloquear este dispositivo?",
    de: "Möchten Sie dieses Gerät freischalten?",
    es: "¿Desea desbloquear este dispositivo?",
    fr: "Voulez-vous débloquer ce périphérique?",
    it: "Vuoi sbloccare questo dispositivo?",
    sq: "A dëshironi të çaktivizoni bllokimin e kësaj pajisje?",
    tr: "Bu cihazın engelini kaldırmak istiyor musunuz?",
  },
  "Device configuration updates are successful": {
    en: "Device configuration updates are successful.",
    pt: "As atualizações de configuração do dispositivo foram bem-sucedidas.",
    de: "Gerätekonfigurationsaktualisierungen waren erfolgreich.",
    es: "Las actualizaciones de configuración del dispositivo se realizaron con éxito.",
    fr: "Les mises à jour de la configuration de l'appareil ont réussi.",
    it: "Gli aggiornamenti della configurazione del dispositivo sono avvenuti con successo.",
    sq: "Përditësimet e konfigurimit të pajisjes janë të suksesshme.",
    tr: "Cihaz yapılandırma güncellemeleri başarılıdır.",
  },
  "Double check your server credentials before pressing start": {
    en: "Double check your server credentials before pressing start.",
    pt: "Verifique suas credenciais do servidor antes de pressionar Iniciar.",
    de: "Überprüfen Sie Ihre Server-Anmeldeinformationen, bevor Sie starten.",
    es: "Verifique sus credenciales del servidor antes de presionar Iniciar.",
    fr: "Vérifiez vos identifiants de serveur avant d'appuyer sur Démarrer.",
    it: "Verifica le tue credenziali del server prima di premere Avvia.",
    sq: "Verifikoni kredencialet tuaja të serverit para se të shtypni fillimi.",
    tr: "Başlatmadan önce sunucu kimlik bilgilerinizi kontrol edin.",
  },
  "device activation info": {
    en: "If you would like to activate this device in the future, you need to spend a credit on it again. Do you want to deactivate this device?",
    pt: "Se você deseja ativar este dispositivo no futuro, precisará gastar créditos novamente. Deseja desativar este dispositivo?",
    de: "Wenn Sie dieses Gerät in Zukunft aktivieren möchten, müssen Sie erneut Guthaben dafür ausgeben. Möchten Sie dieses Gerät deaktivieren?",
    es: "Si desea activar este dispositivo en el futuro, deberá gastar créditos nuevamente. ¿Desea desactivar este dispositivo?",
    fr: "Si vous souhaitez activer ce dispositif à l'avenir, vous devrez dépenser à nouveau des crédits. Souhaitez-vous désactiver ce dispositif ?",
    it: "Se desideri attivare nuovamente questo dispositivo in futuro, dovrai spendere nuovamente un credito. Vuoi disattivare questo dispositivo?",
    sq: "Nëse dëshironi të aktivizoni këtë pajisje në të ardhmen, duhet të shpenzoni përsëri një kredit për të. Doni të çaktivizoni këtë pajisje?",
    tr: "Bu cihazı gelecekte etkinleştirmek istiyorsanız, tekrar kredi harcamanız gerekiyor. Bu cihazı devre dışı bırakmak istiyor musunuz?",
  },
  "Do you want to reset this device": {
    en: "Do you want to reset this device?",
    pt: "Deseja reiniciar este dispositivo?",
    de: "Möchten Sie dieses Gerät zurücksetzen?",
    es: "¿Desea restablecer este dispositivo?",
    fr: "Voulez-vous réinitialiser cet appareil?",
    it: "Vuoi ripristinare questo dispositivo?",
    sq: "Doni të ristartoni këtë pajisje?",
    tr: "Bu cihazı sıfırlamak istiyor musunuz?",
  },
  "Do you want to cancel reset this device": {
    en: "Do you want to cancel reset this device?",
    pt: "Você deseja cancelar a reinicialização deste dispositivo?",
    de: "Möchten Sie den Reset dieses Geräts abbrechen?",
    es: "¿Desea cancelar el restablecimiento de este dispositivo?",
    fr: "Voulez-vous annuler la réinitialisation de cet appareil?",
    it: "Vuoi annullare il ripristino di questo dispositivo?",
    sq: "Doni të anuloni ristartin e kësaj pajisje?",
    tr: "Bu cihazın sıfırlanmasını iptal etmek istiyor musunuz?",
  },

  Date: {
    en: "Date",
    pt: "Data",
    de: "Datum",
    es: "Fecha",
    fr: "Date",
    it: "Data",
    sq: "Data",
    tr: "Tarih",
  },
  Delete: {
    en: "Delete",
    pt: "Excluir",
    de: "Löschen",
    es: "Eliminar",
    fr: "Supprimer",
    it: "Elimina",
    sq: "Fshij",
    tr: "Sil",
  },
  Day: {
    en: "Day",
    es: "Día",
    fr: "Jour",
    de: "Tag",
    pt: "Dia",
    it: "Giorno",
    sq: "Ditë",
    tr: "Gün",
  },

  //m
  Month: {
    en: "Month",
    es: "Mes",
    fr: "Mois",
    de: "Monat",
    pt: "Mês",
    it: "Mese",
    sq: "Mënd",
    tr: "Ay",
  },
  Message: {
    en: "Message",
    pt: "Mensagem",
    de: "Nachricht",
    es: "Mensaje",
    fr: "Message",
    it: "Messaggio",
    sq: "Mesazh",
    tr: "Mesaj",
  },
  "Movie ID": {
    en: "Movie ID",
    pt: "ID do Filme",
    de: "Film-ID",
    es: "ID de Película",
    fr: "ID du Film",
    it: "ID Film",
    sq: "ID e Filmit",
    tr: "Film ID",
  },
  Menu: {
    en: "Menu",
    pt: "Menu",
    de: "Menü",
    es: "Menú",
    fr: "Menu",
    it: "Menu",
    sq: "Meny",
    tr: "Menü",
  },

  "Menu Page": {
    en: "Menu Page",
    es: "Página de menú",
    fr: "Page de menu",
    de: "Menüseite",
    pt: "Página de menu",
    it: "Pagina del menu",
    sq: "Faqja e menysë",
    tr: "Sayfa Menüsü",
  },
  month: {
    en: "month",
    pt: "mês",
    de: "Monat",
    es: "mes",
    fr: "mois",
    it: "mese",
    sq: "muaj",
    tr: "ay",
  },
  Movies: {
    en: "Movies",
    pt: "Filmes",
    de: "Filme",
    es: "Películas",
    fr: "Films",
    it: "Film",
    sq: "Filma",
    tr: "Filmler",
  },
  Migration: {
    en: "Migration",
    pt: "Migração",
    de: "Migration",
    es: "Migración",
    fr: "Migration",
    it: "Migrazione",
    sq: "Migracion",
    tr: "Göç",
  },
  Migrate: {
    en: "Migrate",
    pt: "Migrar",
    de: "Migrieren",
    es: "Migrar",
    fr: "Migrer",
    it: "Migrare",
    sq: "Migrimi",
    tr: "Göç",
  },
  Model: {
    en: "Model",
    pt: "Modelo",
    de: "Modell",
    es: "Modelo",
    fr: "Modèle",
    it: "Modello",
    sq: "Modeli",
    tr: "Model",
  },
  "Movies Categories": {
    en: "Movies Categories",
    pt: "Categorias de filmes",
    de: "Filmkategorien",
    es: "Categorías de películas",
    fr: "Catégories de films",
    it: "Categorie di film",
    sq: "Kategoritë e filmit",
    tr: "Film Kategorileri",
  },

  "Monday/September/2023": {
    en: "Monday/September/2023",
    pt: "Segunda-feira/Setembro/2023",
    de: "Montag/September/2023",
    es: "Lunes/Septiembre/2023",
    fr: "Lundi/Septembre/2023",
    it: "Lunedì/Settembre/2023",
    sq: "E Hënë/Shtator/2023",
    tr: "Pazartesi/ Eylül/2023",
  },
  "Migration system": {
    en: "Migration system",
    pt: "Sistema de migração",
    de: "Migrationsystem",
    es: "Sistema de migración",
    fr: "Système de migration",
    it: "Sistema di migrazione",
    sq: "Sistemi i migracionit",
    tr: "Göç sistemi",
  },
  "Migrate your users from one server to another": {
    en: "Migrate your users from one server to another",
    pt: "Migre seus usuários de um servidor para outro",
    de: "Migrieren Sie Ihre Benutzer von einem Server auf einen anderen",
    es: "Migra a tus usuarios de un servidor a otro",
    fr: "Migrez vos utilisateurs d'un serveur à un autre",
    it: "Migra i tuoi utenti da un server all'altro",
    sq: "Migrojini përdoruesit tuaj nga një server në një tjetër",
    tr: "Kullanıcılarınızı bir sunucudan diğerine taşımak için",
  },
  "Migrate your users from": {
    en: "Migrate your users from",
    pt: "Migre seus usuários de",
    de: "Migrieren Sie Ihre Benutzer von",
    es: "Migra tus usuarios desde",
    fr: "Migrez vos utilisateurs depuis",
    it: "Migra i tuoi utenti da",
    sq: "Migrojini përdoruesit tuaj nga",
    tr: "Kullanıcılarınızı buradan taşıyın",
  },
  "Migration is not possible": {
    en: "Migration is not possible. The new migration server matches the server you want to migrate from.",
    pt: "A migração não é possível. O novo servidor de migração corresponde ao servidor de origem que deseja migrar.",
    de: "Die Migration ist nicht möglich. Der neue Migrationsserver entspricht dem Server, von dem Sie migrieren möchten.",
    es: "La migración no es posible. El nuevo servidor de migración coincide con el servidor del que desea migrar.",
    fr: "La migration n'est pas possible. Le nouveau serveur de migration correspond au serveur que vous souhaitez migrer.",
    it: "La migrazione non è possibile. Il nuovo server di migrazione corrisponde al server da cui desideri migrare.",
    sq: "Migrimi nuk është i mundur. Serveri i ri i migrimit përputhet me serverin që dëshironi të migroni.",
    tr: "Göç mümkün değildir. Yeni göç sunucusu, taşımak istediğiniz sunucu ile eşleşiyor.",
  },
  "Migration system allows you to move all": {
    en: "Migration system allows you to move all your users from one server to another server within a few minutes in case of a problem.",
    pt: "O sistema de migração permite mover todos os seus usuários de um servidor para outro em poucos minutos, em caso de problema.",
    de: "Das Migrationsystem ermöglicht es Ihnen, alle Ihre Benutzer innerhalb weniger Minuten von einem Server auf einen anderen zu verschieben, falls ein Problem auftritt.",
    es: "El sistema de migración le permite mover a todos sus usuarios de un servidor a otro en cuestión de minutos en caso de un problema.",
    fr: "Le système de migration vous permet de déplacer tous vos utilisateurs d'un serveur à un autre en quelques minutes en cas de problème.",
    it: "Il sistema di migrazione consente di spostare tutti i tuoi utenti da un server all'altro in pochi minuti in caso di problemi.",
    sq: "Sistemi i migrimit ju lejon të lëvizni të gjithë përdoruesit tuaj nga një server në një tjetër brenda disa minutave në rast se ka një problem.",
    tr: "Göç sistemi, bir sorun olması durumunda, tüm kullanıcılarınızı birkaç dakika içinde bir sunucudan diğerine taşımanıza olanak tanır.",
  },

  //l
  Lock: {
    en: "Lock",
    pt: "Trancar",
    de: "Sperren",
    es: "Bloquear",
    fr: "Verrouiller",
    it: "Blocca",
    sq: "Blloko",
    tr: "Kilitle",
  },
  Login: {
    en: "Login",
    pt: "Entrar",
    de: "Anmeldung",
    es: "Iniciar sesión",
    fr: "Connexion",
    it: "Accesso",
    sq: "Identifikohu",
    tr: "Giriş",
  },
  Logout: {
    en: "Logout",
    pt: "Sair",
    de: "Abmelden",
    es: "Cerrar sesión",
    fr: "Déconnexion",
    it: "Esci",
    sq: "Çkyçu",
    tr: "Çıkış",
  },
  "Logo size": {
    en: "Logo size",
    pt: "Tamanho do logotipo",
    de: "Logo-Größe",
    es: "Tamaño del logotipo",
    fr: "Taille du logo",
    it: "Dimensione del logo",
    sq: "Madhësia e logotipit",
    tr: "Logo boyutu",
  },
  "Live TV": {
    en: "Live TV",
    pt: "TV ao Vivo",
    de: "Live-TV",
    es: "TV en vivo",
    fr: "Télévision en direct",
    it: "TV in diretta",
    sq: "TV Live",
    tr: "Canlı TV",
  },
  lifetime: {
    en: "Lifetime",
    pt: "Vitalício",
    de: "Lebensdauer",
    es: "Vitalicio",
    fr: "Durée de vie",
    it: "Vita",
    sq: "Jeta",
    tr: "Ömür boyu",
  },
  "Last online": {
    en: "Last online",
    pt: "Último online",
    de: "Zuletzt online",
    es: "Último en línea",
    fr: "Dernière connexion",
    it: "Ultimo accesso",
    sq: "Online i fundit",
    tr: "Son çevrimiçi",
  },
  "Live TV Categories": {
    en: "Live TV Categories",
    pt: "Categorias de TV ao Vivo",
    de: "Live-TV-Kategorien",
    es: "Categorías de TV en vivo",
    fr: "Catégories de télévision en direct",
    it: "Categorie TV in diretta",
    sq: "Kategoritë e TV Live",
    tr: "Canlı TV Kategorileri",
  },
  //n
  "Not available platform": {
    en: "Not available platform",
    pt: "Plataforma não disponível",
    de: "Plattform nicht verfügbar",
    es: "Plataforma no disponible",
    fr: "Plateforme non disponible",
    it: "Piattaforma non disponibile",
    sq: "Platformë e padisponueshme",
    tr: "Mevcut olmayan platform",
  },
  "No devices found on server": {
    en: "No devices found on server",
    pt: "Nenhum dispositivo encontrado no servidor",
    de: "Keine Geräte auf dem Server gefunden",
    es: "No se encontraron dispositivos en el servidor",
    fr: "Aucun appareil trouvé sur le serveur",
    it: "Nessun dispositivo trovato sul server",
    sq: "Nuk u gjetën pajisje në server",
    tr: "Sunucuda hiç cihaz bulunamadı",
  },

  No: {
    en: "No",
    pt: "Não",
    de: "Nein",
    es: "No",
    fr: "Non",
    it: "No",
    sq: "Jo",
    tr: "Hayır",
  },
  "N/A": {
    en: "N/A",
    pt: "N/D",
    de: "N/A",
    es: "N/D",
    fr: "N/D",
    it: "N/D",
    sq: "N/A",
    tr: "Yok",
  },
  Name: {
    en: "Name",
    pt: "Nome",
    de: "Name",
    es: "Nombre",
    fr: "Nom",
    it: "Nome",
    sq: "Emër",
    tr: "İsim",
  },
  Next: {
    en: "Next",
    pt: "Próximo",
    de: "Nächster",
    es: "Siguiente",
    fr: "Suivant",
    it: "Successivo",
    sq: "Tjetra",
    tr: "Sonraki",
  },
  "New password": {
    en: "New password",
    pt: "Nova senha",
    de: "Neues Passwort",
    es: "Nueva contraseña",
    fr: "Nouveau mot de passe",
    it: "Nuova password",
    sq: "Fjalëkalim i ri",
    tr: "Yeni şifre",
  },
  "Name on card": {
    en: "Name on card",
    pt: "Nome no cartão",
    de: "Name auf der Karte",
    es: "Nombre en la tarjeta",
    fr: "Nom sur la carte",
    it: "Nome sulla carta",
    sq: "Emri në kartë",
    tr: "Karttaki isim",
  },
  Notes: {
    en: "Notes",
    pt: "Notas",
    de: "Notizen",
    es: "Notas",
    fr: "Notes",
    it: "Note",
    sq: "Shënime",
    tr: "Notlar",
  },
  Notifications: {
    en: "Notifications",
    pt: "Notificações",
    de: "Benachrichtigungen",
    es: "Notificaciones",
    fr: "Notifications",
    it: "Notifiche",
    sq: "Njoftimet",
    tr: "Bildirimler",
  },
  "New device configurations": {
    en: "New device configurations",
    pt: "Novas configurações de dispositivo",
    de: "Neue Gerätekonfigurationen",
    es: "Nuevas configuraciones de dispositivo",
    fr: "Nouvelles configurations de l'appareil",
    it: "Nuove configurazioni del dispositivo",
    sq: "Konfigurimet e reja të pajisjes",
    tr: "Yeni cihaz yapılandırmaları",
  },
  "New device configuration guide": {
    en: "New device configuration guide",
    pt: "Novo guia de configuração de dispositivo",
    de: "Neue Gerätekonfigurationsanleitung",
    es: "Nueva guía de configuración de dispositivo",
    fr: "Nouveau guide de configuration de l'appareil",
    it: "Nuova guida alla configurazione del dispositivo",
    sq: "Udhëzuesi i konfigurimit të pajisjes së re",
    tr: "Yeni cihaz yapılandırma kılavuzu",
  },
  //g
  "Generic Config": {
    en: "Generic Config",
    pt: "Configuração Genérica",
    de: "Generische Konfiguration",
    es: "Configuración Genérica",
    fr: "Configuration Générique",
    it: "Configurazione Generica",
    sq: "Konfigurim Gjenrik",
    tr: "Genel Yapılandırma",
  },
  "Get code": {
    en: "Get code",
    pt: "Obter código",
    de: "Code erhalten",
    es: "Obtener código",
    fr: "Obtenir le code",
    it: "Ottieni codice",
    sq: "Merr kodin",
    tr: "Kodu Al",
  },
  "Get credits": {
    en: "Get credits",
    pt: "Obter créditos",
    de: "Credits erhalten",
    es: "Obtener créditos",
    fr: "Obtenir des crédits",
    it: "Ottieni crediti",
    sq: "Merr kredite",
    tr: "Kredi Al",
  },
  Genre: {
    en: "Genre",
    pt: "Gênero",
    de: "Genre",
    es: "Género",
    fr: "Genre",
    it: "Genere",
    sq: "Janër",
    tr: "Tür",
  },
  "Generate playlist": {
    en: "Generate playlist",
    pt: "Gerar lista de reprodução",
    de: "Playlist generieren",
    es: "Generar lista de reproducción",
    fr: "Générer une playlist",
    it: "Genera playlist",
    sq: "Krijo listën e për të dëgjuar",
    tr: "Çalma listesi oluştur",
  },

  //s
  "Server ID": {
    en: "Server ID",
    pt: "ID do Servidor",
    de: "Server-ID",
    es: "ID del Servidor",
    fr: "ID du Serveur",
    it: "ID del Server",
    sq: "ID e Serverit",
    tr: "Sunucu Kimliği",
  },
  "Start date": {
    en: "Start date",
    es: "Fecha de inicio",
    fr: "Date de début",
    de: "Startdatum",
    pt: "Data de início",
    it: "Data di inizio",
    sq: "Data e fillimit",
    tr: "Başlangıç tarihi",
  },
  success: {
    en: "Success",
    pt: "Sucesso",
    de: "Erfolg",
    es: "Éxito",
    fr: "Succès",
    it: "Successo",
    sq: "Sukses",
    tr: "Başarı",
  },
  "Search by device key": {
    en: "Search by device key",
    pt: "Pesquisar por chave do dispositivo",
    de: "Suche nach Geräteschlüssel",
    es: "Buscar por clave de dispositivo",
    fr: "Recherche par clé de dispositif",
    it: "Ricerca per chiave del dispositivo",
    sq: "Kërkoni sipas çelësit të pajisjes",
    tr: "Cihaz anahtarı ile ara",
  },
  State: {
    en: "State",
    pt: "Estado",
    de: "Zustand",
    es: "Estado",
    fr: "État",
    it: "Stato",
    sq: "Gjendja",
    tr: "Durum",
  },
  "Stream ID": {
    en: "Stream ID",
    pt: "ID do Fluxo",
    de: "Stream-ID",
    es: "ID de Transmisión",
    fr: "Identifiant de Flux",
    it: "ID del Flusso",
    sq: "ID e Strujës",
    tr: "Akış Kimliği",
  },
  "Series ID": {
    en: "Series ID",
    pt: "ID da Série",
    de: "Seriennummer",
    es: "ID de la Serie",
    fr: "Identifiant de Série",
    it: "ID Serie",
    sq: "ID e Serisë",
    tr: "Dizi Kimliği",
  },
  Season: {
    en: "Season",
    pt: "Temporada",
    de: "Saison",
    es: "Temporada",
    fr: "Saison",
    it: "Stagione",
    sq: "Stinë",
    tr: "Mevsim",
  },
  "server to another": {
    en: "server to another",
    pt: "servidor para outro",
    de: "Server zu einem anderen",
    es: "servidor a otro",
    fr: "serveur vers un autre",
    it: "server verso un altro",
    sq: "server për në tjetër",
    tr: "sunucu bir diğerine",
  },
  Send: {
    en: "Send",
    pt: "Enviar",
    de: "Senden",
    es: "Enviar",
    fr: "Envoyer",
    it: "Invia",
    sq: "Dërgo",
    tr: "Gönder",
  },
  Seen: {
    en: "Seen",
    pt: "Visto",
    de: "Gesehen",
    es: "Visto",
    fr: "Vu",
    it: "Visto",
    sq: "Shikuar",
    tr: "Görüldü",
  },
  Sync: {
    en: "Sync",
    pt: "Sincronizar",
    de: "Synchronisieren",
    es: "Sincronizar",
    fr: "Synchroniser",
    it: "Sincronizza",
    sq: "Sinkronizo",
    tr: "Senkranize Et",
  },
  "Sync playlist": {
    en: "Sync playlist",
    pt: "Sincronizar lista de reprodução",
    de: "Wiedergabeliste synchronisieren",
    es: "Sincronizar lista de reproducción",
    fr: "Synchroniser la playlist",
    it: "Sincronizza playlist",
    sq: "Sinkronizo listën e përhapjes",
    tr: "Senkranize Et liste",
  },
  Save: {
    en: "Save",
    pt: "Salvar",
    de: "Speichern",
    es: "Guardar",
    fr: "Enregistrer",
    it: "Salva",
    sq: "Ruaj",
    tr: "Kaydet",
  },
  Start: {
    en: "Start",
    pt: "Iniciar",
    de: "Starten",
    es: "Iniciar",
    fr: "Démarrer",
    it: "Avvia",
    sq: "Fillo",
    tr: "Başla",
  },
  Search: {
    en: "Search",
    pt: "Pesquisar",
    de: "Suchen",
    es: "Buscar",
    fr: "Rechercher",
    it: "Cerca",
    sq: "Kërko",
    tr: "Ara",
  },
  Status: {
    en: "Status",
    pt: "Status",
    de: "Status",
    es: "Estado",
    fr: "Statut",
    it: "Stato",
    sq: "Statusi",
    tr: "Durum",
  },
  Schedule: {
    en: "Schedule",
    es: "Horario",
    fr: "Horaire",
    de: "Zeitplan",
    pt: "Agenda",
    it: "Programma",
    sq: "Orari",
    tr: "Program",
  },
  Select: {
    en: "Select",
    pt: "Selecionar",
    de: "Auswählen",
    es: "Seleccionar",
    fr: "Sélectionner",
    it: "Seleziona",
    sq: "Zgjidh",
    tr: "Seç",
  },
  Series: {
    en: "Series",
    pt: "Séries",
    de: "Serien",
    es: "Series",
    fr: "Séries",
    it: "Serie",
    sq: "Seria",
    tr: "Seriler",
  },
  Surname: {
    en: "Surname",
    pt: "Sobrenome",
    de: "Nachname",
    es: "Apellido",
    fr: "Nom de famille",
    it: "Cognome",
    sq: "Mbiemri",
    tr: "Soyadı",
  },
  Settings: {
    en: "Settings",
    pt: "Configurações",
    de: "Einstellungen",
    es: "Ajustes",
    fr: "Paramètres",
    it: "Impostazioni",
    sq: "Parametrat",
    tr: "Ayarlar",
  },
  Seller: {
    en: "Seller",
    pt: "Vendedor",
    de: "Verkäufer",
    es: "Vendedor",
    fr: "Vendeur",
    it: "Venditore",
    sq: "Shitësi",
    tr: "Satıcı",
  },
  Success: {
    en: "Success",
    pt: "Sucesso",
    de: "Erfolg",
    es: "Éxito",
    fr: "Succès",
    it: "Successo",
    sq: "Sukses",
    tr: "Başarı",
  },
  Signup: {
    en: "Signup",
    pt: "Cadastro",
    de: "Anmeldung",
    es: "Registro",
    fr: "Inscription",
    it: "Registrazione",
    sq: "Regjistrohu",
    tr: "Kayıt Ol",
  },
  "Send date": {
    en: "Send date",
    pt: "Data de envio",
    de: "Sendedatum",
    es: "Fecha de envío",
    fr: "Date d'envoi",
    it: "Data di invio",
    sq: "Data e dërgimit",
    tr: "Gönderim tarihi",
  },
  Statistics: {
    en: "Statistics",
    pt: "Estatísticas",
    de: "Statistiken",
    es: "Estadísticas",
    fr: "Statistiques",
    it: "Statistiche",
    sq: "Statistikat",
    tr: "İstatistikler",
  },
  "Select DNS": {
    en: "Select DNS",
    pt: "Selecionar DNS",
    de: "DNS auswählen",
    es: "Seleccionar DNS",
    fr: "Sélectionner DNS",
    it: "Seleziona DNS",
    sq: "Zgjidh DNS",
    tr: "DNS Seç",
  },
  Support: {
    en: "Support",
    pt: "Suporte",
    de: "Unterstützung",
    es: "Soporte",
    fr: "Support",
    it: "Supporto",
    sq: "Mbështetje",
    tr: "Destek",
  },
  Subresellers: {
    en: "Subresellers",
    pt: "Subrevendedores",
    de: "Unterhändler",
    es: "Subrevendedores",
    fr: "Sous-revendeurs",
    it: "Subrivenditori",
    sq: "Nën-rihumbësuesit",
    tr: "Altbayiler",
  },
  "Select platform": {
    en: "Select platform",
    pt: "Selecionar plataforma",
    de: "Plattform auswählen",
    es: "Seleccionar plataforma",
    fr: "Sélectionner la plateforme",
    it: "Seleziona piattaforma",
    sq: "Zgjidh platformën",
    tr: "Platform Seç",
  },
  "Select device by": {
    en: "Select device by:",
    pt: "Selecionar dispositivo por:",
    de: "Gerät auswählen nach:",
    es: "Seleccionar dispositivo por:",
    fr: "Sélectionner l'appareil par :",
    it: "Seleziona dispositivo per:",
    sq: "Zgjidh pajisjen me:",
    tr: "Cihazı seç:",
  },
  "Secondary color": {
    en: "Secondary color",
    pt: "Cor secundária",
    de: "Sekundärfarbe",
    es: "Color secundario",
    fr: "Couleur secondaire",
    it: "Colore secondario",
    sq: "Ngjyra e dytësore",
    tr: "İkincil renk",
  },
  "Sub-Reseller Info": {
    en: "Sub-Reseller Info",
    pt: "Informações de Sub-Revendedor",
    de: "Sub-Händler Informationen",
    es: "Información de Sub-Revendedor",
    fr: "Informations du sous-revendeur",
    it: "Informazioni del Sub-Rivenditore",
    sq: "Informacione për Nën-rihumbësuesin",
    tr: "Alt-Bayi Bilgisi",
  },
  "Send notifications": {
    en: "Send notifications",
    pt: "Enviar notificações",
    de: "Benachrichtigungen senden",
    es: "Enviar notificaciones",
    fr: "Envoyer des notifications",
    it: "Invia notifiche",
    sq: "Dërgo njoftime",
    tr: "Bildirim gönder",
  },
  "Select credit count": {
    en: "Select credit count",
    pt: "Selecionar contagem de crédito",
    de: "Kreditanzahl auswählen",
    es: "Seleccionar cantidad de crédito",
    fr: "Sélectionner le nombre de crédits",
    it: "Seleziona il conteggio dei crediti",
    sq: "Zgjidh numrin e kredive",
    tr: "Kredi sayısını seç",
  },
  "Something went wrong": {
    en: "Something went wrong.",
    pt: "Algo deu errado.",
    de: "Etwas ist schiefgelaufen.",
    es: "Algo salió mal.",
    fr: "Quelque chose s'est mal passé.",
    it: "Qualcosa è andato storto.",
    sq: "Diçka shkoi gabim.",
    tr: "Bir şeyler yanlış gitti.",
  },
  "Start migration process": {
    en: "Start migration process",
    pt: "Iniciar processo de migração",
    de: "Starte den Migrationsprozess",
    es: "Iniciar proceso de migración",
    fr: "Démarrer le processus de migration",
    it: "Avvia il processo di migrazione",
    sq: "Fillo procesin e migrimit",
    tr: "Geçiş sürecini başlat",
  },
  "Select activation type": {
    en: "Select activation type",
    pt: "Selecionar tipo de ativação",
    de: "Aktivierungstyp auswählen",
    es: "Seleccionar tipo de activación",
    fr: "Sélectionner le type d'activation",
    it: "Seleziona il tipo di attivazione",
    sq: "Zgjidh llojin e aktivizimit",
    tr: "Aktivasyon türünü seç",
  },
  "Select the type of your server": {
    en: "Select the type of your server:",
    pt: "Selecione o tipo do seu servidor:",
    de: "Wählen Sie den Servertyp aus:",
    es: "Seleccione el tipo de su servidor:",
    fr: "Sélectionnez le type de votre serveur :",
    it: "Seleziona il tipo del tuo server:",
    sq: "Zgjidhni llojin e serverit tuaj:",
    tr: "Sunucunuzun türünü seçin:",
  },
  "Sorry, the page you visited does not exist": {
    en: "Sorry, the page you visited does not exist.",
    pt: "Desculpe, a página que você visitou não existe.",
    de: "Entschuldigung, die von Ihnen besuchte Seite existiert nicht.",
    es: "Lo siento, la página que visitó no existe.",
    fr: "Désolé, la page que vous avez visitée n'existe pas.",
    it: "Spiacente, la pagina che hai visitato non esiste.",
    sq: "Ndjesi, faqja që vizituat nuk ekziston.",
    tr: "Üzgünüm, ziyaret ettiğiniz sayfa mevcut değil.",
  },
  "Select and attach one from your server": {
    en: "Select and attach one from your DNS server hosts then write the username and password of the chosen host. Or select one of your Xtream Servers and generate a playlist which will be attached to your device. You can see the generated URL in Playlist URL input.",
    pt: "Selecione e anexe um dos hosts do seu servidor DNS e, em seguida, escreva o nome de usuário e a senha do host escolhido. Ou selecione um dos seus servidores Xtream e gere uma lista de reprodução que será anexada ao seu dispositivo. Você pode ver a URL gerada na entrada de URL da lista de reprodução.",
    de: "Wählen Sie einen Ihrer DNS-Serverhosts aus und schreiben Sie dann den Benutzernamen und das Passwort des ausgewählten Hosts. Oder wählen Sie einen Ihrer Xtream Server aus und generieren Sie eine Playlist, die an Ihr Gerät angehängt wird. Sie können die generierte URL im Eingabefeld für die Playlist-URL sehen.",
    es: "Seleccione y adjunte uno de los hosts de su servidor DNS, luego escriba el nombre de usuario y la contraseña del host elegido. O seleccione uno de sus servidores Xtream y genere una lista de reproducción que se adjuntará a su dispositivo. Puede ver la URL generada en el campo de entrada de la URL de la lista de reproducción.",
    fr: "Sélectionnez et attachez l'un de vos hôtes de serveur DNS, puis saisissez le nom d'utilisateur et le mot de passe de l'hôte choisi. Ou sélectionnez l'un de vos serveurs Xtream et générez une liste de lecture qui sera attachée à votre appareil. Vous pouvez voir l'URL générée dans le champ d'entrée de l'URL de la liste de lecture.",
    it: "Seleziona e allega uno dei server DNS del tuo server, quindi scrivi il nome utente e la password dell'host scelto. Oppure seleziona uno dei tuoi server Xtream e genera una playlist che verrà allegata al tuo dispositivo. Puoi vedere l'URL generato nell'input dell'URL della playlist.",
    sq: "Zgjidhni dhe bashkangjitni një nga hostet e serverit tuaj DNS pastaj shkruani emrin e përdoruesit dhe fjalëkalimin e hostit të zgjedhur. Ose zgjidhni një nga Serverët tuaj Xtream dhe gjeneroni një listë riprodhimi që do të bashkangjitet me pajisjen tuaj. Mund të shihni URL-në e gjeneruar në hyrjen e URL-së së listës së riprodhimit.",
    tr: "DNS sunucunuzun hostlarından birini seçin ve ekleyin, ardından seçilen hostun kullanıcı adını ve şifresini yazın. Ya da Xtream sunucularınızdan birini seçin ve cihazınıza eklenecek bir çalma listesi oluşturun. Oluşturulan URL'yi Çalma Listesi URL girişi kısmında görebilirsiniz.",
  },
  "server migration info": {
    en: "In first you need to choose the server from where you want to transfer your users, then choose the server to where you want to migrate your users.",
    pt: "Primeiro, você precisa escolher o servidor de onde deseja transferir seus usuários e, em seguida, escolher o servidor para onde deseja migrar seus usuários.",
    de: "Zuerst müssen Sie den Server auswählen, von dem aus Sie Ihre Benutzer übertragen möchten, und dann den Server auswählen, auf den Sie Ihre Benutzer migrieren möchten.",
    es: "En primer lugar, debe elegir el servidor desde el que desea transferir a sus usuarios y luego elegir el servidor al que desea migrar a sus usuarios.",
    fr: "Tout d'abord, vous devez choisir le serveur à partir duquel vous souhaitez transférer vos utilisateurs, puis choisir le serveur vers lequel vous souhaitez migrer vos utilisateurs.",
    it: "Innanzitutto, devi scegliere il server da cui desideri trasferire i tuoi utenti e poi scegliere il server in cui desideri migrare i tuoi utenti.",
    sq: "Fillimisht duhet të zgjidhni serverin nga ku dëshironi të transferoni përdoruesit tuaj, pastaj zgjidhni serverin në të cilin dëshironi të migratoni përdoruesit tuaj.",
    tr: "Öncelikle, kullanıcılarınızı transfer etmek istediğiniz sunucuyu seçmeniz ve ardından kullanıcılarınızı taşımak istediğiniz sunucuyu seçmeniz gerekir.",
  },
  "Something went wrong, try again": {
    en: "Something went wrong, try again",
    pt: "Algo deu errado, tente novamente",
    de: "Etwas ist schiefgegangen, versuche es erneut",
    es: "Algo salió mal, inténtalo de nuevo",
    fr: "Quelque chose s'est mal passé, réessayez",
    it: "Qualcosa è andato storto, riprova",
    sq: "Diçka shkoi keq, provoni përsëri",
    tr: "Bir şeyler ters gitti, lütfen tekrar deneyin.",
  },
  "Select file to upload": {
    en: "Select file to upload",
    es: "Seleccionar archivo para subir",
    fr: "Sélectionnez le fichier à télécharger",
    de: "Datei zum Hochladen auswählen",
    pt: "Selecione o arquivo para carregar",
    it: "Seleziona il file da caricare",
    sq: "Zgjidhni skedarin për ta ngarkuar",
    tr: "Yüklemek için dosyayı seçin",
  },

  //k
  kids: {
    en: "kids",
    pt: "crianças",
    de: "Kinder",
    es: "niños",
    fr: "enfants",
    it: "bambini",
    sq: "fëmijët",
    tr: "çocuklar",
  },
  //h
  "How to activate": {
    en: "How to activate",
    pt: "Como ativar",
    de: "Wie aktivieren",
    es: "Cómo activar",
    fr: "Comment activer",
    it: "Come attivare",
    sq: "Si të aktivizoj",
    tr: "Nasıl aktive edilir",
  },
  History: {
    en: "History",
    pt: "História",
    de: "Geschichte",
    es: "Historia",
    fr: "Histoire",
    it: "Storia",
    sq: "Historiku",
    tr: "Tarih",
  },
  hide: {
    en: "hide",
    pt: "ocultar",
    de: "verbergen",
    es: "ocultar",
    fr: "cacher",
    it: "nascondi",
    sq: "fsheh",
    tr: "gizle",
  },
  Host: {
    en: "Host",
    pt: "Host",
    de: "Host",
    es: "Anfitrión",
    fr: "Hôte",
    it: "Host",
    sq: "Pritësi",
    tr: "Sunucu",
  },
  "Hidden content": {
    en: "Hidden content",
    pt: "Conteúdo oculto",
    de: "Versteckter Inhalt",
    es: "Contenido oculto",
    fr: "Contenu caché",
    it: "Contenuto nascosto",
    sq: "Përmbajtje e fshehur",
    tr: "Gizli içerik",
  },
  "Available now": {
    en: "Available now",
    pt: "Disponível agora",
    de: "Jetzt verfügbar",
    es: "Disponible ahora",
    fr: "Disponible maintenant",
    it: "Disponibile ora",
    sq: "E disponueshme tani",
    tr: "Şu anda mevcut",
  },

  //p
  "Please note that only previously registered accounts within our system can be linked or attached":
    {
      en: "Please note that only previously registered accounts within our system can be linked or attached.",
      pt: "Por favor, note que apenas contas previamente registradas em nosso sistema podem ser vinculadas ou anexadas.",
      de: "Bitte beachten Sie, dass nur zuvor registrierte Konten in unserem System verknüpft oder angehängt werden können.",
      es: "Tenga en cuenta que solo las cuentas previamente registradas en nuestro sistema pueden ser vinculadas o adjuntadas.",
      fr: "Veuillez noter que seules les comptes préalablement enregistrées dans notre système peuvent être liées ou attachées.",
      it: "Si prega di notare che solo gli account precedentemente registrati nel nostro sistema possono essere collegati o associati.",
      sq: "Ju lutemi vini re se vetëm llogaritë e regjistruara më parë në sistemin tonë mund të lidhen ose bashkëngjiten.",
      tr: "Lütfen yalnızca sistemimizde daha önce kayıtlı olan hesapların bağlanabileceğini veya iliştirilebileceğini unutmayın.",
    },
  pixels: {
    en: "pixels",
    es: "píxeles",
    fr: "pixels",
    de: "Pixel",
    pt: "pixels",
    it: "pixel",
    sq: "piksela",
    tr: "piksel",
  },
  "Please enter date": {
    en: "Please enter date",
    es: "Por favor, ingrese la fecha",
    fr: "Veuillez entrer la date",
    de: "Bitte Datum eingeben",
    pt: "Por favor, insira a data",
    it: "Per favore, inserisci la data",
    sq: "Ju lutem, futni datën",
    tr: "Lütfen tarihi girin",
  },
  "Payment status": {
    en: "Payment status",
    es: "Estado del pago",
    fr: "Statut de paiement",
    de: "Zahlungsstatus",
    pt: "Status de pagamento",
    it: "Stato del pagamento",
    sq: "Statusi i pagesës",
    tr: "Ödeme durumu",
  },
  panel: {
    en: "Panel",
    pt: "Painel",
    de: "Panel",
    es: "Panel",
    fr: "Panneau",
    it: "Pannello",
    sq: "Paneli",
    tr: "Panel",
  },
  "Pay with": {
    en: "Pay with",
    pt: "Pagar com",
    de: "Bezahlen mit",
    es: "Pagar con",
    fr: "Payer avec",
    it: "Paga con",
    sq: "Paguaj me",
    tr: "İle Öde",
  },
  "Pay with Card": {
    en: "Pay with Card",
    pt: "Pagar com Cartão",
    de: "Mit Karte bezahlen",
    es: "Pagar con Tarjeta",
    fr: "Payer avec Carte",
    it: "Pagare con Carta",
    sq: "Pagesë me Kartë",
    tr: "Kartla Öde",
  },
  "PIX card": {
    en: "PIX card",
    pt: "Cartão PIX",
    de: "PIX-Karte",
    es: "Tarjeta PIX",
    fr: "Carte PIX",
    it: "Carta PIX",
    sq: "Kartë PIX",
    tr: "PIX kartı",
  },

  Paid: {
    en: "Paid",
    pt: "Pago",
    de: "Bezahlt",
    es: "Pagado",
    fr: "Payé",
    it: "Pagato",
    sq: "Paguar",
    tr: "Ödenmiş",
  },
  Price: {
    en: "Price",
    pt: "Preço",
    de: "Preis",
    es: "Precio",
    fr: "Prix",
    it: "Prezzo",
    sq: "Çmimi",
    tr: "Fiyat",
  },
  Provider: {
    en: "Provider",
    pt: "Fornecedor",
    de: "Anbieter",
    es: "Proveedor",
    fr: "Fournisseur",
    it: "Provider",
    sq: "Furnizues",
    tr: "Sağlayıcı",
  },
  "Provider not found": {
    en: "Provider not found",
    pt: "Fornecedor não encontrado",
    de: "Anbieter nicht gefunden",
    es: "Proveedor no encontrado",
    fr: "Fournisseur non trouvé",
    it: "Provider non trovato",
    sq: "Furnizuesi nuk u gjet",
    tr: "Sağlayıcı bulunamadı",
  },
  Partner: {
    en: "Partner",
    pt: "Parceiro",
    de: "Partner",
    es: "Socio",
    fr: "Partenaire",
    it: "Partner",
    sq: "Partner",
    tr: "Ortak",
  },
  Platform: {
    en: "Platform",
    pt: "Plataforma",
    de: "Plattform",
    es: "Plataforma",
    fr: "Plateforme",
    it: "Piattaforma",
    sq: "Platformë",
    tr: "Platform",
  },
  Platforms: {
    en: "Platforms",
    pt: "Plataformas",
    de: "Plattformen",
    es: "Plataformas",
    fr: "Plateformes",
    it: "Piattaforme",
    sq: "Platforma",
    tr: "Platformlar",
  },
  Playlist: {
    en: "Playlist",
    pt: "Lista de reprodução",
    de: "Wiedergabeliste",
    es: "Lista de reproducción",
    fr: "Liste de lecture",
    it: "Playlist",
    sq: "Lista e këngëve",
    tr: "Çalma Listesi",
  },
  Profile: {
    en: "Profile",
    pt: "Perfil",
    de: "Profil",
    es: "Perfil",
    fr: "Profil",
    it: "Profilo",
    sq: "Profili",
    tr: "Profil",
  },
  "Previous step": {
    en: "Previous step",
    pt: "Passo anterior",
    de: "Vorheriger Schritt",
    es: "Paso anterior",
    fr: "Étape précédente",
    it: "Passaggio precedente",
    sq: "Hapi i mëparshëm",
    tr: "Önceki adım",
  },
  Password: {
    en: "Password",
    pt: "Senha",
    de: "Passwort",
    es: "Contraseña",
    fr: "Mot de passe",
    it: "Password",
    sq: "Fjalëkalim",
    tr: "Şifre",
  },
  Phone: {
    en: "Phone",
    pt: "Telefone",
    de: "Telefon",
    es: "Teléfono",
    fr: "Téléphone",
    it: "Telefono",
    sq: "Telefoni",
    tr: "Telefon",
  },
  "Playlist URL": {
    en: "Playlist URL",
    pt: "URL da lista de reprodução",
    de: "Playlist-URL",
    es: "URL de la lista de reproducción",
    fr: "URL de la liste de lecture",
    it: "URL della playlist",
    sq: "URL e listës së përgjimeve",
    tr: "Oynatma Listesi URL'si",
  },
  "Partner key": {
    en: "Partner key",
    pt: "Chave do parceiro",
    de: "Partner-Schlüssel",
    es: "Clave del socio",
    fr: "Clé du partenaire",
    it: "Chiave del partner",
    sq: "Çelësi i partnerit",
    tr: "Ortak anahtarı",
  },
  "Primary color": {
    en: "Primary color",
    pt: "Cor primária",
    de: "Primärfarbe",
    es: "Color primario",
    fr: "Couleur principale",
    it: "Colore primario",
    sq: "Ngjyra kryesore",
    tr: "Ana renk",
  },
  "Payment method": {
    en: "Payment method",
    pt: "Método de pagamento",
    de: "Zahlungsmethode",
    es: "Método de pago",
    fr: "Moyen de paiement",
    it: "Metodo di pagamento",
    sq: "Metoda e pagesës",
    tr: "Ödeme yöntemi",
  },
  "Password changed": {
    en: "Password changed!",
    pt: "Senha alterada!",
    de: "Passwort geändert!",
    es: "¡Contraseña cambiada!",
    fr: "Mot de passe changé !",
    it: "Password cambiata!",
    sq: "Fjalëkalimi u ndryshua!",
    tr: "Şifre değiştirildi!",
  },
  "Please enter the password of the account you want to attach.": {
    en: "Please enter the password of the account you want to attach.",
    pt: "Por favor, insira a senha da conta que deseja anexar.",
    de: "Bitte geben Sie das Passwort des Kontos ein, das Sie anhängen möchten.",
    es: "Por favor, ingrese la contraseña de la cuenta que desea adjuntar.",
    fr: "Veuillez entrer le mot de passe du compte que vous souhaitez attacher.",
    it: "Si prega di inserire la password dell'account che si desidera allegare.",
    sq: "Ju lutemi vendosni fjalëkalimin e llogarisë që dëshironi të bashkangjitni.",
    tr: "Lütfen eklemek istediğiniz hesabın şifresini girin.",
  },
  "Please check the playlist as it is incorrectly filled in.": {
    en: "Please check the playlist as it is incorrectly filled in.",
    pt: "Por favor, verifique a lista de reprodução, pois está preenchida incorretamente.",
    de: "Bitte überprüfen Sie die Playlist, da sie falsch ausgefüllt ist.",
    es: "Por favor, compruebe la lista de reproducción, ya que está incorrectamente rellenada.",
    fr: "Veuillez vérifier la playlist car elle est incorrectement remplie.",
    it: "Si prega di controllare la playlist in quanto è compilata in modo errato.",
    sq: "Ju lutemi kontrolloni listën e këngëve, pasi është mbushur gabimisht.",
    tr: "Lütfen listeyi kontrol edin, çünkü yanlış doldurulmuş.",
  },
  "Please check this playlist as its expiration date has expired.": {
    en: "Please check this playlist as its expiration date has expired.",
    pt: "Por favor, verifique esta lista de reprodução, pois a data de validade expirou.",
    de: "Bitte überprüfen Sie diese Playlist, da das Ablaufdatum abgelaufen ist.",
    es: "Por favor, compruebe esta lista de reproducción, ya que su fecha de vencimiento ha caducado.",
    fr: "Veuillez vérifier cette playlist car sa date d'expiration est dépassée.",
    it: "Si prega di controllare questa playlist poiché la data di scadenza è scaduta.",
    sq: "Ju lutemi kontrolloni këtë listë këngësh, pasi data e skadimit ka përfunduar.",
    tr: "Lütfen bu listeyi kontrol edin, çünkü son kullanma tarihi dolmuştur.",
  },

  "Please input playlist URL": {
    en: "Please input playlist URL",
    pt: "Por favor, insira o URL da lista de reprodução",
    de: "Bitte geben Sie die URL der Wiedergabeliste ein",
    es: "Por favor, introduzca la URL de la lista de reproducción",
    fr: "Veuillez entrer l'URL de la playlist",
    it: "Si prega di inserire l'URL della playlist",
    sq: "Ju lutemi vendosni URL-në e listës së përgjimeve",
    tr: "Lütfen oynatma listesi URL'sini girin",
  },
  "Please enter device key": {
    en: "Please enter device key",
    pt: "Por favor, insira a chave do dispositivo",
    de: "Bitte geben Sie den Geräteschlüssel ein",
    es: "Por favor, introduzca la clave del dispositivo",
    fr: "Veuillez entrer la clé de l'appareil",
    it: "Per favore, inserisci la chiave del dispositivo",
    sq: "Ju lutemi vendosni çelësin e pajisjes",
    tr: "Lütfen cihaz anahtarını girin",
  },
  "Please check the entered data": {
    en: "Please check the entered data",
    pt: "Por favor, verifique os dados inseridos",
    de: "Bitte überprüfen Sie die eingegebenen Daten",
    es: "Por favor, verifique los datos ingresados",
    fr: "Veuillez vérifier les données saisies",
    it: "Si prega di verificare i dati inseriti",
    sq: "Ju lutemi kontrolloni të dhënat e vendosura",
    tr: "Lütfen girilen verileri kontrol edin",
  },

  "Please input name": {
    en: "Please input name",
    pt: "Por favor, insira o nome",
    de: "Bitte geben Sie den Namen ein",
    es: "Por favor, ingrese el nombre",
    fr: "Veuillez entrer le nom",
    it: "Per favore, inserisci il nome",
    sq: "Ju lutemi vendosni emrin",
    tr: "Lütfen ismi girin",
  },
  "Please input Host": {
    en: "Please input Host",
    pt: "Por favor, insira o Host",
    de: "Bitte geben Sie den Host ein",
    es: "Por favor, ingrese el Host",
    fr: "Veuillez entrer l'hôte",
    it: "Per favore, inserisci l'Host",
    sq: "Ju lutemi vendosni Hostin",
    tr: "Lütfen Host'u girin",
  },

  "Please input api key": {
    en: "Please input api key",
    pt: "Por favor, insira a chave da API",
    de: "Bitte geben Sie den API-Schlüssel ein",
    es: "Por favor, ingrese la clave de la API",
    fr: "Veuillez entrer la clé API",
    it: "Per favore, inserisci la chiave API",
    sq: "Ju lutemi vendosni çelësin API",
    tr: "Lütfen API anahtarını girin",
  },
  "Please input your name": {
    en: "Please input your name!",
    pt: "Por favor, insira o seu nome!",
    de: "Bitte geben Sie Ihren Namen ein!",
    es: "Por favor, ingrese su nombre!",
    fr: "Veuillez entrer votre nom !",
    it: "Per favore, inserisci il tuo nome!",
    sq: "Ju lutemi vendosni emrin tuaj!",
    tr: "Lütfen adınızı girin!",
  },
  "Please input cvv": {
    en: "Please input cvv",
    pt: "Por favor, insira o CVV",
    de: "Bitte geben Sie den CVV ein",
    es: "Por favor, ingrese el CVV",
    fr: "Veuillez saisir le cryptogramme visuel",
    it: "Inserisci il CVV per favore",
    sq: "Ju lutemi vendosni CVV-në",
    tr: "Lütfen CVV'yi girin",
  },
  "Please input number": {
    en: "Please input number",
    pt: "Por favor, insira um número",
    de: "Bitte geben Sie eine Zahl ein",
    es: "Por favor, ingrese un número",
    fr: "Veuillez saisir un numéro",
    it: "Per favore, inserire un numero",
    sq: "Ju lutemi vendosni numrin",
    tr: "Lütfen bir numara girin",
  },
  "Please input expiration month": {
    en: "Please input expiration month",
    pt: "Por favor, insira o mês de validade",
    de: "Bitte geben Sie den Ablaufmonat ein",
    es: "Por favor, ingrese el mes de vencimiento",
    fr: "Veuillez entrer le mois d'expiration",
    it: "Si prega di inserire il mese di scadenza",
    sq: "Ju lutemi vendosni muajin e skadimit",
    tr: "Lütfen son kullanma ayını girin",
  },
  "Please input expiration year": {
    en: "Please input expiration year",
    pt: "Por favor, insira o ano de expiração",
    de: "Bitte geben Sie das Ablaufjahr ein",
    es: "Por favor, introduzca el año de vencimiento",
    fr: "Veuillez entrer l'année d'expiration",
    it: "Si prega di inserire l'anno di scadenza",
    sq: "Ju lutemi vendosni vitin e skadimit",
    tr: "Lütfen son kullanma yılını girin",
  },
  "Please check auto renew": {
    en: "Please check auto renew",
    pt: "Por favor, verifique a renovação automática",
    de: "Bitte überprüfen Sie die automatische Verlängerung",
    es: "Por favor, compruebe la renovación automática",
    fr: "Veuillez vérifier le renouvellement automatique",
    it: "Per favore, controlla il rinnovo automatico",
    sq: "Ju lutemi kontrolloni rinovimin automatik",
    tr: "Lütfen otomatik yenilemeyi kontrol edin",
  },
  "Please input your password": {
    en: "Please input your password!",
    pt: "Por favor, insira sua senha!",
    de: "Bitte geben Sie Ihr Passwort ein!",
    es: "Por favor, ingrese su contraseña!",
    fr: "Veuillez entrer votre mot de passe !",
    it: "Per favore, inserisci la tua password!",
    sq: "Ju lutemi vendosni fjalëkalimin tuaj!",
    tr: "Lütfen şifrenizi girin!",
  },
  "Please confirm your password": {
    en: "Please confirm your password!",
    pt: "Por favor, confirme sua senha!",
    de: "Bitte bestätigen Sie Ihr Passwort!",
    es: "Por favor, confirme su contraseña!",
    fr: "Veuillez confirmer votre mot de passe !",
    it: "Per favore, conferma la tua password!",
    sq: "Ju lutemi konfirmoni fjalëkalimin tuaj!",
    tr: "Lütfen şifrenizi onaylayın!",
  },
  "Please input your username": {
    en: "Please input your username!",
    pt: "Por favor, insira seu nome de usuário!",
    de: "Bitte geben Sie Ihren Benutzernamen ein!",
    es: "Por favor, ingrese su nombre de usuario!",
    fr: "Veuillez entrer votre nom d'utilisateur !",
    it: "Per favore, inserisci il tuo nome utente!",
    sq: "Ju lutemi vendosni emrin tuaj të përdoruesit!",
    tr: "Lütfen kullanıcı adınızı girin!",
  },
  "Please input access code": {
    en: "Please input access code",
    pt: "Por favor, insira o código de acesso",
    de: "Bitte geben Sie den Zugangscode ein",
    es: "Por favor, ingrese el código de acceso",
    fr: "Veuillez entrer le code d'accès",
    it: "Per favore, inserisci il codice di accesso",
    sq: "Ju lutemi vendosni kodin e qasjes",
    tr: "Lütfen erişim kodunu girin",
  },
  "Please input your E-mail": {
    en: "Please input your E-mail!",
    pt: "Por favor, insira o seu e-mail!",
    de: "Bitte geben Sie Ihre E-Mail-Adresse ein!",
    es: "Por favor, ingrese su correo electrónico!",
    fr: "Veuillez entrer votre adresse e-mail !",
    it: "Per favore, inserisci la tua email!",
    sq: "Ju lutemi vendosni emailin tuaj!",
    tr: "Lütfen e-posta adresinizi girin!",
  },
  "Please input title": {
    en: "Please input title!",
    pt: "Por favor, insira o título!",
    de: "Bitte geben Sie den Titel ein!",
    es: "Por favor, ingrese el título!",
    fr: "Veuillez entrer le titre !",
    it: "Per favore, inserisci il titolo!",
    sq: "Ju lutemi vendosni titullin!",
    tr: "Lütfen başlığı girin!",
  },
  "Please input your 2FA email address": {
    en: "Please input your 2FA email address",
    pt: "Por favor, insira o seu endereço de e-mail de 2FA",
    de: "Bitte geben Sie Ihre 2FA-E-Mail-Adresse ein",
    es: "Por favor, ingrese su dirección de correo electrónico de 2FA",
    fr: "Veuillez entrer votre adresse e-mail 2FA",
    it: "Inserisci il tuo indirizzo email 2FA, per favore",
    sq: "Ju lutemi vendosni adresën tuaj të emailit 2FA",
    tr: "Lütfen 2FA e-posta adresinizi girin",
  },
  "Please input your brand name": {
    en: "Please input your brand name!",
    pt: "Por favor, insira o nome da sua marca!",
    de: "Bitte geben Sie den Markennamen ein!",
    es: "Por favor, ingrese el nombre de su marca!",
    fr: "Veuillez entrer le nom de votre marque !",
    it: "Per favore, inserisci il nome del tuo brand!",
    sq: "Ju lutemi vendosni emrin e markës suaj!",
    tr: "Lütfen marka adınızı girin!",
  },
  "Please input description": {
    en: "Please input description!",
    pt: "Por favor, insira a descrição!",
    de: "Bitte geben Sie eine Beschreibung ein!",
    es: "Por favor, ingrese una descripción!",
    fr: "Veuillez entrer la description !",
    it: "Per favore, inserisci la descrizione!",
    sq: "Ju lutemi vendosni përshkrimin!",
    tr: "Lütfen açıklamayı girin!",
  },
  "Please input your credit count": {
    en: "Please input your credit count!",
    pt: "Por favor, insira a contagem de crédito!",
    de: "Bitte geben Sie die Kreditanzahl ein!",
    es: "Por favor, ingrese la cantidad de créditos!",
    fr: "Veuillez entrer le nombre de crédits !",
    it: "Per favore, inserisci il conteggio dei crediti!",
    sq: "Ju lutemi vendosni numrin tuaj të kreditit!",
    tr: "Lütfen kredi sayınızı girin!",
  },
  "Pick new server to migrate your users": {
    en: "Pick new server to migrate your users",
    pt: "Escolha um novo servidor para migrar seus usuários",
    de: "Wählen Sie einen neuen Server, um Ihre Benutzer zu migrieren",
    es: "Seleccione un nuevo servidor para migrar a sus usuarios",
    fr: "Choisissez un nouveau serveur pour migrer vos utilisateurs",
    it: "Scegli un nuovo server per migrare i tuoi utenti",
    sq: "Zgjidhni serverin e ri për të migrur përdoruesit tuaj",
    tr: "Kullanıcılarınızı taşımak için yeni bir sunucu seçin",
  },
  "Please input your new password": {
    en: "Please input your new password!",
    pt: "Por favor, insira sua nova senha!",
    de: "Bitte geben Sie Ihr neues Passwort ein!",
    es: "Por favor, ingrese su nueva contraseña!",
    fr: "Veuillez entrer votre nouveau mot de passe !",
    it: "Per favore, inserisci la tua nuova password!",
    sq: "Ju lutemi vendosni fjalëkalimin tuaj të ri!",
    tr: "Lütfen yeni şifrenizi girin!",
  },
  "Please input your verify code": {
    en: "Please input your verify code",
    pt: "Por favor, insira seu código de verificação",
    de: "Bitte geben Sie Ihren Bestätigungscode ein",
    es: "Por favor, ingrese su código de verificación",
    fr: "Veuillez saisir votre code de vérification",
    it: "Inserisci il tuo codice di verifica",
    sq: "Ju lutemi vendosni kodin tuaj të verifikimit",
    tr: "Lütfen doğrulama kodunuzu girin",
  },
  "Password must be minimum 8 characters": {
    en: "Password must be minimum 8 characters.",
    pt: "A senha deve ter no mínimo 8 caracteres.",
    de: "Das Passwort muss mindestens 8 Zeichen lang sein.",
    es: "La contraseña debe tener como mínimo 8 caracteres.",
    fr: "Le mot de passe doit comporter au moins 8 caractères.",
    it: "La password deve essere lunga almeno 8 caratteri.",
    sq: "Fjalëkalimi duhet të jetë minimum 8 karaktere",
    tr: "Şifre en az 8 karakter olmalıdır.",
  },
  "Provider hasn't approved": {
    en: "Provider hasn't approved",
    pt: "O provedor não aprovou",
    de: "Der Anbieter hat nicht zugestimmt",
    es: "El proveedor no ha aprobado",
    fr: "Le fournisseur n'a pas approuvé",
    it: "Il fornitore non ha approvato",
    sq: "Furnizuesi nuk ka miratuar",
    tr: "Sağlayıcı onaylamadı",
  },
  "Pick expiration date of the playlist": {
    en: "Pick expiration date of the playlist",
    pt: "Escolha a data de expiração da lista de reprodução",
    de: "Wählen Sie das Ablaufdatum der Wiedergabeliste",
    es: "Seleccione la fecha de vencimiento de la lista de reproducción",
    fr: "Choisissez la date d'expiration de la liste de lecture",
    it: "Scegli la data di scadenza della playlist",
    sq: "Zgjidh datën e skadimit të listës së riprodhimit",
    tr: "Oynatma listesinin sonlandırma tarihini seçin",
  },
  "Please select the type of your server": {
    en: "Please select the type of your server",
    pt: "Por favor, selecione o tipo do seu servidor",
    de: "Bitte wählen Sie den Servertyp aus",
    es: "Por favor, seleccione el tipo de su servidor",
    fr: "Veuillez sélectionner le type de votre serveur",
    it: "Per favore, seleziona il tipo del tuo server",
    sq: "Ju lutemi zgjidhni llojin e serverit tuaj",
    tr: "Lütfen sunucunuzun türünü seçin",
  },
  "Please, contact us in case of any changes": {
    en: "Please, contact us in case of any changes.",
    pt: "Por favor, entre em contato conosco em caso de qualquer alteração.",
    de: "Bitte kontaktieren Sie uns im Falle von Änderungen.",
    es: "Por favor, contáctenos en caso de cualquier cambio.",
    fr: "Veuillez nous contacter en cas de modifications.",
    it: "Per favore, contattaci in caso di modifiche.",
    sq: "Ju lutemi, na kontaktoni në rast ndonjë ndryshimi.",
    tr: "Lütfen herhangi bir değişiklik durumunda bizimle iletişime geçin.",
  },
  "Please select the server you want to migrate": {
    en: "Please select the server you want to migrate from and the new server you want to migrate to!",
    pt: "Por favor, selecione o servidor de origem e o novo servidor para migrar!",
    de: "Bitte wählen Sie den Server aus, von dem Sie migrieren möchten, und den neuen Server, zu dem Sie migrieren möchten!",
    es: "Por favor, seleccione el servidor del que desea migrar y el nuevo servidor al que desea migrar.",
    fr: "Veuillez sélectionner le serveur à partir duquel vous souhaitez migrer et le nouveau serveur vers lequel vous souhaitez migrer !",
    it: "Per favore, seleziona il server da cui vuoi migrare e il nuovo server in cui vuoi migrare!",
    sq: "Ju lutemi zgjidhni serverin nga të cilit dëshironi të migratoni dhe serverin e ri ku dëshironi të migroni!",
    tr: "Lütfen taşımak istediğiniz sunucuyu seçin ve taşımak istediğiniz yeni sunucuyu seçin!",
  },
  "Please enter the Device key": {
    en: "Please enter the Device key. It is shown in the bottom left corner of the device.",
    pt: "Por favor, insira a chave do dispositivo. Ela é mostrada no canto inferior esquerdo do dispositivo.",
    de: "Bitte geben Sie den Geräteschlüssel ein. Er wird unten links auf dem Gerät angezeigt.",
    es: "Por favor, ingrese la clave del dispositivo. Se muestra en la esquina inferior izquierda del dispositivo.",
    fr: "Veuillez entrer la clé de l'appareil. Elle est affichée dans le coin inférieur gauche de l'appareil.",
    it: "Per favore, inserisci la chiave del dispositivo. È mostrata nell'angolo in basso a sinistra del dispositivo.",
    sq: "Ju lutemi vendosni çelësin e pajisjes. Ai shfaqet në cepin e majtë të fundit të pajisjes.",
    tr: "Lütfen Aygıt anahtarını girin. Aygıtın sol alt köşesinde gösterilmektedir.",
  },

  "Please input": {
    en: "Please input",
    pt: "Por favor, insira",
    de: "Bitte eingeben",
    es: "Por favor, ingrese",
    fr: "Veuillez entrer",
    it: "Per favore, inserire",
    sq: "Ju lutemi vendosni",
    tr: "Lütfen girin",
  },
  Payments: {
    en: "Payments",
    pt: "Pagamentos",
    de: "Zahlungen",
    es: "Pagos",
    fr: "Paiements",
    it: "Pagamenti",
    sq: "Pagesat",
    tr: "Ödemeler",
  },
  "Pay with BTC": {
    en: "Pay with BTC",
    pt: "Pagar com BTC",
    de: "Mit BTC bezahlen",
    es: "Pagar con BTC",
    fr: "Payer avec BTC",
    it: "Paga con BTC",
    sq: "Paguaj me BTC",
    tr: "BTC ile öde",
  },
  "Pay with Coinbase": {
    en: "Pay with Coinbase",
    pt: "Pagar com Coinbase",
    de: "Mit Coinbase bezahlen",
    es: "Pagar con Coinbase",
    fr: "Payer avec Coinbase",
    it: "Paga con Coinbase",
    sq: "Paguaj me Coinbase",
    tr: "Coinbase ile öde",
  },
  "Pay with PIX": {
    en: "Pay with PIX",
    pt: "Pagar com PIX",
    de: "Zahlen Sie mit PIX",
    es: "Pagar con PIX",
    fr: "Payer avec PIX",
    it: "Paga con PIX",
    sq: "Paguaj me PIX",
    tr: "PIX ile öde",
  },
  "Pay with PIX card": {
    en: "Pay with PIX card",
    pt: "Pagar com cartão PIX",
    de: "Mit PIX-Karte bezahlen",
    es: "Pagar con tarjeta PIX",
    fr: "Payer avec la carte PIX",
    it: "Paga con la carta PIX",
    sq: "Paguaj me kartën PIX",
    tr: "PIX kart ile öde",
  },
  "Paymant method not avalible now": {
    en: "Paymant method not avalible now",
    pt: "Método de pagamento não disponível agora",
    de: "Zahlungsmethode jetzt nicht verfügbar",
    es: "Método de pago no disponible ahora",
    fr: "Méthode de paiement non disponible maintenant",
    it: "Metodo di pagamento non disponibile ora",
    sq: "Metoda e pagesës nuk është e disponueshme tani",
    tr: "Ödeme yöntemi şu anda mevcut değil",
  },
  Pay: {
    en: "Pay",
    pt: "Pagar",
    de: "Bezahlen",
    es: "Pagar",
    fr: "Payer",
    it: "Pagare",
    sq: "Paguaj",
    tr: "Öde",
  },
  Poster: {
    en: "Poster",
    pt: "Pôster",
    de: "Plakat",
    es: "Póster",
    fr: "Affiche",
    it: "Poster",
    sq: "Poster",
    tr: "Afiş",
  },
  "Privacy policy": {
    en: "Privacy policy",
    pt: "Política de privacidade",
    de: "Datenschutzbestimmungen",
    es: "Política de privacidad",
    fr: "Politique de confidentialité",
    it: "Informativa sulla privacy",
    sq: "Politika e privatësisë",
    tr: "Gizlilik politikası",
  },

  //t
  "to remove seller status": {
    en: "to remove seller status",
    pt: "remover o status de vendedor",
    de: "den Verkäuferstatus entfernen",
    es: "eliminar el estado de vendedor",
    fr: "retirer le statut de vendeur",
    it: "rimuovere lo status di venditore",
    sq: "për të hequr statusin e shitësit",
    tr: "satıcı statüsünü kaldırmak",
  },
  "The dimensions of the file are": {
    en: "The dimensions of the file are",
    es: "Las dimensiones del archivo son",
    fr: "Les dimensions du fichier sont",
    de: "Die Abmessungen der Datei sind",
    pt: "As dimensões do arquivo são",
    it: "Le dimensioni del file sono",
    sq: "Përmasat e skedarit janë",
    tr: "Dosyanın boyutları şunlardır",
  },

  "Terms of usage": {
    en: "Terms of usage",
    pt: "Termos de uso",
    de: "Nutzungsbedingungen",
    es: "Términos de uso",
    fr: "Conditions d'utilisation",
    it: "Termini di utilizzo",
    sq: "Kushtet e përdorimit",
    tr: "Kullanım şartları",
  },

  "Total free trial and active": {
    en: "Total free trial and active",
    pt: "Total de teste grátis e ativo",
    de: "Gesamte kostenlose Testversion und aktiv",
    es: "Total de prueba gratuita y activo",
    fr: "Essai gratuit total et actif",
    it: "Totale prova gratuita e attiva",
    sq: "Totali i provave të lira dhe aktiv",
    tr: "Toplam ücretsiz deneme ve aktif",
  },

  "This platform is currently unavailable to you because your partner has not subscribed yet. If you want to access this platform, please reach out to your partner":
    {
      en: "This platform is currently unavailable to you because your partner has not subscribed yet. If you want to access this platform, please reach out to your partner.",
      pt: "Esta plataforma está atualmente indisponível para você porque seu parceiro ainda não se inscreveu. Se você deseja acessar esta plataforma, por favor, entre em contato com seu parceiro.",
      de: "Diese Plattform steht Ihnen derzeit nicht zur Verfügung, da Ihr Partner sich noch nicht angemeldet hat. Wenn Sie auf diese Plattform zugreifen möchten, setzen Sie sich bitte mit Ihrem Partner in Verbindung.",
      es: "Esta plataforma no está disponible actualmente para ti porque tu compañero aún no se ha suscrito. Si deseas acceder a esta plataforma, por favor, ponte en contacto con tu compañero.",
      fr: "Cette plateforme n'est actuellement pas disponible pour vous car votre partenaire ne s'est pas encore abonné. Si vous souhaitez accéder à cette plateforme, veuillez contacter votre partenaire.",
      it: "Questa piattaforma non è attualmente disponibile per te perché il tuo partner non si è ancora iscritto. Se desideri accedere a questa piattaforma, per favore, contatta il tuo partner.",
      sq: "Ky platformë është momentalisht e padisponueshme për ju sepse partneri juaj ende nuk është abonuar. Nëse dëshironi të hyni në këtë platformë, ju lutemi kontaktoni partnerin tuaj.",
      tr: "Bu platform şu anda size uygun değil çünkü ortağınız henüz abone olmadı. Bu platforma erişmek istiyorsanız, lütfen ortağınıza ulaşın.",
    },

  Type: {
    en: "Type",
    pt: "Tipo",
    de: "Typ",
    es: "Tipo",
    fr: "Type",
    it: "Tipo",
    sq: "Lloji",
    tr: "Tür",
  },

  Title: {
    en: "Title",
    pt: "Título",
    de: "Titel",
    es: "Título",
    fr: "Titre",
    it: "Titolo",
    sq: "Titulli",
    tr: "Başlık",
  },

  Total: {
    en: "Total",
    pt: "Total",
    de: "Gesamt",
    es: "Total",
    fr: "Total",
    it: "Totale",
    sq: "Totali",
    tr: "Toplam",
  },

  Trial: {
    en: "Trial",
    pt: "Teste",
    de: "Test",
    es: "Prueba",
    fr: "Essai",
    it: "Prova",
    sq: "Provë",
    tr: "Deneme",
  },
  "Top Movies": {
    en: "Top Movies",
    pt: "Melhores Filmes",
    de: "Top-Filme",
    es: "Películas Populares",
    fr: "Meilleurs Films",
    it: "I Migliori Film",
    sq: "Filmat më të Mirë",
    tr: "En İyi Filmler",
  },

  "Top Lives": {
    en: "Top Lives",
    pt: "Melhores Canais",
    de: "Top-Lives",
    es: "Vidas Destacadas",
    fr: "Meilleures Lives",
    it: "Live Top",
    sq: "Lives më të Mirë",
    tr: "En İyi Yayınlar",
  },

  "Top TV Shows": {
    en: "Top TV Shows",
    pt: "Melhores Séries",
    de: "Top-Fernsehsendungen",
    es: "Mejores Programas de TV",
    fr: "Meilleures Émissions de Télévision",
    it: "Migliori Programmi TV",
    sq: "Shfaqje televizive më të Mirë",
    tr: "En İyi TV Şovları",
  },

  "Take Credits": {
    en: "Take Credits",
    pt: "Adquirir Créditos",
    de: "Credits Erwerben",
    es: "Obtener Créditos",
    fr: "Obtenir des Crédits",
    it: "Acquista Crediti",
    sq: "Merr Kredite",
    tr: "Kredi Al",
  },

  "Transaction ID": {
    en: "Transaction ID",
    pt: "ID da Transação",
    de: "Transaktions-ID",
    es: "ID de Transacción",
    fr: "ID de Transaction",
    it: "ID Transazione",
    sq: "ID e Transaksionit",
    tr: "İşlem Kimliği",
  },

  "Total activations": {
    en: "Total activations",
    pt: "Ativações Totais",
    de: "Gesamtaktivierungen",
    es: "Activaciones Totales",
    fr: "Activations Totales",
    it: "Attivazioni Totali",
    sq: "Aktivizime totale",
    tr: "Toplam Aktivasyon",
  },
  "Trial expired": {
    en: "Trial expired",
    pt: "Período de teste expirado",
    de: "Testphase abgelaufen",
    es: "Prueba expirada",
    fr: "Essai expiré",
    it: "Prova scaduta",
    sq: "Prova ka skaduar",
    tr: "Deneme süresi dolmuş",
  },

  "Trial remaining days": {
    en: "Trial remaining days",
    pt: "Dias de teste restantes",
    de: "Verbleibende Testtage",
    es: "Días de Prueba Restantes",
    fr: "Jours d'Essai Restants",
    it: "Giorni di Prova Rimasti",
    sq: "Ditët e mbetura të provës",
    tr: "Kalan deneme günleri",
  },

  "That's not a valid URL": {
    en: "That's not a valid URL",
    pt: "Isso não é uma URL válida",
    de: "Das ist keine gültige URL",
    es: "Esa no es una URL válida",
    fr: "Ce n'est pas une URL valide",
    it: "Questa non è un'URL valida",
    sq: "Ky nuk është një URL e vlefshme",
    tr: "Bu geçerli bir URL değil",
  },
  "The URL is not reachable": {
    en: "The URL is not reachable.",
    pt: "A URL não está acessível.",
    de: "Die URL ist nicht erreichbar.",
    es: "La URL no es accesible.",
    fr: "L'URL n'est pas accessible.",
    it: "L'URL non è raggiungibile.",
    sq: "URL nuk është i arritshëm.",
    tr: "URL ulaşılamıyor.",
  },

  "That's not a valid host": {
    en: "That's not a valid host",
    pt: "Isso não é um host válido",
    de: "Das ist kein gültiger Host",
    es: "Eso no es un host válido",
    fr: "Ce n'est pas un hôte valide",
    it: "Questo non è un host valido",
    sq: "Ky nuk është një host i vlefshëm",
    tr: "Bu geçerli bir host değil.",
  },

  "TV Shows Categories": {
    en: "TV Shows Categories",
    pt: "Categorias de Séries",
    de: "TV-Sendungskategorien",
    es: "Categorías de Programas de TV",
    fr: "Catégories d'Émissions de Télévision",
    it: "Categorie di Programmi TV",
    sq: "Kategoritë e emisioneve televizive",
    tr: "TV Şovları Kategorileri",
  },
  "There are 2 types of activation": {
    en: "There are 2 types of activation:",
    pt: "Existem 2 tipos de ativação:",
    de: "Es gibt 2 Arten der Aktivierung:",
    es: "Hay 2 tipos de activación:",
    fr: "Il y a 2 types d'activation :",
    it: "Ci sono 2 tipi di attivazione:",
    sq: "Ka 2 lloje të aktivizimit:",
    tr: "2 tür aktivasyon vardır:",
  },

  "The two passwords that you entered do not match": {
    en: "The two passwords that you entered do not match!",
    pt: "As duas senhas que você digitou não coincidem!",
    de: "Die beiden Passwörter, die Sie eingegeben haben, stimmen nicht überein!",
    es: "Las dos contraseñas que ingresó no coinciden!",
    fr: "Les deux mots de passe que vous avez saisis ne correspondent pas !",
    it: "Le due password che hai inserito non corrispondono!",
    sq: "Dy fjalëkalimet që keni shkruar nuk përputhen!",
    tr: "Girdiğiniz iki şifre eşleşmiyor!",
  },

  "Tell us your email and we will send your password": {
    en: "Tell us your email and we will send your password.",
    pt: "Informe-nos seu e-mail e enviaremos sua senha.",
    de: "Geben Sie uns Ihre E-Mail-Adresse an, und wir senden Ihnen Ihr Passwort.",
    es: "Díganos su correo electrónico y le enviaremos su contraseña.",
    fr: "Indiquez-nous votre adresse e-mail, et nous vous enverrons votre mot de passe.",
    it: "Dicci la tua email e ti invieremo la tua password.",
    sq: "Na tregoni emailin tuaj dhe ne do t'ju dërgojmë fjalëkalimin tuaj.",
    tr: "Bize e-posta adresinizi söyleyin, şifrenizi göndereceğiz.",
  },
  "Then you can separate and send notifications by device keys": {
    en: "Then you can separate and send notifications by device keys, by registration date, by platforms, all at once.",
    pt: "Em seguida, você pode separar e enviar notificações por chaves de dispositivo, por data de registro, por plataformas, tudo de uma vez.",
    de: "Dann können Sie Benachrichtigungen nach Geräteschlüsseln, nach Anmeldedatum, nach Plattformen oder alles auf einmal trennen und senden.",
    es: "Luego puede separar y enviar notificaciones por claves de dispositivo, por fecha de registro, por plataformas, todo a la vez.",
    fr: "Ensuite, vous pouvez séparer et envoyer des notifications par clés de périphérique, par date d'inscription, par plateformes, tout en une seule fois.",
    it: "Poi puoi separare e inviare notifiche per chiavi di dispositivo, per data di registrazione, per piattaforme, tutto in una volta.",
    sq: "Pastaj mund të ndani dhe të dërgoni njoftime sipas çelësave të pajisjes, sipas datës së regjistrimit, sipas platformave, të gjitha në njëherë.",
    tr: "Sonra, bildirimleri cihaz anahtarlarına, kayıt tarihine ve platformlara göre ayırabilir ve hepsini birden gönderebilirsiniz.",
  },
  "Then, set the notification send date and the notification expiration date": {
    en: "Then, set the notification send date and the notification expiration date.",
    pt: "Em seguida, defina a data de envio da notificação e a data de expiração da notificação.",
    de: "Legen Sie dann das Datum für den Versand der Benachrichtigung und das Ablaufdatum der Benachrichtigung fest.",
    es: "Luego, establezca la fecha de envío de la notificación y la fecha de vencimiento de la notificación.",
    fr: "Ensuite, définissez la date d'envoi de la notification et la date d'expiration de la notification.",
    it: "Poi, imposta la data di invio della notifica e la data di scadenza della notifica.",
    sq: "Pastaj, vendosni datën e dërgimit të njoftimit dhe datën e skadimit të njoftimit.",
    tr: "Sonra, bildirim gönderim tarihini ve bildirim sona erme tarihini ayarlayın.",
  },
  "The credit count will be updated on your profile during 5 minutes": {
    en: "The credit count will be updated on your profile during 5 minutes. If you will have any problems with the payment please contact",
    pt: "A contagem de créditos será atualizada em seu perfil durante 5 minutos. Se você tiver algum problema com o pagamento, entre em contato.",
    de: "Die Kreditanzahl wird in Ihrem Profil innerhalb von 5 Minuten aktualisiert. Wenn Sie Probleme mit der Zahlung haben, kontaktieren Sie bitte",
    es: "El recuento de créditos se actualizará en su perfil durante 5 minutos. Si tiene algún problema con el pago, póngase en contacto con",
    fr: "Le nombre de crédits sera mis à jour sur votre profil pendant 5 minutes. Si vous avez des problèmes de paiement, veuillez contacter",
    it: "Il conteggio dei crediti verrà aggiornato nel tuo profilo entro 5 minuti. Se avrai problemi con il pagamento, contatta",
    sq: "Numri i kredive do të përditësohet në profilin tuaj gjatë 5 minutave. Nëse keni ndonjë problem me pagesën, ju lutemi kontaktoni",
    tr: "Kredi sayısı profilinizde 5 dakika içinde güncellenecektir. Ödeme ile ilgili herhangi bir sorun yaşarsanız, lütfen iletişime geçin.",
  },
  "This device is attached to you, you can find it in your device list": {
    en: "This device is attached to you, you can find it in your device list",
    pt: "Este dispositivo está ligado a si, pode encontrá-lo na sua lista de dispositivos",
    de: "Dieses Gerät ist mit Ihnen verbunden, Sie finden es in Ihrer Geräteliste",
    es: "Este dispositivo está vinculado a usted, puede encontrarlo en su lista de dispositivos",
    fr: "Cet appareil vous est attribué, vous pouvez le trouver dans votre liste d'appareils",
    it: "Questo dispositivo è collegato a te, puoi trovarlo nella tua lista di dispositivi",
    sq: "Kjo pajisje është e lidhur me ju, mund ta gjeni në listën tuaj të pajisjeve",
    tr: "Bu cihaz size bağlı, cihazlar listenizde bulabilirsiniz.",
  },
  "The devices are busy with another provider": {
    en: "The devices are busy with another provider",
    pt: "Os dispositivos estão ocupados com outro fornecedor",
    de: "Die Geräte sind bei einem anderen Anbieter im Einsatz",
    es: "Los dispositivos están ocupados con otro proveedor",
    fr: "Les appareils sont occupés avec un autre fournisseur",
    it: "I dispositivi sono occupati con un altro fornitore",
    sq: "Pajisjet janë të zëna me një ofrues tjetër",
    tr: "Cihazlar başka bir sağlayıcı ile meşguldür.",
  },
  "Total: All devices count Free trial": {
    en: "Total: All devices count Free trial: Devices that are in Free Trial period Active: Active devices count Trial expired: Devices whose free trial period has been expired and they need to be activated to continue using the TV app.",
    pt: "Total: Contagem de todos os dispositivos Teste gratuito: Dispositivos que estão no período de teste gratuito Ativos: Contagem de dispositivos ativos Teste expirado: Dispositivos cujo período de teste gratuito expirou e precisam ser ativados para continuar usando o aplicativo de TV.",
    de: "Gesamt: Anzahl aller Geräte Testversion: Geräte, die sich im Testzeitraum befinden Aktiv: Anzahl der aktiven Geräte Test abgelaufen: Geräte, deren Testzeitraum abgelaufen ist und die aktiviert werden müssen, um die TV-App weiterhin zu nutzen.",
    es: "Total: Cantidad total de dispositivos Prueba gratuita: Dispositivos que están en período de prueba gratuita Activo: Cantidad de dispositivos activos Prueba vencida: Dispositivos cuyo período de prueba gratuita ha vencido y necesitan activarse para seguir utilizando la aplicación de TV.",
    fr: "Total : Nombre total de dispositifs Essai gratuit : Dispositifs en période d'essai gratuit Actif : Nombre de dispositifs actifs Essai expiré : Dispositifs dont la période d'essai gratuit a expiré et qui doivent être activés pour continuer à utiliser l'application TV.",
    it: "Totale: Conteggio totale dei dispositivi Prova gratuita: Dispositivi che si trovano in periodo di prova gratuita Attivi: Conteggio dei dispositivi attivi Prova scaduta: Dispositivi il cui periodo di prova gratuita è scaduto e devono essere attivati per continuare a utilizzare l'app TV.",
    sq: "Totali: Gjithë numërimi i pajisjeve Provë e lirë: Pajisjet që janë në periudhën e provës së lirë Aktiv: Numërimi i pajisjeve aktive Provë e skaduar: Pajisjet të cilat janë skaduar periudha e tyre e provës së lirë dhe duhet të aktivizohen për të vazhduar të përdorin aplikacionin e TV-së.",
    tr: "Toplam: Tüm cihaz sayısı Ücretsiz deneme: Ücretsiz deneme süresinde olan cihazlar Aktif: Aktif cihaz sayısı Deneme süresi dolmuş: Ücretsiz deneme süresi dolmuş cihazlar, TV uygulamasını kullanmaya devam etmek için etkinleştirilmesi gerekmektedir.",
  },
  "to become a seller": {
    en: "to become a seller",
    pt: "tornar-se um vendedor",
    de: "ein Verkäufer werden",
    es: "convertirse en vendedor",
    fr: "devenir vendeur",
    it: "diventare un venditore",
    sq: "të bëheni një shitës",
    tr: "bir satıcı olmak",
  },
  "Two-Factor Authentication (2FA) Code Reminder": {
    en: "Please ensure that a verification code is dispatched to the email address registered for your two-factor authentication (2FA). Kindly review your mailbox for the code within the next 10 minutes.",
    pt: "Certifique-se de que um código de verificação seja enviado para o endereço de e-mail registrado para sua autenticação de dois fatores (2FA). Por favor, verifique sua caixa de entrada para o código nos próximos 10 minutos.",
    de: "Bitte stellen Sie sicher, dass ein Bestätigungscode an die für Ihre Zwei-Faktor-Authentifizierung (2FA) registrierte E-Mail-Adresse gesendet wird. Überprüfen Sie bitte Ihr Postfach auf den Code innerhalb der nächsten 10 Minuten.",
    es: "Asegúrese de que se envíe un código de verificación a la dirección de correo electrónico registrada para su autenticación de dos factores (2FA). Revise su bandeja de entrada para encontrar el código en los próximos 10 minutos.",
    fr: "Veuillez vous assurer qu'un code de vérification est envoyé à l'adresse e-mail enregistrée pour votre authentification à deux facteurs (2FA). Veuillez vérifier votre boîte de réception pour le code dans les 10 prochaines minutes.",
    it: "Assicurati che un codice di verifica venga inviato all'indirizzo email registrato per la tua autenticazione a due fattori (2FA). Controlla gentilmente la tua casella di posta per il codice entro i prossimi 10 minuti.",
    sq: "Ju lutemi sigurohuni që një kod verifikimi të dërgohet në adresën e emailit të regjistruar për autentifikimin tuaj dyfaktor (2FA). Ju lutemi, shqyrtoni postën tuaj elektronike për kodin brenda 10 minutave të ardhshme.",
    tr: "Lütfen iki faktörlü kimlik doğrulama (2FA) için kayıtlı e-posta adresinize bir doğrulama kodunun gönderildiğinden emin olun. Lütfen kodu kontrol etmek için gelen kutunuzu sonraki 10 dakika içinde gözden geçirin.",
  },
  "Text copied to clipboard": {
    en: "Text copied to clipboard",
    pt: "Texto copiado para a área de transferência",
    de: "Text in die Zwischenablage kopiert",
    es: "Texto copiado al portapapeles",
    fr: "Texte copié dans le presse-papiers",
    it: "Testo copiato negli appunti",
    sq: "Teksti u kopjua në shiritin e prerjes",
    tr: "Metin panoya kopyalandı",
  },
  "Trial expired date": {
    en: "Trial expired date",
    pt: "Data de expiração do período de avaliação",
    de: "Ablaufdatum der Testphase",
    es: "Fecha de vencimiento de la prueba",
    fr: "Date d'expiration de l'essai",
    it: "Data di scadenza della prova",
    sq: "Data e skadimit të provës së lirë",
    tr: "Deneme süresi bitiş tarihi",
  },

  //r
  "Refund policy": {
    en: "Refund policy",
    pt: "Política de reembolso",
    de: "Rückerstattungsrichtlinie",
    es: "Política de reembolso",
    fr: "Politique de remboursement",
    it: "Politica di rimborso",
    sq: "Politika e rimbursimit",
    tr: "İade politikası",
  },
  Reset: {
    en: "Reset",
    pt: "Redefinir",
    de: "Zurücksetzen",
    es: "Restablecer",
    fr: "Réinitialiser",
    it: "Ripristina",
    sq: "Rivendos",
    tr: "Sıfırla",
  },
  "Reseller name": {
    en: "Reseller name",
    pt: "Nome do Revendedor",
    de: "Wiederverkäufername",
    es: "Nombre del Revendedor",
    fr: "Nom du Revendeur",
    it: "Nome del Rivenditore",
    sq: "Emri i ri-shitësit",
    tr: "Bayii adı",
  },
  "Reseller email": {
    en: "Reseller email",
    pt: "E-mail do Revendedor",
    de: "Wiederverkäufer-E-Mail",
    es: "Correo electrónico del Revendedor",
    fr: "E-mail du Revendeur",
    it: "Email del Rivenditore",
    sq: "Emaili i ri-shitësit",
    tr: "Bayii e-postası",
  },
  "Release date": {
    en: "Release date",
    pt: "Data de Lançamento",
    de: "Veröffentlichungsdatum",
    es: "Fecha de Lanzamiento",
    fr: "Date de Sortie",
    it: "Data di Uscita",
    sq: "Data e publikimit",
    tr: "Çıkış tarihi",
  },
  "Reset password": {
    en: "Reset password",
    pt: "Redefinir senha",
    de: "Passwort zurücksetzen",
    es: "Restablecer contraseña",
    fr: "Réinitialiser le mot de passe",
    it: "Ripristina password",
    sq: "Rivendos fjalëkalimin",
    tr: "Şifreyi sıfırla",
  },
  "Reseller Config": {
    en: "Reseller Config",
    pt: "Configuração do Revendedor",
    de: "Wiederverkäuferkonfiguration",
    es: "Configuración del Revendedor",
    fr: "Configuration du Revendeur",
    it: "Configurazione del Rivenditore",
    sq: "Konfigurimi i Rishitësit",
    tr: "Bayii Konfigürasyonu",
  },
  "Receiving devices": {
    en: "Receiving devices",
    pt: "Recebendo dispositivos",
    de: "Geräte empfangen",
    es: "Recepción de dispositivos",
    fr: "Réception des dispositifs",
    it: "Ricezione dei dispositivi",
    sq: "Marrja e pajisjeve",
    tr: "Alma cihazları",
  },
  "Retype password": {
    en: "Retype password",
    pt: "Digite a senha novamente",
    de: "Passwort erneut eingeben",
    es: "Vuelva a escribir la contraseña",
    fr: "Saisissez à nouveau le mot de passe",
    it: "Ridigita la password",
    sq: "Rishkruaj fjalëkalimin",
    tr: "Şifreyi yeniden girin",
  },
  "Reseller devices": {
    en: "Reseller devices",
    pt: "Dispositivos do Revendedor",
    de: "Wiederverkäufergeräte",
    es: "Dispositivos del Revendedor",
    fr: "Dispositifs du Revendeur",
    it: "Dispositivi del Rivenditore",
    sq: "Pajisjet e Rishitësit",
    tr: "Bayii cihazları",
  },
  "Reset my password": {
    en: "Reset my password",
    pt: "Redefinir minha senha",
    de: "Mein Passwort zurücksetzen",
    es: "Restablecer mi contraseña",
    fr: "Réinitialiser mon mot de passe",
    it: "Ripristina la mia password",
    sq: "Rivendos fjalëkalimin tim",
    tr: "Şifremi sıfırla",
  },
  "Reseller configuration updates are successful": {
    en: "Reseller configuration updates are successful.",
    pt: "As atualizações de configuração do revendedor foram bem-sucedidas.",
    de: "Die Aktualisierungen der Wiederverkäuferkonfiguration waren erfolgreich.",
    es: "Las actualizaciones de configuración del revendedor se han realizado con éxito.",
    fr: "Les mises à jour de la configuration du revendeur ont réussi.",
    it: "Le modifiche alla configurazione del rivenditore sono state effettuate con successo.",
    sq: "Përditësimet e konfigurimit të shitësit janë kryer me sukses.",
    tr: "Bayii yapılandırma güncellemeleri başarıyla gerçekleştirildi.",
  },
  "Reseller will need to buy credits": {
    en: "Reseller will need to buy credits",
    pt: "O revendedor precisará comprar créditos",
    de: "Der Wiederverkäufer muss Guthaben kaufen",
    es: "El revendedor deberá comprar créditos",
    fr: "Le revendeur devra acheter des crédits",
    it: "Il rivenditore dovrà acquistare crediti",
    sq: "Shitësi do të ketë nevojë të blejë kredite",
    tr: "Bayinin kredi satın alması gerekecek",
  },
  "Registered Devices": {
    en: "Registered Devices",
    pt: "Dispositivos Registrados",
    de: "Registrierte Geräte",
    es: "Dispositivos Registrados",
    fr: "Appareils Enregistrés",
    it: "Dispositivi Registrati",
    sq: "Pajisjet e Regjistruara",
    tr: "Kayıtlı Cihazlar",
  },
  "Reseller can use my credits": {
    en: "Reseller can use my credits",
    pt: "O revendedor pode usar meus créditos",
    de: "Der Wiederverkäufer kann meine Guthaben nutzen",
    es: "El revendedor puede utilizar mis créditos",
    fr: "Le revendeur peut utiliser mes crédits",
    it: "Il rivenditore può usare i miei crediti",
    sq: "Shitësi mund të përdorë kreditë e mia",
    tr: "Bayii kredilerimi kullanabilir",
  },
  "Reset requests": {
    en: "Reset requests",
    pt: "Solicitações de reset",
    de: "Reset-Anfragen",
    es: "Solicitudes de reinicio",
    fr: "Demandes de réinitialisation",
    it: "Richieste di reset",
    sq: "Kërkesat e rivendosjes",
    tr: "Sıfırlama talepleri",
  },
  //f
  from: {
    en: "from",
    pt: "de",
    de: "von",
    es: "de",
    fr: "de",
    it: "da",
    sq: "nga",
    tr: "den",
  },
  Filter: {
    en: "Filter",
    pt: "Filtrar",
    de: "Filtern",
    es: "Filtrar",
    fr: "Filtrer",
    it: "Filtrare",
    sq: "Filtro",
    tr: "Filtrele",
  },
  Favorites: {
    en: "Favorites",
    pt: "Favoritos",
    de: "Favoriten",
    es: "Favoritos",
    fr: "Favoris",
    it: "Preferiti",
    sq: "Preferencat",
    tr: "Favoriler",
  },
  "Free trial": {
    en: "Free trial",
    pt: "Teste gratuito",
    de: "Kostenlose Testversion",
    es: "Prueba gratuita",
    fr: "Essai gratuit",
    it: "Prova gratuita",
    sq: "Prova falas",
    tr: "Ücretsiz deneme",
  },
  "Fill the name": {
    en: "Fill the name",
    pt: "Preencha o nome",
    de: "Name ausfüllen",
    es: "Rellene el nombre",
    fr: "Remplissez le nom",
    it: "Compila il nome",
    sq: "Mbusheni emrin",
    tr: "Adı doldurun",
  },
  "Forgot Your Password": {
    en: "Forgot Your Password",
    pt: "Esqueceu sua senha",
    de: "Passwort vergessen",
    es: "¿Olvidó su contraseña?",
    fr: "Mot de passe oublié",
    it: "Hai dimenticato la password",
    sq: "Keni harruar fjalëkalimin tuaj",
    tr: "Şifrenizi mi unuttunuz?",
  },
  "Free trial expired": {
    en: "Free trial expired",
    pt: "Teste gratuito expirado",
    de: "Kostenlose Testversion abgelaufen",
    es: "Prueba gratuita caducada",
    fr: "Essai gratuit expiré",
    it: "Prova gratuita scaduta",
    sq: "Prova falas ka skaduar",
    tr: "Ücretsiz deneme süresi doldu",
  },
  "Failed to copy text to clipboard": {
    en: "Failed to copy text to clipboard",
    pt: "Falha ao copiar texto para a área de transferência",
    de: "Text konnte nicht in die Zwischenablage kopiert werden",
    es: "Error al copiar texto al portapapeles",
    fr: "Échec de la copie du texte dans le presse-papiers",
    it: "Impossibile copiare il testo negli appunti",
    sq: "Dështoi në kopjimin e tekstit në të dhënat e transferimit",
    tr: "Metin panoya kopyalanamadı",
  },
  "Free trial days": {
    en: "Free trial days",
    pt: "Dias de teste gratuito",
    de: "Tage der kostenlosen Testversion",
    es: "Días de prueba gratuita",
    fr: "Jours d'essai gratuit",
    it: "Giorni di prova gratuita",
    sq: "Ditë prova falas",
    tr: "Ücretsiz deneme günleri",
  },
  "Free trial expired date": {
    en: "Free trial expired date",
    pt: "Data de expiração do período de avaliação gratuita",
    de: "Datum des Ablaufs der kostenlosen Testversion",
    es: "Fecha de vencimiento de la prueba gratuita",
    fr: "Date d'expiration de l'essai gratuit",
    it: "Data di scadenza del periodo di prova gratuito",
    sq: "Data e skadimit të provës së falas",
    tr: "Ücretsiz deneme süresi sona erdi tarihi",
  },
  "Forget your password? No need to worry": {
    en: "Forget your password? No need to worry.",
    pt: "Esqueceu sua senha? Não precisa se preocupar.",
    de: "Passwort vergessen? Keine Sorge.",
    es: "¿Olvidaste tu contraseña? No te preocupes.",
    fr: "Oublié votre mot de passe ? Pas besoin de s'inquiéter.",
    it: "Hai dimenticato la password? Nessun problema.",
    sq: "Keni harruar fjalëkalimin tuaj? S'ka nevojë për shqetësim.",
    tr: "Şifrenizi mi unuttunuz? Endişelenmeyin.",
  },
  "For every 1 activation Expired: Devices whose": {
    en: "For every 1 activation will be charged 1 credit from your account. Expired: Devices whose active 1-year period has been expired and they need to be activated to continue using the TV app.",
    pt: "Para cada ativação, será cobrado 1 crédito da sua conta. Expirado: Dispositivos cujo período ativo de 1 ano expirou e precisam ser ativados para continuar usando o aplicativo de TV.",
    de: "Für jede Aktivierung wird 1 Kredit von Ihrem Konto abgebucht. Abgelaufen: Geräte, deren aktiver 1-Jahres-Zeitraum abgelaufen ist und die aktiviert werden müssen, um die Nutzung der TV-App fortzusetzen.",
    es: "Por cada activación se cobrará 1 crédito de su cuenta. Expirado: Dispositivos cuyo período activo de 1 año ha expirado y necesitan ser activados para seguir utilizando la aplicación de TV.",
    fr: "Pour chaque activation, 1 crédit sera débité de votre compte. Expiré : Dispositifs dont la période active d'un an a expiré et qui doivent être activés pour continuer à utiliser l'application TV.",
    it: "Per ogni attivazione verrà addebitato 1 credito dal tuo account. Scaduti: Dispositivi il cui periodo attivo di 1 anno è scaduto e devono essere attivati per continuare a utilizzare l'app TV.",
    sq: "Për çdo aktivizim do të tarifizohet 1 kredit nga llogaria juaj. Skaduar: Dispositivat të cilët janë skaduar periudha aktive 1-vjeçare dhe ata duhet të aktivizohen për të vazhduar përdorimin e aplikacionit TV.",
    tr: "Her bir aktivasyon için hesabınızdan 1 kredi alınacaktır. Süresi dolmuş: Aktif 1 yıllık süresi dolmuş cihazlar, TV uygulamasını kullanmaya devam etmek için etkinleştirilmesi gerekmektedir.",
  },
  "For every 1 activation will be charged 1 credit from your account": {
    en: "For every 1 activation will be charged 1 credit from your account.",
    pt: "Para cada ativação, será cobrado 1 crédito da sua conta.",
    de: "Für jede Aktivierung wird 1 Kredit von Ihrem Konto abgebucht.",
    es: "Por cada activación se cobrará 1 crédito de su cuenta.",
    fr: "Pour chaque activation, 1 crédit sera débité de votre compte.",
    it: "Per ogni attivazione verrà addebitato 1 credito dal tuo account.",
    sq: "Për çdo aktivizim do të tarifizohet 1 kredit nga llogaria juaj.",
    tr: "Her bir aktivasyon için hesabınızdan 1 kredi alınacaktır.",
  },
  "If enabled, resellers will be able to log in to their devices using your DNS":
    {
      en: "If enabled, resellers will be able to log in to their devices using your DNS.",
      pt: "Se ativado, os revendedores poderão fazer login em seus dispositivos usando seu DNS.",
      de: "Wenn aktiviert, können Wiederverkäufer sich mit ihren Geräten über Ihr DNS anmelden.",
      es: "Si está habilitado, los revendedores podrán iniciar sesión en sus dispositivos utilizando su DNS.",
      fr: "Si activé, les revendeurs pourront se connecter à leurs appareils en utilisant votre DNS.",
      it: "Se abilitato, i rivenditori potranno accedere ai loro dispositivi utilizzando il tuo DNS.",
      sq: "Nëse aktivizohet, shitësit do të jenë në gjendje të kyçen në pajisjet e tyre duke përdorur DNS-in tuaj.",
      tr: "Etkinleştirildiğinde, bayiler cihazlarına sizin DNS'iniz ile giriş yapabilecekler.",
    },
  "is equal to": {
    en: "is equal to",
    pt: "é igual a",
    de: "ist gleich",
    es: "es igual a",
    fr: "est égal à",
    it: "è uguale a",
    sq: "është e barabartë me",
    tr: "eşittir",
  },
  "IP address": {
    en: "IP address",
    pt: "Endereço IP",
    de: "IP-Adresse",
    es: "Dirección IP",
    fr: "Adresse IP",
    it: "Indirizzo IP",
    sq: "Adresa IP",
    tr: "IP adresi",
  },
  ID: {
    en: "ID",
    pt: "ID",
    de: "ID",
    es: "ID",
    fr: "ID",
    it: "ID",
    sq: "ID",
    tr: "ID",
  },
  "Is trial": {
    en: "Is trial",
    pt: "É teste",
    de: "Ist Test",
    es: "Es prueba",
    fr: "Est un essai",
    it: "È prova",
    sq: "Është provë",
    tr: "Denemedir",
  },
  "Is lifetime": {
    en: "Is lifetime",
    pt: "É vitalício",
    de: "Ist lebenslang",
    es: "Es de por vida",
    fr: "Est à vie",
    it: "È a vita",
    sq: "Është përjetësi",
    tr: "Sonsuzdur",
  },
  "Is active": {
    en: "Is active",
    pt: "Está ativo",
    de: "Ist aktiv",
    es: "Está activo",
    fr: "Est actif",
    it: "È attivo",
    sq: "Është aktiv",
    tr: "Aktif mi",
  },
  "Is online": {
    en: "Is online",
    pt: "Está online",
    de: "Ist online",
    es: "Está en línea",
    fr: "Est en ligne",
    it: "È online",
    sq: "Është në linjë",
    tr: "Çevrimi çevrimiçi",
  },
  "Input name": {
    en: "Input name",
    pt: "Nome de entrada",
    de: "Eingabename",
    es: "Nombre de entrada",
    fr: "Nom d'entrée",
    it: "Nome di input",
    sq: "Emri i hyrjes",
    tr: "Girdi adı",
  },
  "Input Host": {
    en: "Input Host",
    pt: "Host de entrada",
    de: "Eingabe-Host",
    es: "Host de entrada",
    fr: "Hôte d'entrée",
    it: "Host di input",
    sq: "Hosti i hyrjes",
    tr: "Girdi Ana Bilgisi",
  },
  "Input API key": {
    en: "Input API key",
    pt: "Chave da API de entrada",
    de: "API-Schlüssel eingeben",
    es: "Clave de API de entrada",
    fr: "Clé API d'entrée",
    it: "Chiave API di input",
    sq: "Çelësi API i hyrjes",
    tr: "API anahtarını girin",
  },
  "Input a number": {
    en: "Input a number",
    pt: "Insira um número",
    de: "Geben Sie eine Zahl ein",
    es: "Ingrese un número",
    fr: "Entrez un numéro",
    it: "Inserisci un numero",
    sq: "Vendos një numër",
    tr: "Bir sayı girin",
  },
  "Input access code": {
    en: "Input access code",
    pt: "Código de acesso de entrada",
    de: "Zugangscode eingeben",
    es: "Código de acceso de entrada",
    fr: "Code d'accès d'entrée",
    it: "Inserisci il codice di accesso",
    sq: "Vendosni kodin e hyrjes",
    tr: "Erişim kodunu girin",
  },
  "Insert the host of your Xtream Playlist": {
    en: "Insert the host of your Xtream Playlist",
    pt: "Insira o host da sua Playlist Xtream",
    de: "Geben Sie den Host Ihrer Xtream-Playlist ein",
    es: "Inserte el host de su lista de reproducción de Xtream",
    fr: "Insérez l'hôte de votre liste de lecture Xtream",
    it: "Inserisci l'host della tua Playlist Xtream",
    sq: "Vendosni hostin e listës së juaj Xtream",
    tr: "Xtream Oynatma Listenizin ana bilgisini girin",
  },
  "In first, create a title and a description for your text": {
    en: "In first, create a title and a description for your text.",
    pt: "Primeiro, crie um título e uma descrição para o seu texto.",
    de: "Erstellen Sie zuerst einen Titel und eine Beschreibung für Ihren Text.",
    es: "En primer lugar, cree un título y una descripción para su texto.",
    fr: "Tout d'abord, créez un titre et une description pour votre texte.",
    it: "Innanzitutto, crea un titolo e una descrizione per il tuo testo.",
    sq: "Fillimisht, krijoni një titull dhe një përshkrim për tekstin tuaj.",
    tr: "Öncelikle, metniniz için bir başlık ve açıklama oluşturun.",
  },
  "It can take from few minutes to hours depending on the device count": {
    en: "It can take from a few minutes to hours depending on the device count.",
    pt: "Pode levar de alguns minutos a horas, dependendo da contagem de dispositivos.",
    de: "Es kann je nach Geräteanzahl einige Minuten bis Stunden dauern.",
    es: "Puede tomar desde unos minutos hasta horas, dependiendo de la cantidad de dispositivos.",
    fr: "Cela peut prendre quelques minutes à quelques heures en fonction du nombre de dispositifs.",
    it: "Ciò può richiedere da pochi minuti a ore, a seconda del numero di dispositivi.",
    sq: "Mund të zgjasë nga disa minuta deri në orë, në varësi të numrit të pajisjeve.",
    tr: "Cihaz sayısına bağlı olarak birkaç dakikadan saate kadar sürebilir.",
  },
  "If you want to activate the device after deactivating it, you must spend 1 credit from your account":
    {
      en: "If you want to activate the device after deactivating it, you must spend 1 credit from your account.",
      pt: "Se desejar ativar o dispositivo após desativá-lo, você deve gastar 1 crédito da sua conta.",
      de: "Wenn Sie das Gerät nach der Deaktivierung aktivieren möchten, müssen Sie 1 Guthaben von Ihrem Konto verwenden.",
      es: "Si desea activar el dispositivo después de desactivarlo, debe gastar 1 crédito de su cuenta.",
      fr: "Si vous souhaitez activer l'appareil après l'avoir désactivé, vous devez dépenser 1 crédit de votre compte.",
      it: "Se desideri attivare il dispositivo dopo averlo disattivato, devi spendere 1 credito dal tuo account.",
      sq: "Nëse dëshironi të aktivizoni pajisjen pasi ta keni çaktivizuar, duhet të shpenzoni 1 kredit nga llogaria juaj.",
      tr: "Cihazı devre dışı bıraktıktan sonra etkinleştirmek istiyorsanız, hesabınızdan 1 kredi harcamanız gerekir.",
    },
  "If this option is enabled, resellers will have the option to automatically register with you as sub resellers. If not, you have to manually accept their requests.":
    {
      en: "If this option is enabled, resellers will have the option to automatically register with you as sub resellers. If not, you have to manually accept their requests.",
      pt: "Se esta opção estiver ativada, os revendedores terão a opção de se registrar automaticamente como sub-revendedores. Caso contrário, você terá que aceitar manualmente as solicitações deles.",
      de: "Wenn diese Option aktiviert ist, haben Wiederverkäufer die Möglichkeit, sich automatisch als Unterverkäufer bei Ihnen zu registrieren. Andernfalls müssen Sie ihre Anfragen manuell akzeptieren.",
      es: "Si esta opción está habilitada, los revendedores tendrán la opción de registrarse automáticamente contigo como subrevendedores. De lo contrario, tendrás que aceptar manualmente sus solicitudes.",
      fr: "Si cette option est activée, les revendeurs auront la possibilité de s'inscrire automatiquement chez vous en tant que sous-revendeurs. Sinon, vous devrez accepter manuellement leurs demandes.",
      it: "Se questa opzione è abilitata, i rivenditori avranno la possibilità di registrarsi automaticamente con te come sub-rivenditori. In caso contrario, dovrai accettare manualmente le loro richieste.",
      sq: "Nëse ky opsion është i aktivizuar, rishitësit do të kenë mundësinë të regjistrohen automatikisht si nën-ri-shitës me ju. Nëse jo, duhet të pranoni manualisht kërkesat e tyre.",
      tr: "Bu seçenek etkinleştirilirse, bayiler alt bayi olarak sizinle otomatik olarak kaydolma seçeneğine sahip olacaktır. Aksi takdirde, taleplerini manuel olarak onaylamanız gerekir.",
    },
  "Incorrect password": {
    en: "Incorrect password",
    pt: "Senha incorreta",
    de: "Falsches Passwort",
    es: "Contraseña incorrecta",
    fr: "Mot de passe incorrect",
    it: "Password errata",
    sq: "Fjalëkalim i pasaktë",
    tr: "Yanlış şifre",
  },
  "If this option is enabled, users will be able to log in to the app. If not, you will have to manually add the playlist to the device.":
    {
      en: "If this option is enabled, users will be able to log in to the app. If not, you will have to manually add the playlist to the device.",
      pt: "Se esta opção estiver ativada, os usuários poderão fazer login no aplicativo. Caso contrário, você terá que adicionar a lista de reprodução manualmente ao dispositivo.",
      de: "Wenn diese Option aktiviert ist, können sich Benutzer in der App anmelden. Andernfalls müssen Sie die Wiedergabeliste manuell zum Gerät hinzufügen.",
      es: "Si esta opción está habilitada, los usuarios podrán iniciar sesión en la aplicación. De lo contrario, deberá agregar la lista de reproducción al dispositivo manualmente.",
      fr: "Si cette option est activée, les utilisateurs pourront se connecter à l'application. Sinon, vous devrez ajouter la liste de lecture manuellement à l'appareil.",
      it: "Se questa opzione è abilitata, gli utenti potranno accedere all'app. In caso contrario, dovrai aggiungere manualmente la playlist al dispositivo.",
      sq: "Nëse ky opsion është i aktivizuar, përdoruesit do të jenë në gjendje të kyçen në aplikacion. Nëse jo, do të duhet ta shtoni manualisht listën e përgjërimeve në pajisjen.",
      tr: "Bu seçenek etkinleştirilirse, kullanıcılar uygulamaya giriş yapabileceklerdir. Aksi takdirde, oynatma listesini cihaza manuel olarak eklemeniz gerekecek.",
    },
  //y
  Yes: {
    en: "Yes",
    pt: "Sim",
    de: "Ja",
    es: "Sí",
    fr: "Oui",
    it: "Sì",
    sq: "Po",
    tr: "Evet",
  },
  "Your guide": {
    en: "Your guide",
    pt: "Seu guia",
    de: "Ihr Leitfaden",
    es: "Tu guía",
    fr: "Votre guide",
    it: "La tua guida",
    sq: "Udhëzuesi juaj",
    tr: "Rehberiniz",
  },
  "Your devices": {
    en: "Your devices",
    pt: "Seus dispositivos",
    de: "Ihre Geräte",
    es: "Tus dispositivos",
    fr: "Vos appareils",
    it: "I tuoi dispositivi",
    sq: "Pajisjet tuaja",
    tr: "Cihazlarınız",
  },
  "You cannot use branding": {
    en: "You cannot use branding!",
    pt: "Você não pode usar marca!",
    de: "Sie können keine Marken verwenden!",
    es: "¡No puedes usar la marca!",
    fr: "Vous ne pouvez pas utiliser de marque !",
    it: "Non puoi usare il marchio!",
    sq: "Nuk mund të përdorni markën!",
    tr: "Marka kullanamazsınız!",
  },
  "You will get email to email": {
    en: "You will get email to email",
    pt: "Você receberá um e-mail para o e-mail",
    de: "Sie erhalten eine E-Mail an die E-Mail-Adresse",
    es: "Recibirás un correo electrónico a tu correo electrónico",
    fr: "Vous recevrez un e-mail à l'adresse e-mail",
    it: "Riceverai un'e-mail all'indirizzo e-mail",
    sq: "Do të merrni email në email",
    tr: "E-posta almak için e-posta alacaksınız",
  },
  "Your account has been blocked": {
    en: "Your account has been blocked!",
    pt: "Sua conta foi bloqueada!",
    de: "Ihr Konto wurde gesperrt!",
    es: "Tu cuenta ha sido bloqueada!",
    fr: "Votre compte a été bloqué !",
    it: "Il tuo account è stato bloccato!",
    sq: "Llogaria juaj është bllokuar!",
    tr: "Hesabınız engellendi!",
  },

  "Your old password and new password are same": {
    en: "Your old password and new password are the same",
    pt: "Sua senha antiga e nova são iguais",
    de: "Ihr altes Passwort und Ihr neues Passwort sind identisch",
    es: "Tu antigua contraseña y nueva contraseña son iguales",
    fr: "Votre ancien mot de passe et votre nouveau mot de passe sont identiques",
    it: "La tua vecchia password e la nuova password sono uguali",
    sq: "Fjalëkalimi i vjetër dhe i ri janë të njëjtë",
    tr: "Eski şifreniz ve yeni şifreniz aynıdır",
  },
  "Your playlist will be autogenerated": {
    en: "Your playlist will be autogenerated",
    pt: "Sua lista de reprodução será gerada automaticamente",
    de: "Ihre Wiedergabeliste wird automatisch generiert",
    es: "Su lista de reproducción se generará automáticamente",
    fr: "Votre playlist sera générée automatiquement",
    it: "La tua playlist verrà generata automaticamente",
    sq: "Lista juaj e përgjegjësive do të gjenerohet automatikisht",
    tr: "Oynatma listeniz otomatik olarak oluşturulacaktır",
  },
  "Your administrator restricted your permissions": {
    en: "Your administrator restricted your permissions",
    pt: "Seu administrador restringiu suas permissões",
    de: "Ihr Administrator hat Ihre Berechtigungen eingeschränkt",
    es: "Su administrador restringió sus permisos",
    fr: "Votre administrateur a restreint vos permissions",
    it: "Il tuo amministratore ha limitato le tue autorizzazioni",
    sq: "Administruesi juaj ka kufizuar lejet tuaja",
    tr: "Yönetici, izinlerinizi kısıtladı",
  },

  "You can use this tool to send notifications to your users": {
    en: "You can use this tool to send notifications to your users.",
    pt: "Você pode usar esta ferramenta para enviar notificações aos seus usuários.",
    de: "Sie können dieses Tool verwenden, um Benachrichtigungen an Ihre Benutzer zu senden.",
    es: "Puede utilizar esta herramienta para enviar notificaciones a sus usuarios.",
    fr: "Vous pouvez utiliser cet outil pour envoyer des notifications à vos utilisateurs.",
    it: "Puoi utilizzare questo strumento per inviare notifiche ai tuoi utenti.",
    sq: "Mund të përdorni këtë mjet për të dërguar njoftime tek përdoruesit tuaj.",
    tr: "Bu aracı kullanıcılarınıza bildirim göndermek için kullanabilirsiniz.",
  },
  "You have to wait until migration will be ended successfully": {
    en: "You have to wait until migration will be ended successfully.",
    pt: "Você precisa esperar até que a migração seja concluída com sucesso.",
    de: "Sie müssen warten, bis die Migration erfolgreich abgeschlossen ist.",
    es: "Debe esperar hasta que la migración se haya completado con éxito.",
    fr: "Vous devez attendre que la migration se termine avec succès.",
    it: "Devi aspettare che la migrazione sia completata con successo.",
    sq: "Duhet të prisni derisa migrimi të përfundojë me sukses.",
    tr: "Göç başarılı bir şekilde tamamlanana kadar beklemelisiniz.",
  },
  "You can set up devices to be activated at once, after logging in": {
    en: "You can set up devices to be activated at once, after logging in.",
    pt: "Você pode configurar dispositivos para serem ativados imediatamente após o login.",
    de: "Sie können Geräte so einrichten, dass sie sofort nach dem Einloggen aktiviert werden.",
    es: "Puede configurar dispositivos para que se activen al instante después de iniciar sesión.",
    fr: "Vous pouvez configurer des appareils pour être activés immédiatement après la connexion.",
    it: "Puoi configurare i dispositivi per essere attivati immediatamente dopo il login.",
    sq: "Mund të konfiguroni pajisjet që të aktivizohen menjëherë, pas hyrjes në llogari.",
    tr: "Giriş yaptıktan sonra birden fazla cihazı etkinleştirmek için ayarlayabilirsiniz.",
  },
  "You must become a Partner in order to use the functionality on this page": {
    en: "You must become a Partner in order to use the functionality on this page.",
    pt: "Você deve se tornar um Parceiro para usar a funcionalidade nesta página.",
    de: "Sie müssen Partner werden, um die Funktion auf dieser Seite nutzen zu können.",
    es: "Debe convertirse en un Socio para poder utilizar la funcionalidad de esta página.",
    fr: "Vous devez devenir un Partenaire pour utiliser les fonctionnalités de cette page.",
    it: "Devi diventare un Partner per utilizzare le funzionalità di questa pagina.",
    sq: "Duhet të bëheni Partner për të përdorur funksionalitetin në këtë faqe.",
    tr: "Bu sayfadaki işlevselliği kullanmak için Partner olmalısınız.",
  },
  "You can give Free trial to your users. Free trial can be between 1 and 7 days":
    {
      en: "You can give Free trial to your users. Free trial can be between 1 and 7 days.",
      pt: "Você pode oferecer um teste gratuito aos seus usuários. O teste gratuito pode ter duração de 1 a 7 dias.",
      de: "Sie können Ihren Benutzern eine kostenlose Testversion anbieten. Die kostenlose Testversion kann zwischen 1 und 7 Tagen liegen.",
      es: "Puede ofrecer una prueba gratuita a sus usuarios. La prueba gratuita puede ser de 1 a 7 días.",
      fr: "Vous pouvez offrir un essai gratuit à vos utilisateurs. L'essai gratuit peut durer de 1 à 7 jours.",
      it: "Puoi offrire una prova gratuita ai tuoi utenti. La prova gratuita può durare da 1 a 7 giorni.",
      sq: "Ju mund të jepni provë falas për përdoruesit tuaj. Prova falas mund të jetë nga 1 deri në 7 ditë.",
      tr: "Kullanıcılarınıza ücretsiz deneme verebilirsiniz. Ücretsiz deneme süresi 1 ila 7 gün arasında olabilir.",
    },
  "You will get email message about the results of the migration in the end of the process":
    {
      en: "You will get email message about the results of the migration in the end of the process.",
      pt: "Você receberá uma mensagem de e-mail sobre os resultados da migração no final do processo.",
      de: "Sie erhalten eine E-Mail-Nachricht über die Ergebnisse der Migration am Ende des Prozesses.",
      es: "Recibirá un mensaje de correo electrónico sobre los resultados de la migración al final del proceso.",
      fr: "Vous recevrez un message électronique concernant les résultats de la migration à la fin du processus.",
      it: "Riceverai un messaggio di posta elettronica sui risultati della migrazione alla fine del processo.",
      sq: "Ju do të merrni një mesazh emaili për rezultatet e migrimit në fund të procesit.",
      tr: "Göç sonuçları hakkında bir e-posta mesajı alacaksınız, işlemin sonunda.",
    },
  "You can choose: Give the device free trial": {
    en: "You can choose: Give the device free trial or activate without a free trial. Free trial can be from 1 to 7 days.",
    pt: "Você pode escolher: Dar ao dispositivo um teste gratuito ou ativá-lo sem um teste gratuito. O teste gratuito pode ser de 1 a 7 dias.",
    de: "Sie können wählen: Geben Sie dem Gerät eine kostenlose Testversion oder aktivieren Sie es ohne kostenlose Testversion. Die kostenlose Testversion kann von 1 bis 7 Tagen dauern.",
    es: "Puede elegir: Dar al dispositivo una prueba gratuita o activarlo sin una prueba gratuita. La prueba gratuita puede ser de 1 a 7 días.",
    fr: "Vous pouvez choisir : Donner un essai gratuit à l'appareil ou l'activer sans essai gratuit. L'essai gratuit peut durer de 1 à 7 jours.",
    it: "Puoi scegliere: Dare al dispositivo una prova gratuita o attivarlo senza una prova gratuita. La prova gratuita può durare da 1 a 7 giorni.",
    sq: "Ju mund të zgjidhni: Dhënia e provës falas të pajisjes ose aktivizimi pa një provë falas. Prova falas mund të jetë nga 1 deri në 7 ditë.",
    tr: "Şunu seçebilirsiniz: Cihaza ücretsiz deneme verin veya ücretsiz deneme olmadan aktive edin. Ücretsiz deneme süresi 1 ila 7 gün arasında olabilir.",
  },
  "You can click on the checkbox and let our system auto renew your device activation after one year from activation date.":
    {
      en: "You can click on the checkbox and let our system auto renew your device activation after one year from activation date.",
      pt: "Você pode clicar na caixa de seleção e permitir que nosso sistema renove automaticamente a ativação do seu dispositivo após um ano a partir da data de ativação.",
      de: "Sie können auf das Kontrollkästchen klicken und unser System automatisch die Aktivierung Ihres Geräts nach einem Jahr ab dem Aktivierungsdatum erneuern lassen.",
      es: "Puede hacer clic en la casilla de verificación e permitir que nuestro sistema renueve automáticamente la activación de su dispositivo después de un año desde la fecha de activación.",
      fr: "Vous pouvez cliquer sur la case à cocher et laisser notre système renouveler automatiquement l'activation de votre appareil après un an à compter de la date d'activation.",
      it: "Puoi fare clic sulla casella di controllo e permettere al nostro sistema di rinnovare automaticamente l'attivazione del tuo dispositivo dopo un anno dalla data di attivazione.",
      sq: "Ju mund të klikoni në kutinë e zgjedhjes dhe lejoni sistemin tonë të riaftësojë automatikisht aktivizimin e pajisjes suaj pas një viti nga data e aktivizimit.",
      tr: "Onay kutusuna tıklayabilir ve sistemimizin cihazınızın aktivasyonunu, aktivasyon tarihinden bir yıl sonra otomatik olarak yenilemesine izin verebilirsiniz.",
    },
  "You can click on checkbox and let our system": {
    en: "You can click on the checkbox and let our system",
    pt: "Você pode clicar na caixa de seleção e deixar nosso sistema",
    de: "Sie können das Kontrollkästchen aktivieren und unser System",
    es: "Puede hacer clic en la casilla de verificación y dejar que nuestro sistema",
    fr: "Vous pouvez cliquer sur la case à cocher et laisser notre système",
    it: "Puoi fare clic sulla casella di controllo e lasciare che il nostro sistema",
    sq: "Mund të klikoni në kutinë e zgjedhjes dhe lejoni sistemin tonë",
    tr: "Onay kutusuna tıklayabilir ve sistemimizin",
  },
  "You can make setup for the activation of new devices in this section": {
    en: "You can make setup for the activation of new devices in this section. If you activate the checkbox of 'Allow users to login from application,' your users can be auto-activated and login to TV by themselves.",
    pt: "Você pode configurar a ativação de novos dispositivos nesta seção. Se você ativar a caixa de seleção 'Permitir que os usuários façam login pelo aplicativo', seus usuários podem ser ativados automaticamente e fazer login na TV por conta própria.",
    de: "Sie können in diesem Abschnitt die Aktivierung neuer Geräte einrichten. Wenn Sie das Kontrollkästchen 'Benutzern das Anmelden von der Anwendung erlauben' aktivieren, können Ihre Benutzer automatisch aktiviert und sich selbst in TV einloggen.",
    es: "Puede configurar la activación de nuevos dispositivos en esta sección. Si activa la casilla 'Permitir que los usuarios inicien sesión desde la aplicación', sus usuarios pueden ser activados automáticamente e iniciar sesión en la TV por sí mismos.",
    fr: "Vous pouvez configurer l'activation de nouveaux appareils dans cette section. Si vous activez la case à cocher 'Autoriser les utilisateurs à se connecter depuis l'application', vos utilisateurs peuvent être activés automatiquement et se connecter à la TV par eux-mêmes.",
    it: "Puoi configurare l'attivazione di nuovi dispositivi in questa sezione. Se attivi la casella di controllo 'Consenti agli utenti di effettuare l'accesso dall'applicazione', i tuoi utenti possono essere attivati automaticamente e accedere alla TV da soli.",
    sq: "Ju mund të bëni konfigurimin për aktivizimin e pajisjeve të reja në këtë seksion. Nëse aktivizoni kutinë e zgjedhjes 'Lejo përdoruesit të kyçen nga aplikacioni,' përdoruesit tuaj mund të aktivizohen automatikisht dhe të kyçen në televizor vetë.",
    tr: "Bu bölümde yeni cihazların aktivasyonu için ayarlama yapabilirsiniz. 'Kullanıcıların uygulamadan giriş yapmasına izin ver' onay kutusunu etkinleştirirseniz, kullanıcılarınız otomatik olarak etkinleştirilebilir ve televizyona kendileri giriş yapabilirler.",
  },
  "Your account is not approved yet by your admin. Contact your admin to get approved.":
    {
      en: "Your account is not approved yet by your admin. Contact your admin to get approved.",
      pt: "Sua conta ainda não foi aprovada pelo seu administrador. Entre em contato com seu administrador para ser aprovado.",
      de: "Ihr Konto wurde noch nicht von Ihrem Administrator genehmigt. Kontaktieren Sie Ihren Administrator, um genehmigt zu werden.",
      es: "Tu cuenta aún no ha sido aprobada por tu administrador. Contacta a tu administrador para ser aprobado.",
      fr: "Votre compte n'a pas encore été approuvé par votre administrateur. Contactez votre administrateur pour être approuvé.",
      it: "Il tuo account non è ancora stato approvato dal tuo amministratore. Contatta il tuo amministratore per essere approvato.",
      sq: "Llogaria juaj ende nuk është aprovuar nga administratori juaj. Kontaktoni administratorin tuaj për të qenë i aprovuar.",
      tr: "Hesabınız henüz yöneticiniz tarafından onaylanmamıştır. Onay almak için yöneticinizle iletişime geçin.",
    },
  "You can't select yourself": {
    en: "You can't select yourself",
    pt: "Você não pode se selecionar",
    de: "Du kannst dich nicht selbst auswählen",
    es: "No puedes seleccionarte a ti mismo",
    fr: "Vous ne pouvez pas vous sélectionner vous-même",
    it: "Non puoi selezionarti da solo",
    sq: "Nuk mund të zgjidhni veten",
    tr: "Kendinizi seçemezsiniz",
  },
  "You can sync playlist by clicking the 'Sync' button or wait for the device to be logged in, after which it will sync automatically.":
    {
      en: "You can sync playlist by clicking the 'Sync' button or wait for the device to be logged in, after which it will sync automatically.",
      pt: "Você pode sincronizar a playlist clicando no botão 'Sincronizar' ou aguardar o dispositivo fazer login, após o qual ele será sincronizado automaticamente.",
      de: "Sie können die Playlist synchronisieren, indem Sie auf die Schaltfläche 'Synchronisieren' klicken oder darauf warten, dass das Gerät angemeldet ist, woraufhin es automatisch synchronisiert wird.",
      es: "Puede sincronizar la lista de reproducción haciendo clic en el botón 'Sincronizar' o esperar a que el dispositivo inicie sesión, después de lo cual se sincronizará automáticamente.",
      fr: "Vous pouvez synchroniser la playlist en cliquant sur le bouton 'Synchroniser' ou attendre que le périphérique se connecte, après quoi il se synchronisera automatiquement.",
      it: "Puoi sincronizzare la playlist cliccando sul pulsante 'Sincronizza' o aspettare che il dispositivo effettui l'accesso, dopodiché si sincronizzerà automaticamente.",
      sq: "Mund të sinkronizoni listën e përgjegjësive duke klikuar në butonin 'Sinkronizo' ose prisni që pajisja të hyjë në sistem, pas së cilës do të sinkronizohet automatikisht.",
      tr: "Oynatma listesini 'Senkrone' düğmesine tıklayarak senkronize edebilir veya cihazın oturum açmasını bekleyebilirsiniz; ardından otomatik olarak senkronize olacaktır.",
    },
  //o
  "Open for devices": {
    en: "Open for devices",
    pt: "Aberto para dispositivos",
    de: "Offen für Geräte",
    es: "Abierto para dispositivos",
    fr: "Ouvert pour les appareils",
    it: "Aperto per dispositivi",
    sq: "I hapur për pajisjet",
    tr: "Cihazlar için açık",
  },
  or: {
    en: "or",
    pt: "ou",
    de: "oder",
    es: "o",
    fr: "ou",
    it: "o",
    sq: "ose",
    tr: "veya",
  },
  Os: {
    en: "Os",
    pt: "Sistema Operacional",
    de: "Betriebssystem",
    es: "Sistema Operativo",
    fr: "Système d'exploitation",
    it: "Sistema Operativo",
    sq: "Sistemi Operativ",
    tr: "İşletim Sistemi",
  },
  Online: {
    en: "Online",
    pt: "Online",
    de: "Online",
    es: "En línea",
    fr: "En ligne",
    it: "Online",
    sq: "Online",
    tr: "Çevrimiçi",
  },
  "Old password": {
    en: "Old password",
    pt: "Senha antiga",
    de: "Altes Passwort",
    es: "Contraseña antigua",
    fr: "Ancien mot de passe",
    it: "Vecchia password",
    sq: "Fjalëkalimi i vjetër",
    tr: "Eski şifre",
  },
  "OTP Config": {
    en: "OTP Config",
    pt: "Configuração de OTP",
    de: "OTP-Konfiguration",
    es: "Configuración de OTP",
    fr: "Configuration OTP",
    it: "Configurazione OTP",
    sq: "Konfigurimi OTP",
    tr: "OTP Yapılandırması",
  },

  "On the 'Reset Requests' page, there are reset requests that are pending. To make use of this functionality, please reset or cancel them":
    {
      en: "On the 'Reset Requests' page, there are reset requests that are pending. To make use of this functionality, please reset or cancel them.",
      pt: "Na página 'Solicitações de Redefinição', há solicitações de redefinição pendentes. Para utilizar esta funcionalidade, por favor redefina ou cancele-as.",
      de: "Auf der Seite 'Zurücksetzungsanfragen' gibt es ausstehende Anfragen. Um diese Funktionalität zu nutzen, setzen Sie sie bitte zurück oder stornieren Sie sie.",
      es: "En la página 'Solicitudes de Restablecimiento', hay solicitudes de restablecimiento pendientes. Para utilizar esta funcionalidad, por favor restablézcalas o cancélelas.",
      fr: "Sur la page 'Demandes de réinitialisation', il y a des demandes en attente. Pour utiliser cette fonctionnalité, veuillez les réinitialiser ou les annuler.",
      it: "Nella pagina 'Richieste di Ripristino', ci sono richieste di ripristino in sospeso. Per utilizzare questa funzionalità, ripristina o annulla tali richieste.",
      sq: "Në faqen 'Kërkesat për Rivendosje', ka kërkesa të pa përfunduara. Për të përdorur këtë funksionalitet, ju lutemi rivendosni ose anuloni ato.",
      tr: "'Sıfırlama Talepleri' sayfasında bekleyen sıfırlama talepleri var. Bu işlevi kullanmak için lütfen talepleri sıfırlayın veya iptal edin.",
    },

  // x
  xui: {
    en: "xui",
    pt: "xui",
    de: "xui",
    es: "xui",
    fr: "xui",
    it: "xui",
    sq: "xui",
    tr: "xui",
  },
  "X Cloud Admin Panel": {
    en: "X Cloud Admin Panel",
    pt: "Painel de Administração XCloud",
    de: "X Cloud Admin-Panel",
    es: "Panel de Administración de X Cloud",
    fr: "Panneau d'administration X Cloud",
    it: "Pannello di amministrazione di X Cloud",
    sq: "Paneli i Administratorit të X Cloud",
    tr: "X Cloud Yönetim Paneli",
  },
  //v
  "Videos must be less than ten": {
    en: "Videos must be less than ten",
    es: "Los videos deben ser menos de diez",
    fr: "Les vidéos doivent être inférieures à dix",
    de: "Videos müssen weniger als zehn sein",
    pt: "Os vídeos devem ser menos de dez",
    it: "I video devono essere meno di dieci",
    sq: "Videot duhet të jenë më pak se dhjetë",
    tr: "Videolar ondan az olmalıdır",
  },
  "View more": {
    en: "View more",
    pt: "Ver mais",
    de: "Mehr anzeigen",
    es: "Ver más",
    fr: "Voir plus",
    it: "Visualizza altro",
    sq: "Shiko më shumë",
    tr: "Daha fazla görüntüle",
  },
  Video: {
    en: "Video",
    es: "Vídeo",
    fr: "Vidéo",
    de: "Video",
    pt: "Vídeo",
    it: "Video",
    sq: "Video",
    tr: "Video",
  },
  "View the guide created by images": {
    en: "View the guide created by images",
    pt: "Visualize o guia criado por imagens",
    de: "Ansehen des von Bildern erstellten Leitfadens",
    es: "Ver la guía creada por imágenes",
    fr: "Voir le guide créé par des images",
    it: "Visualizza la guida creata dalle immagini",
    sq: "Shiko udhëzuesin e krijuar nga imazhet",
    tr: "Görüntülerle oluşturulan kılavuzu görüntüle",
  },
  "Verify code must be 6 characters": {
    en: "Verify code must be 6 characters",
    pt: "O código de verificação deve ter 6 caracteres",
    de: "Der Überprüfungscode muss 6 Zeichen lang sein",
    es: "El código de verificación debe tener 6 caracteres",
    fr: "Le code de vérification doit comporter 6 caractères",
    it: "Il codice di verifica deve essere lungo 6 caratteri",
    sq: "Kodi i verifikimit duhet të jetë 6 karaktere",
    tr: "Doğrulama kodu 6 karakter olmalıdır",
  },
  //u
  "Unsupported file type": {
    en: "Unsupported file type",
    es: "Tipo de archivo no compatible",
    fr: "Type de fichier non pris en charge",
    de: "Nicht unterstützter Dateityp",
    pt: "Tipo de arquivo não suportado",
    it: "Tipo di file non supportato",
    sq: "Lloji i skedarit i pambështetur",
    tr: "Desteklenmeyen dosya türü",
  },
  Upload: {
    en: "Upload",
    es: "Subir",
    fr: "Téléverser",
    de: "Hochladen",
    pt: "Carregar",
    it: "Carica",
    sq: "Ngarko",
    tr: "Yükle",
  },
  "Upload image": {
    en: "Upload image",
    es: "Subir imagen",
    fr: "Télécharger l'image",
    de: "Bild hochladen",
    pt: "Carregar imagem",
    it: "Carica immagine",
    sq: "Ngarko imazh",
    tr: "Görüntü yükle",
  },
  "Upload video": {
    en: "Upload video",
    es: "Subir video",
    fr: "Télécharger la vidéo",
    de: "Video hochladen",
    pt: "Carregar vídeo",
    it: "Carica video",
    sq: "Ngarko video",
    tr: "Video yükle",
  },
  UUID: {
    en: "UUID",
    pt: "UUID",
    de: "UUID",
    es: "UUID",
    fr: "UUID",
    it: "UUID",
    sq: "UUID",
    tr: "UUID",
  },
  User: {
    en: "User",
    pt: "Usuário",
    de: "Benutzer",
    es: "Usuario",
    fr: "Utilisateur",
    it: "Utente",
    sq: "Përdoruesi",
    tr: "Kullanıcı",
  },
  Unblock: {
    en: "Unblock",
    pt: "Desbloquear",
    de: "Entsperren",
    es: "Desbloquear",
    fr: "Débloquer",
    it: "Sblocca",
    sq: "Çaktivizo bllokimin",
    tr: "Engeli kaldır",
  },
  Upload: {
    en: "Upload",
    pt: "Carregar",
    de: "Hochladen",
    es: "Cargar",
    fr: "Télécharger",
    it: "Carica",
    sq: "Ngarko",
    tr: "Yükle",
  },
  "Updated date": {
    en: "Updated date",
    pt: "Data atualizada",
    de: "Aktualisiertes Datum",
    es: "Fecha actualizada",
    fr: "Date mise à jour",
    it: "Data aggiornata",
    sq: "Data e përditësimit",
    tr: "Güncellenme tarihi",
  },
  Username: {
    en: "Username",
    pt: "Nome de usuário",
    de: "Benutzername",
    es: "Nombre de usuario",
    fr: "Nom d'utilisateur",
    it: "Nome utente",
    sq: "Emri i përdoruesit",
    tr: "Kullanıcı adı",
  },
  "Upload Logo": {
    en: "Upload Logo",
    pt: "Carregar Logotipo",
    de: "Logo hochladen",
    es: "Cargar logotipo",
    fr: "Télécharger le logo",
    it: "Carica logo",
    sq: "Ngarko logon",
    tr: "Logo yükle",
  },
  "Upload Background": {
    en: "Upload Background",
    pt: "Carregar Plano de Fundo",
    de: "Hintergrund hochladen",
    es: "Cargar fondo",
    fr: "Télécharger l'arrière-plan",
    it: "Carica sfondo",
    sq: "Ngarko sfondin",
    tr: "Arka plan yükle",
  },
  "Users will be able to reset the device automatically if this field is enabled. If it isn't, they won't be able to reset it unless you confirm it from the Devices page / Reset requests":
    {
      en: "Users will be able to reset the device automatically if this field is enabled. If it isn't, they won't be able to reset it unless you confirm it from the Devices page / Reset requests.",
      pt: "Os usuários poderão redefinir o dispositivo automaticamente se este campo estiver habilitado. Caso contrário, eles não poderão redefini-lo a menos que você o confirme na página Dispositivos / Solicitações de redefinição.",
      de: "Benutzer können das Gerät automatisch zurücksetzen, wenn dieses Feld aktiviert ist. Andernfalls können sie es nicht zurücksetzen, es sei denn, Sie bestätigen es auf der Seite Geräte / Zurücksetzungsanfragen.",
      es: "Los usuarios podrán restablecer el dispositivo automáticamente si este campo está habilitado. De lo contrario, no podrán restablecerlo a menos que lo confirme desde la página de Dispositivos / Solicitudes de restablecimiento.",
      fr: "Les utilisateurs pourront réinitialiser le périphérique automatiquement si ce champ est activé. Sinon, ils ne pourront pas le réinitialiser à moins que vous ne le confirmiez depuis la page Appareils / Demandes de réinitialisation.",
      it: "Gli utenti potranno ripristinare automaticamente il dispositivo se questo campo è abilitato. In caso contrario, non potranno ripristinarlo a meno che tu non lo confermi dalla pagina Dispositivi / Richieste di ripristino.",
      sq: "Përdoruesit do të jenë në gjendje të rivendosin automatikisht pajisjen nëse ky fushë është e aktivizuar. Nëse nuk është, ata nuk do të jenë në gjendje ta rivendosin atë përveç nëse ju e konfirmoni atë nga faqja e Pajisjeve / Kërkesat për rivendosje.",
      tr: "Kullanıcılar, bu alan etkinse cihazı otomatik olarak sıfırlayabileceklerdir. Değilse, cihazı sıfırlayamazlar, cihazı Sıfırlama talepleri / Cihazlar sayfasından onaylamadığınız sürece.",
    },
  "Use port": {
    en: "Use port",
    pt: "Usar porta",
    de: "Port verwenden",
    es: "Usar puerto",
    fr: "Utiliser le port",
    it: "Usa porta",
    sq: "Përdorni portin",
    tr: "Portu kullan",
  },
  //v
  Visibly: {
    en: "Visibly",
    pt: "Visivelmente",
    de: "Sichtbar",
    es: "Visiblemente",
    fr: "Visiblement",
    it: "Visibilmente",
    sq: "Vizualisht",
    tr: "Görünür şekilde",
  },
  Viewed: {
    en: "Viewed",
    pt: "Visualizado",
    de: "Angesehen",
    es: "Visto",
    fr: "Vu",
    it: "Visualizzato",
    sq: "Shikuar",
    tr: "Görüntülendi",
  },
  Version: {
    en: "Version",
    pt: "Versão",
    de: "Version",
    es: "Versión",
    fr: "Version",
    it: "Versione",
    sq: "Versioni",
    tr: "Sürüm",
  },
  //w
  WARNING: {
    en: "WARNING",
    pt: "AVISO",
    de: "WARNUNG",
    es: "ADVERTENCIA",
    fr: "AVERTISSEMENT",
    it: "AVVERTENZA",
    sq: "KUJDES",
    tr: "UYARI",
  },
  "Welcome to the": {
    en: "Welcome to the",
    pt: "Bem-vindo ao",
    de: "Willkommen bei",
    es: "Bienvenido a",
    fr: "Bienvenue sur",
    it: "Benvenuti su",
    sq: "Mirë se vini në",
    tr: "Hoş geldiniz",
  },
  welcome: {
    en: "Welcome",
    pt: "Bem-vindo",
    de: "Willkommen",
    es: "Bienvenido",
    fr: "Bienvenue",
    it: "Benvenuto",
    sq: "Mirë se vini",
    tr: "Hoş geldiniz",
  },
  "when migration will be finished": {
    en: "when migration will be finished!",
    pt: "quando a migração estiver concluída!",
    de: "wenn die Migration abgeschlossen ist!",
    es: "cuando la migración haya terminado!",
    fr: "quand la migration sera terminée !",
    it: "quando la migrazione sarà completata!",
    sq: "kur migrimi do të përfundojë!",
    tr: "göç tamamlandığında!",
  },
  "With save button you can save your changes": {
    en: "With save button you can save your changes.",
    pt: "Com o botão de salvar, você pode salvar suas alterações.",
    de: "Mit der Speichertaste können Sie Ihre Änderungen speichern.",
    es: "Con el botón de guardar, puede guardar sus cambios.",
    fr: "Avec le bouton de sauvegarde, vous pouvez enregistrer vos modifications.",
    it: "Con il pulsante Salva puoi salvare le tue modifiche.",
    sq: "Me butonin ruaj, mund të ruani ndryshimet tuaja.",
    tr: "Kaydet butonuyla değişikliklerinizi kaydedebilirsiniz.",
  },
  "With save button you can save your changes and the new device will be added successfully":
    {
      en: "With save button you can save your changes and the new device will be added successfully.",
      pt: "Com o botão de salvar, você pode salvar suas alterações e o novo dispositivo será adicionado com sucesso.",
      de: "Mit der Speichertaste können Sie Ihre Änderungen speichern, und das neue Gerät wird erfolgreich hinzugefügt.",
      es: "Con el botón de guardar, puede guardar sus cambios y el nuevo dispositivo se agregará con éxito.",
      fr: "Avec le bouton de sauvegarde, vous pouvez enregistrer vos modifications et le nouveau dispositif sera ajouté avec succès.",
      it: "Con il pulsante Salva puoi salvare le tue modifiche e il nuovo dispositivo verrà aggiunto con successo.",
      sq: "Me butonin ruaj, mund të ruani ndryshimet tuaja dhe pajisja e re do të shtohet me sukses.",
      tr: "Kaydet butonuyla değişikliklerinizi kaydedebilir ve yeni cihaz başarıyla eklenecektir.",
    },
  "We have sent a verification code to your provided email. Please verify the confirmation code you have received in your email.":
    {
      en: "We have sent a verification code to your provided email. Please verify the confirmation code you have received in your email.",
      pt: "Enviamos um código de verificação para o seu e-mail fornecido. Por favor, verifique o código de confirmação que você recebeu em seu e-mail.",
      de: "Wir haben einen Bestätigungscode an Ihre angegebene E-Mail-Adresse gesendet. Bitte überprüfen Sie den Bestätigungscode, den Sie in Ihrer E-Mail erhalten haben.",
      es: "Hemos enviado un código de verificación a su correo electrónico proporcionado. Por favor, verifique el código de confirmación que ha recibido en su correo electrónico.",
      fr: "Nous avons envoyé un code de vérification à votre adresse e-mail fournie. Veuillez vérifier le code de confirmation que vous avez reçu dans votre e-mail.",
      it: "Abbiamo inviato un codice di verifica alla tua email fornita. Verifica il codice di conferma che hai ricevuto nella tua email.",
      sq: "Kemi dërguar një kod verifikimi në emailin tuaj të dhënë. Ju lutemi verifikoni kodin e konfirmimit që keni marrë në emailin tuaj.",
      tr: "Sağladığınız e-posta adresinize bir doğrulama kodu gönderdik. Lütfen e-posta kutunuzda aldığınız onay kodunu doğrulayın.",
    },
  "We have sent a verification code to your email.": {
    en: "We have sent a verification code to your email.",
    pt: "Enviamos um código de verificação para o seu e-mail.",
    de: "Wir haben einen Bestätigungscode an Ihre E-Mail gesendet.",
    es: "Hemos enviado un código de verificación a su correo electrónico.",
    fr: "Nous avons envoyé un code de vérification à votre adresse e-mail.",
    it: "Abbiamo inviato un codice di verifica alla tua email.",
    sq: "Ju kemi dërguar një kod verifikimi në email-in tuaj.",
    tr: "E-posta adresinize bir doğrulama kodu gönderdik.",
  },
  "Wrong password": {
    en: "Wrong password",
    pt: "Senha incorreta",
    de: "Falsches Passwort",
    es: "Contraseña incorrecta",
    fr: "Mot de passe incorrect",
    it: "Password errata",
    sq: "Fjalëkalim i gabuar",
    tr: "Yanlış şifre",
  },
  Week: {
    en: "Week",
    es: "Semana",
    fr: "Semaine",
    de: "Woche",
    pt: "Semana",
    it: "Settimana",
    sq: "Java",
    tr: "Hafta",
  },
};

export default translations;
