import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import moment from "moment";
import { DatePicker, Empty } from "antd";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import REQUESTS from "../../api/requests";
import useTranslate from "../../hooks/translator";
import { ICONS } from "../../config";

export default function MoviesStatisticsChart() {

  const { profile } = useSelector((state) => state.profile);

  const translate = useTranslate();

  const [chartData, setChartData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const onChangeMonth = (date, dateString) => {
    if (date) {
      setSelectedMonth(new Date(date._d).getMonth());
      return selectedMonth;
    } else {
      setSelectedMonth(null);
      return selectedMonth;
    }
  };

  const onChangeYear = (date, dateString) => {
    if (date) {
      setSelectedYear(new Date(date._d).getFullYear());
      return selectedYear;
    } else {
      setSelectedYear(new Date().getFullYear());
      return selectedYear;
    }
  };

  const getMoviesStatisticsChart = () => {
    setChartData([]);
    const query = {
      type: "movie",
      year: selectedYear,
      month: selectedMonth,
    };

    REQUESTS.MOVIES_CHART(query)
    .then((data) => {
      const newData = data?.map((data) => ({
          viewed: data.viewed,
          name: data.name,
        }))
        .reverse();
      setChartData(newData);
    })
    .catch((err)=>console.log(err))
  };

  useEffect(() => {
    getMoviesStatisticsChart();
  }, [selectedMonth, selectedYear, profile]);


  return (
    <div className="movies-statistics-chart">
      <div className="movies-statistics-chart__top">
        <h3 style={{ marginBottom: 20 }}>{translate("Top Movies")}</h3>
        <div className="datepicker">
          <>
            <DatePicker
              onChange={onChangeYear}
              value={moment(selectedYear, "YYYY")}
              picker={"year"}
              format={"YYYY"}
              placeholder="Select year"
            />

            <DatePicker
              onChange={onChangeMonth}
              defaultValue={moment().month(selectedMonth)}
              style={{ marginLeft: "20px" }}
              picker={"month"}
              format={"MMM"}
              placeholder="Select month"
              popupClassName="hide-pannel"
              allowClear={false}
            />
          </>
        </div>
      </div>
      {chartData?.length > 0 ? (
        <>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              layout="vertical"
              width={730}
              height={250}
              data={chartData}
              margin={{ top: 15, right: 30, left: -20, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="4 1 2" />
              <XAxis type="number" allowDecimals={false} />
              <YAxis dataKey="name" type="category" stroke="#f0f7f8" />
              <Tooltip />
              <Legend />
              <Bar 
              dataKey="viewed" 
              barSize={15}
               fill="#9b74c0" 
              // fill="#1f2125"

               />
            </ComposedChart>
          </ResponsiveContainer>
          <Link to="/dashboard/movies" className="view-more-link">
            {translate("View more")} {ICONS.DOUBLE_ARROW}
          </Link>
        </>
      ) : (
        <div className="empty-data">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  );
}
