import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Form } from "antd";

import REQUESTS from "../../api/requests";
import useTranslate from "../../hooks/translator";
import { PATHS, ICONS } from "../../config";
import { WEB_HOST } from "../../config/config";

import {
  InputComponent,
  MessageComponent,
  ButtonComponent,
} from "../../components";

import AuthorizationPage from "./AuthorizationPage";

export default function LoginPage() {
  const translate = useTranslate();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [messageErr, setMessageErr] = useState("");
  const [messageTwoFaCode, setMessageTwoFaCode] = useState("");

  const [twoFaCode, setTwoFaCode] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    setMessageErr("");
    setMessageTwoFaCode("");

    if (values) {
      REQUESTS.LOGIN(values)
        .then((data) => {
          setLoading(false);

          if (typeof data == "string") {
            setTwoFaCode(true);
            setMessageTwoFaCode(data);
          } else {
            navigate(PATHS.DASHBOARD);
          }

          localStorage.setItem("refresh_token", JSON.stringify(data));

          if (typeof data == "string") {
            localStorage.setItem("TOKEN", data);
          } else {
            localStorage.setItem("TOKEN", data.token);
          }
        })
        .catch((err) => {
          setLoading(false);

          if (typeof err === "string") {
            if (err == "Incorrect email or password") {
              setMessageErr(translate(err));
            } else {
              setMessageErr(err);
            }
          }

          if (typeof err?.message === "string") {
            if (err == "Incorrect email or password") {
              setMessageErr(translate(err.message));
            } else {
              setMessageErr(err.message);
            }
          }

          if (err == "Code is required" || err == "Wrong code") {
            setTwoFaCode(true);
          }
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
  };

  const onValuesChange = (changedValues, allValues) => {
    setMessageErr("");
  };

  const onSubmit = () => {
    form.submit();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };
  return (
    <>
      <Helmet>
        <title>{translate("Admin Panel Login Page")}</title>
      </Helmet>

      <AuthorizationPage title={translate("Login")}>
        <Form
          name="login"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          onKeyPress={(e) => handleKeyPress(e)}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: translate("E-mail isn't valid"),
              },
              {
                required: true,
                message: translate("Please input your E-mail"),
              },
            ]}
          >
            <InputComponent
              prefix={ICONS.USER}
              placeholder={translate("Email")}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: translate("Please input your password"),
              },
              {
                min: 8,
                message: translate("Password must be minimum 8 characters"),
              },
            ]}
          >
            <InputComponent
              type="password"
              prefix={ICONS.PASSWORD}
              placeholder={translate("Password")}
            />
          </Form.Item>

          {twoFaCode && (
            <Form.Item
              name="two_fa_code"
              rules={[
                {
                  required: false,
                  message: "",
                },
                {
                  min: 6,
                  message: translate("Verify code must be 6 characters"),
                },
              ]}
            >
              <InputComponent
                maxLength={6}
                placeholder={translate("2FA verification code")}
                style={{ textAlign: "center" }}
              />
            </Form.Item>
          )}

          <div
            style={{
              marginBottom: 24,
              textAlign: "end",
            }}
          >
            <Link to={PATHS.FORGOT_PASSWORD} className="forgot-your-password">
              {translate("Forgot Your Password")}
            </Link>
          </div>

          {messageTwoFaCode && (
            <MessageComponent msgtype="warning" message={messageTwoFaCode} />
          )}

          <MessageComponent message={messageErr} />

          <Form.Item>
            <ButtonComponent
              type="primary"
              style={{ marginTop: 20, width: "100%" }}
              onClick={onSubmit}
              loading={loading}
            >
              {translate("Login")}
            </ButtonComponent>
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ marginRight: 5 }} className="dont-have-account">
              {translate("Don't have account")}
            </p>
            <a
              href={`${WEB_HOST}/register`}
              target="_blank"
              className="signup"
              rel="noreferrer"
            >
              {translate("Signup")}
            </a>
          </div>
        </Form>
      </AuthorizationPage>
    </>
  );
}
