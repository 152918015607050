import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setProfile } from "../../store/features/profileSlice";

import { Tabs, Button, Tooltip, Descriptions, Badge } from "antd";

import { PlusOutlined, SettingOutlined } from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { PageComponent, InfoPopover } from "../../components";

import AllDevices from "./AllDevices";
import AppReset from "./AppReset";
import ActiveDevices from "./ActiveDevices";
import FreeTrialDevices from "./FreeTrialDevices";
import BlockedDevices from "./BlockedDevices";
import DeactivatedTab from "./deactivated/DeactivatedTab";

import DeviceConfigDrawer from "./DeviceConfigDrawer";

import { deviceInfoPage } from "./infoModal";

import "../../styles/devices.scss";

export default function DevicesPage() {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const location = useLocation();

  const { profile } = useSelector((state) => state.profile);

  const [activeTab, setActiveTab] = useState("AllDevices");

  const [headStatistics, setHeadStatistics] = useState([]);

  const [selected, setSelected] = useState(null);

  // for activated device
  const [myActivate, setMyActivate] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [configDrawerOpen, setConfigDrawerOpen] = useState(false);

  const [pendingCount, setPendingCount] = useState(0);

  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    if (location.state == "from-dashboard") {
      setIsDrawerOpen(true);
    }
  }, [location]);

  useEffect(() => {
    getDeviceInfo();
    getPlatforms();
  }, []);

  useEffect(() => {
    if (!profile?.app_auto_reset || activeTab == "Reset requests") {
      getAppResetPending();
    }
  }, [activeTab]);

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelected(null);
      setMyActivate(false);
    }
    if (!selected && isDrawerOpen) {
      setActiveTab("AllDevices");
    }
  }, [selected, isDrawerOpen]);

  const getProfile = () => {
    REQUESTS.PROFILE()
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch((err) => {});
  };

  const onChangeTab = (key) => {
    setActiveTab(key);
  };

  function getDeviceInfo() {
    REQUESTS.DEVICES_INFO()
      .then((data) => {
        setHeadStatistics([
          {
            name: "Active",
            value: data.active,
            key: "active",
          },

          {
            name: "Activated from device",
            value: data.activated_by_device,
            key: "activated_by_device",
          },

          {
            name: "Activated from panel",
            value: data.activated_by_provider,
            key: "activated_by_provider",
          },
          {
            name: "Free trial",
            value: data.free_trial,
            key: "free_trial",
          },

          {
            name: "Trial expired",
            value: data.trial_expired,
            key: "trial_expired",
          },

          {
            name: "Expired",
            value: data.expired,
            key: "expired",
          },
        ]);
      })
      .catch((err) => {});
  }

  const getPlatforms = () => {
    REQUESTS.PLATFORMS({ query: JSON.stringify({ pagination: 0 }) }).then(
      (data) => {
        const platforms = data?.map((item) => ({
          text: item.name == "Antroid Mobile" ? "Android Mobile" : item.name,
          value: item.id,
        }));
        setPlatforms(platforms);
      }
    );
  };

  const getAppResetPending = () => {
    REQUESTS.APP_RESET.GET({ query: JSON.stringify({ pagination: 0 }) })
      .then((data) => {
        const pendingItems = data?.filter((item) => item.state === "pending");
        const pendingCount = pendingItems?.length;
        setPendingCount(pendingCount);
      })
      .catch((err) => {});
  };

  const items = [
    {
      key: "AllDevices",
      label: translate("All devices"),
      children: (
        <AllDevices
          activeTab={activeTab}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          getDeviceInfo={getDeviceInfo}
          getProfile={getProfile}
          platforms={platforms}
          selected={selected}
          setSelected={setSelected}
          myActivate={myActivate}
          setMyActivate={setMyActivate}
        />
      ),
    },
    {
      key: "Active",
      label: translate("Active"),
      children: (
        <ActiveDevices
          activeTab={activeTab}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          getDeviceInfo={getDeviceInfo}
          getProfile={getProfile}
          platforms={platforms}
          selected={selected}
          setSelected={setSelected}
          myActivate={myActivate}
          setMyActivate={setMyActivate}
        />
      ),
    },
    {
      key: "Free trial",
      label: translate("Free trial"),
      children: (
        <FreeTrialDevices
          activeTab={activeTab}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          getDeviceInfo={getDeviceInfo}
          getProfile={getProfile}
          platforms={platforms}
          selected={selected}
          setSelected={setSelected}
          myActivate={myActivate}
          setMyActivate={setMyActivate}
        />
      ),
    },
    {
      key: "Deactivated",
      label: translate("Deactivated"),
      children: (
        <DeactivatedTab
          activeTab={activeTab}
          getDeviceInfo={getDeviceInfo}
          getProfile={getProfile}
          platforms={platforms}
          selected={selected}
          setSelected={setSelected}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          myActivate={myActivate}
          setMyActivate={setMyActivate}
        />
      ),
    },
    {
      key: "Blocked devices",
      label: translate("Blocked devices"),
      children: (
        <BlockedDevices activeTab={activeTab} getProfile={getProfile} />
      ),
    },
    !profile?.app_auto_reset && {
      key: "Reset requests",
      label: (
        <>
          {translate("Reset requests")}
          <Badge count={pendingCount} offset={[10, 0]}></Badge>
        </>
      ),
      children: (
        <AppReset
          pendingCount={pendingCount}
          setPendingCount={setPendingCount}
          activeTab={activeTab}
        />
      ),
    },
  ];

  return (
    <PageComponent>
      <div className="head-page">
        <h3>
          {translate("Devices")}
          <InfoPopover content={deviceInfoPage(translate)} title={null} />
        </h3>
        <div className="tow-actions-btn">
          <Button
            className="plus-button"
            icon={<PlusOutlined />}
            onClick={() => setIsDrawerOpen(true)}
          >
            {translate("Add new device")}
          </Button>
          <Tooltip placement="bottom" title={translate("Device Config")}>
            <Button
              className="config-button"
              icon={<SettingOutlined />}
              onClick={() => setConfigDrawerOpen(true)}
            />
          </Tooltip>
        </div>
      </div>

      <div className="devices-head">
        <Descriptions
          column={{ xs: 2, sm: 3, md: 4 }}
          size="small"
          labelStyle={{ color: "grey" }}
        >
          {headStatistics?.map((el) => (
            <Descriptions.Item label={translate(el.name)} key={el.key}>
              {el.value || 0}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </div>
      <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        items={items}
        onChange={onChangeTab}
      />
      <DeviceConfigDrawer
        open={configDrawerOpen}
        onClose={() => setConfigDrawerOpen(false)}
        pendingCount={pendingCount}
      />
    </PageComponent>
  );
}
