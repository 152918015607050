import { BRANDING_TV_ICONS } from "../../config/data";

export default function TvComponent({
  secondaryColor,
  logo,
  logoSize,
  background,
}) {
  return (
    <div
      className="bg"
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: " no-repeat",
        position: "relative",
      }}
    >
      <div className="tv-logo-container">
        <img src={logo} alt="" className="tv-logo" style={{width: logoSize ? `${logoSize * 5}px` : "137px"}}/>
      </div>

      <div className="tv-icons-container">
        {BRANDING_TV_ICONS.map((item, i) => {
          return (
            (item.name === "Settings" ||
              (item.name === "Live TV") ||
              (item.name === "Movies") ||
              (item.name === "Series")) && (
              <div
                key={item.id}
                className="tv-icon-item"
                style={{
                  border: `2px solid ${i === 0 ? secondaryColor : "#707071"}`,
                  borderRadius: "50%",
                }}
              >
                <div
                  style={{
                    background: `${i === 0 ? secondaryColor : "#313131"}`,
                    borderRadius: "50%",
                    width: "65px",
                    height: "65px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={item.icon} alt="" className="tv-icon" />
                </div>
                <span>{item.name}</span>
              </div>
            )
          );
        })}
      </div>

      <div className="tv-footer-container">
        <p>
          <span> Device Key: 3GK689</span>
        </p>
        <p>App version: 1.1.5</p>
      </div>
    </div>
  );
}
