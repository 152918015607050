import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import useTranslate from "../hooks/translator";

import REQUESTS from "../api/requests";

import ICONS from "../config/icons";

export default function ActivationsStatistics({ subDevicesCount }) {
  const { profile } = useSelector((state) => state.profile);
  const translate = useTranslate();

  const [limitedCount, setLimitedCount] = useState(null);
  const [isLimited, setIsLimited] = useState(false);

  useEffect(() => {
    if (profile?.is_partner && !profile?.is_seller) {
      setLimitedCount(profile?.partner_package?.device_count);
    }
    if (profile?.is_partner && profile?.is_seller) {
      setLimitedCount(profile?.partner_package_device_count);
    }
  }, [profile]);

  useEffect(() => {
    if (profile?.is_partner && limitedCount) {
      if (subDevicesCount + profile?.device_count > limitedCount) {
        setIsLimited(true);
        REQUESTS.PROVIDER_LIMITED({ is_limited: true });
      } else {
        setIsLimited(false);
        REQUESTS.PROVIDER_LIMITED({ is_limited: false });
      }
    }
  }, [limitedCount]);

  const returnData = () => {
    if (profile?.is_partner) {
      if (profile?.partner_package) {
        if (profile?.partner_package?.unlimited) {
          return (
            <>
              <span className="infinity">{ICONS.INFINITY}</span> <span>/</span>
              <span>{profile?.device_count + subDevicesCount}</span>
            </>
          );
        } else {
          return (
            <div style={{ display: "flex", alignItems: "center"}}>
              <span style={{ marginRight: 3 }}>
                {profile?.is_seller
                  ? profile?.partner_package_device_count
                  : profile?.partner_package?.device_count}
              </span>{" "}
              {" / "}
              <span
                style={{
                  marginLeft: 3,
                  color: isLimited && "#d84040",
                }}
              >
                {profile?.device_count + subDevicesCount}
              </span>
            </div>
          );
        }
      }
    } else if (profile?.parent_id) {
      return (
        profile?.status == "NEED_BUY_PARTNER_CREDITS"
          ? profile?.credit + "/" + profile?.device_count
          : profile?.device_count + subDevicesCount
      );
    } else {
      return (
        profile?.activation
      );
    }
  };

  return (
    <div className="total-activations">
      <div style={{ display: "flex", alignItems: "center", gap: 7}}>
        <p>{translate("Activated devices")}:</p>  {returnData()} 
      </div>    

      {!profile?.is_partner && !profile?.parent_id && (
        <p>
          {translate("Available credits")}: {profile?.credit || 0} 
        </p>
      )}
    </div>
  );
}
