import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import useTranslate from "../../../hooks/translator";
import { ICONS } from "../../../config";

export default function AddNewDevice() {
  const translate = useTranslate();
  const navigate = useNavigate();

  const goAddNewDevice = () => {
    navigate("/dashboard/devices", {state: "from-dashboard"})
  }
  return (
    <div style={{marginTop: 30}}>
    <Button  onClick={goAddNewDevice} className="dashboard-new-device">{ICONS.PLUS} {translate("Add new device")}</Button>
    </div>
  )
}
