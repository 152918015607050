import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Popover, message, Popconfirm, Divider, Tooltip } from "antd";

import REQUESTS from "../api/requests";

import { useDispatch, useSelector } from "react-redux";
import { setAccountsList, setProfile } from "../store/features/profileSlice";

import useTranslate from "../hooks/translator";

import { PATHS } from "../config";

import { JOIN_COMMUNITY, TELEGRAM, SUPPORT } from "../config/config";
import IMAGES from "../config/images";
import ICONS from "../config/icons";

import AccountModal from "./accounts/AccountModal";

export default function MultiAccounts() {
  const dispatch = useDispatch();

  const { profile, accountsList, isBrandinLogoChange } = useSelector(
    (state) => state.profile
  );

  const translate = useTranslate();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    getAccounts();
  }, [isBrandinLogoChange]);

  const getAccounts = () => {
    REQUESTS.MULTI_ACCOUNT.GET()
      .then((res) => {
        dispatch(setAccountsList(res));

        const selected = res.find((item) => item.is_selected === true);

        if (selected && isSelected) {
          dispatch(setProfile(selected));
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onSelect = (item) => {
    const body = {
      id: item.id,
    };
    if (item.id !== profile?.id) {
      REQUESTS.MULTI_ACCOUNT.SELECT(body)
        .then((res) => {
          setIsSelected(true);
          dispatch(setProfile(item));
          localStorage.setItem("TOKEN", res.token);
          navigate(PATHS.DASHBOARD);
          getAccounts();
        })
        .catch((err) => {
          if (typeof err == "string") {
            message.error(err);
          }
        });
    }
  };

  const onDelete = (id) => {
    const body = {
      id: id,
    };
    if (id !== profile?.id) {
      REQUESTS.MULTI_ACCOUNT.DELETE(body)
        .then((res) => {
          getAccounts();
          message.success(res);
        })
        .catch((err) => {
          if (typeof err == "string") {
            message.error(err);
          }
        });
    }
  };

  return (
    <>
      <div className="multi-accounts-page">
        <div className="multi-accounts">
          {accountsList?.length == 0 ? (
            <div className="account" style={{ border: "2px solid #3EFF0E" }}>
              <Popover
                placement="right"
                content={
                  <span style={{ color: "#8C8C8C" }}>
                    {translate("You can't select yourself")}{" "}
                  </span>
                }
                overlayClassName="account-popover"
              >
                <img
                  src={profile?.app_branding?.logo || IMAGES.MAN}
                  onError={(e) => (e.target.src = IMAGES.MAN)}
                  alt="profile"
                />
              </Popover>
            </div>
          ) : (
            accountsList?.map((item) => (
              <div
                className="account"
                onClick={() => onSelect(item)}
                key={item.id}
                style={{
                  border: item.is_selected
                    ? "2px solid #3EFF0E"
                    : "2px solid #979797",
                }}
              >
                <Popover
                  placement="right"
                  content={
                    item.is_selected ? (
                      <span style={{ color: "#8C8C8C" }}>
                        {translate("You can't select yourself")}{" "}
                      </span>
                    ) : (
                      <>
                        <Divider
                          style={{
                            color: "grey",
                            borderBottom: "1px solid grey",
                          }}
                        >
                          {item.brand_name}
                        </Divider>
                        <div
                          onClick={() => onSelect(item)}
                          className="account-select"
                        >
                          <span>{translate("Select")}</span>
                        </div>
                        <div
                          className="account-del"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Popconfirm
                            title={`${translate(
                              "Are you sure to delete this account"
                            )}?`}
                            onConfirm={() => onDelete(item.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <span>{translate("Delete")}</span>
                          </Popconfirm>
                        </div>
                      </>
                    )
                  }
                  overlayClassName="account-popover"
                >
                  <img
                    src={
                      item?.app_branding?.logo ||
                      item?.app_branding?.logo_secondary
                    }
                    onError={(e) => (e.target.src = IMAGES.LOGO)}
                    alt=""
                  />
                </Popover>
              </div>
            ))
          )}

          <Button icon={ICONS.ADD} onClick={() => setOpen(true)}></Button>
        </div>
        {!profile?.parent_id && (
          <div className="social-links">
            <Tooltip title={translate("Join community")} placement="right">
              <a
                href={JOIN_COMMUNITY}
                target="_blank"
                className="join-community"
                rel="noreferrer"
              >
                <img src={IMAGES.JOIN_COMMUNITY} alt="join" width={"27px"} />
              </a>
            </Tooltip>
            <Tooltip title="Telegram" placement="right">
              <a
                href={TELEGRAM}
                target="_blank"
                className="social-anim telegram"
                rel="noreferrer"
              >
                {ICONS.TELEGRAM}
              </a>
            </Tooltip>
            <Tooltip title={translate("Support")} placement="right">
              <a
                href={SUPPORT}
                target={"_blank"}
                className="social-anim message-social-anim"
                rel="noreferrer"
              >
                <img src={IMAGES.MESSAGE} alt="MESSAGE" />
              </a>
            </Tooltip>
          </div>
        )}
      </div>

      <AccountModal
        open={open}
        onCancel={() => setOpen(false)}
        getData={getAccounts}
      />
    </>
  );
}
