import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Typography, Progress, notification } from "antd";

import REQUESTS from "../api/requests";
import useTranslate from "../hooks/translator";
import { PATHS, IMAGES } from "../config";
import { APP_NAME, APP_VERSION } from "../config/config";

let interval = null;

export default function WelcomePage() {
  const navigate = useNavigate();
  const translate = useTranslate();

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    interval = setInterval(() => {
      setPercent((percent) => percent + 1);
    }, [10]);
  }, []);

  useEffect(() => {
    if (percent == 100) {
      clearInterval(interval);
      const TOKEN = localStorage.getItem("TOKEN");
      setTimeout(() => {
        if (TOKEN) {
          REQUESTS.PROFILE()
            .then((data) => {
              if (data) {
                navigate(PATHS.DASHBOARD);
              } else {
                navigate(PATHS.LOGIN);
              }
            })
            .catch((err) => {
              if (err?.blocked) {
                notification.info({
                  message: translate("Your account has been blocked"),
                  description: err?.message,
                  duration: null,
                });
              }
            });
        } else {
          localStorage.clear();
          navigate(PATHS.LOGIN);
        }
      }, 100);
    }
  }, [percent]);

  return (
    <div className="welcome-page">
      <div className="container">
        <img src={IMAGES.LOGO} alt="logo" />
        <Typography.Title>
          {translate("Welcome to the")} {APP_NAME}
        </Typography.Title>

        <Progress percent={percent} showInfo={false} strokeColor="#67baff" />
      </div>

      <div className="version">
        {translate("App version")}|{APP_VERSION}
      </div>
    </div>
  );
}
