import axios from "axios";

const axiosInstance = axios.create({
  timeout: 60000,
  headers: {
    "content-type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("TOKEN");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error?.config?.url) {
      let request_url_substring = error?.config?.url?.match(/\/provider\/provider\/profile$/);
      if (
        request_url_substring && 
        request_url_substring[0] == "/provider/provider/profile" &&
        error?.response?.status == 403
      ) {
        localStorage.clear();
        window.location.href = "/#/login";
        return;
      }
    }
    if (error?.response?.status == 423) {
      if (
        window.location.hash == "#/login" ||
        window.location.hash == "/login"
      ) {
        localStorage.clear();
      } else {
        window.open("#", "_self");
        localStorage.clear();
      }
    }
    if (error?.response?.data && error?.response?.data !== "Forbidden") {
      return Promise.reject(error.response.data);
    } 
    if((!error?.response?.data) && (error.message && error.message !== "Forbidden")) {
      return Promise.reject(error.message);
    }
  }
);

const request = async (method, url, params) => {
  try {
    if (method == "get") {
      const response = await axiosInstance.get(url, { params });
      return response;
    } else if (method == "delete") {
      const response = await axiosInstance.delete(url, { data: params });
      return response;
    } else {
      const response = await axiosInstance[method](url, params);
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export default request;
