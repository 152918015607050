import { Layout } from "antd";

import MenuContent from "./MenuContent";

import "../styles/sidebar.scss";

export default function Sidebar({ collapsed, setCollapsed, isMobile }) {
  return (
    <Layout.Sider
      className="sidebar"
      style={{
        height: "100vh",
        left: 0,
        top: 0,
        bottom: 0,
        marginRight: isMobile ? 0 : 250,
        transition: "all 0.1s",
        overflow: "hidden",
        position: "fixed",
      }}
      breakpoint="md"
      collapsedWidth="0"
    >
      <MenuContent />
    </Layout.Sider>
  );
}
