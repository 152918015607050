import { Popover } from "antd";

import { useSelector } from "react-redux";
import useTranslate from "../hooks/translator";

import ICONS from "../config/icons";

export default function ProviderPackage() {
  const { profile } = useSelector((store) => store.profile);
  const translate = useTranslate();

  return (
    <Popover
      placement="bottom"
      className="partner-tariff-popover"
      content={
        <div className="tariff-content">
          <p>
            <span>{translate("Device count")}:</span>{" "}
            {profile?.partner_package?.unlimited
              ? ICONS.INFINITY
              : profile?.partner_package?.device_count}
          </p>
          <p>
            <span>{translate("Price")}:</span> $
            {profile?.partner_package?.price} /
            <span style={{ fontSize: "12px", color: "grey" }}>
              {translate("month")}
            </span>
          </p>
        </div>
      }
    >
      <div className="partner-package">
        <span style={{ color: "#FFE600" }}>{ICONS.STAR}</span>
        <span>{profile?.partner_package?.name}</span>
      </div>
    </Popover>
  );
}
