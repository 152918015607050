import { Button, Popconfirm } from "antd";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import useTranslate from "../hooks/translator";

export default function TableCSV({ dataForExport }) {
  const translate = useTranslate();

  return (
    <Popconfirm
      title={translate("Are you sure to export data")}
      okText={<CSVLink data={dataForExport}>{translate("Yes")}</CSVLink>}
      cancelText={translate("No")}
      icon={null}
    >
      <Button
        style={{
          background: "#173E4B",
          borderColor: "#173E4B",
          color: "white",

        }}
        icon={<DownloadOutlined />}
      >
       {translate("Export CSV")} 
      </Button>
    </Popconfirm>
  );
}
