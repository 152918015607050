import { useState } from "react";

import { Link } from "react-router-dom";

import { Form, Typography } from "antd";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { ICONS, PATHS } from "../../config";

import { InputComponent, ButtonComponent, MessageComponent } from "../../components";

import AuthorizationPage from "./AuthorizationPage";

/**
 * Forgot Password Page is a page for users to reset their password, if they forgot it. It is a form with email input and a submit button.
 * We use the Form component from Ant Design to create the form. We use the InputComponent and ButtonComponent components to create the
 * input and the button. We use the AuthorizationPage component to create the page. We use the MessageComponent component to show the
 * message to the user.
 * @returns
 */

export default function ForgotPasswordPage() {
  const translate = useTranslate();

  const [message, setMessage] = useState(""); // Message to show to the user after the request is done. It can be an error or a success message.
  const [success, setSuccess] = useState(false); // If the request is successful, it will be true. Otherwise, it will be false.
  const [loading, setLoading] = useState(false); // When the request is being processed, it will be true. Otherwise, it will be false.
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // When the form is submitted, this function will be called.
    REQUESTS.FORGOT_PASSWORD(values)
      .then((data) => {
        setLoading(false);
        setSuccess(true);
        setMessage(data);
      })
      .catch((err) => {
        setLoading(false);
        setSuccess(false);
        if (typeof err === "string") {
          setMessage(err);
        }
        if (typeof err.message === "string") {
          setMessage(err.message);
        }
      });
  };

  const onFinishFailed = () => {
    // When the form is submitted, but there is an error, this function will be called.
    setLoading(false);
  };

  const onSubmit = () => {
    // When the submit button is clicked, this function will be called. It will call the form's submit function.
    setLoading(true);
    setMessage("");
    form.submit();
  };

  const onValuesChange = (changedValues, allValues) => {
    // When the form's values are changed, this function will be called.
    setMessage("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      form.submit()
    }
  };
  return (
    <AuthorizationPage title={translate("Forgot Your Password")}>
      <div className="subtitle">
        <Typography.Text>
          {translate("Forget your password? No need to worry")}
        </Typography.Text>

        <Typography.Text>
          {translate("Tell us your email and we will send your password")}
        </Typography.Text>
      </div>

      <Form
        name="login"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        layout="vertical"
        onKeyPress={(e) => handleKeyPress(e)}
      >
        <Form.Item
          name={translate("email")}
          rules={[
            {
              type: "email",
              message: translate("E-mail isn't valid"),
            },
            {
              required: true,
              message: translate("Please input your E-mail"),
            },
          ]}
        >
          <InputComponent
            prefix={ICONS.USER}
            placeholder={translate("Email")}
          />
        </Form.Item>
        <MessageComponent msgtype={success && "success"} message={message} />
        <Form.Item>
          <ButtonComponent block onClick={onSubmit} loading={loading}>
            {translate("Send")}
          </ButtonComponent>
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Link to={PATHS.LOGIN}>{translate("Back To Login")}</Link>
        </div>
      </Form>
    </AuthorizationPage>
  );
}
