import React, { useState } from "react";
import { Button } from "antd";

import REQUESTS from "../api/requests";

import useTranslate from "../hooks/translator";

import { ICONS } from "../config";

export default function CoinbaseButton({ package_id, isMobile }) {
  const translate = useTranslate();

  const [loading, setLoading] = useState(false);

  const payWithCoinbase = () => {
    setLoading(true);
    const body = {
      id: package_id,
    };

    REQUESTS.BTC(body)
      .then((res) => {
        setLoading(false);
        window.open(res.hosted_url, "_self");
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return isMobile ? (
    <Button
      size="large"
      className="coinbase"
      icon={ICONS.BTC}
      loading={loading}
      onClick={payWithCoinbase}
    >
      <span>
      {translate("Pay with Coinbase")}
      </span>
    </Button>
  ) : (
    <Button
      size="large"
      className="coinbase"
      icon={ICONS.BTC}
      loading={loading}
      onClick={payWithCoinbase}
    >
      {translate("Pay with Coinbase")}
    </Button>
  );
}
